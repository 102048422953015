import React from "react";
import { connect } from "react-redux";
import VideoProductsTileItem from "./VideoProductsTileItem";

import ImageList from "@mui/material/ImageList";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { postScoreFeedback } from '../../../../Store/feature_lookup';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const VideoProductsList = (props) => {
    // We have 2 colums and then we leave some for padding.
    const tileItemWidth = props.screenWidth / 2 - 40;
    return (
        <Container
            component="main"
            maxWidth="sm"
            style={{ padding: 0 }}
        >
            <ImageList
                sx={{
                    width: "100%",
                    height: "inherit",
                    padding: 0,
                    borderTopLeftRadius: "1em",
                    borderTopRightRadius: "1em",
                    margin: "0",
                }}
                variant="quilted"
                cols={2}
            >
                {
                    props.current_index >= 0 &&
                    props.feed_response &&
                    props.current_index < props.feed_response.length &&
                    props.feed_response[props.current_index] !== null &&
                    props.feed_response[props.current_index].video !== undefined &&
                    props.feed_response[props.current_index].video.products_info != undefined &&
                    props.feed_response[props.current_index].video.products_info.map((product, key) => {
                        return (<VideoProductsTileItem
                            key={key}
                            image={product.image_url}
                            product_id={product.id}
                            product_url={product.url}
                            product_brand={capitalizeFirstLetter(product.brand)}
                            product_price={capitalizeFirstLetter(product.broad_category) + " / " + (product.available == "NOT_AVAILABLE" ? "Out of Stock" : product.currency + product.price)}
                            product_index={props.current_index}
                            affiliate_link={product.affiliate_link ? product.affiliate_link : ''}
                            colWidth={tileItemWidth}
                            rowWeight={1}
                            colWeight={1}
                            postFeedback={props.postScoreFeedback}
                            available={product.available}
                        />);
                    })
                }
            </ImageList>
            {
                props.current_index >= 0 &&
                props.feed_response &&
                props.current_index < props.feed_response.length &&
                props.feed_response[props.current_index] !== null &&
                props.feed_response[props.current_index].video !== undefined &&
                props.feed_response[props.current_index].video.action != undefined &&
                props.feed_response[props.current_index].video.action.type == 'open_c' &&
                <Button
                    variant="contained"
                    style={{
                        borderBottomLeftRadius: "1em",
                        borderBottomRightRadius: "1em",
                        textTransform: "none",
                        justifyContent: "center",
                        marginTop: "4px",
                        marginBottom: "1em",
                    }}
                    fullWidth
                    href={`/feed?collection=${props.feed_response[props.current_index].video.action.pointer}`}
                >
                    <Typography fontSize="1.25em">
                        {props.feed_response[props.current_index].video.action.button_text}
                    </Typography>
                </Button>
            }
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    feed_response: state.feature_lookup.feed_response,
    current_index: state.feature_lookup.current_index,
});

const mapDispatchToProps = (dispatch) => ({
    postScoreFeedback: (index, score_key, score_value) => dispatch(postScoreFeedback(index, score_key, score_value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoProductsList);

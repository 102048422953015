import React, { useState, useEffect, useCallback, useRef } from "react";
import "./FeedView.css";
import Button from "@mui/material/Button";
import { isMobile } from 'react-device-detect';


import Pager from "../../Components/FeedView/Pager";
import { MediaItem } from "../../Components/FeedView/MediaItem";
import Box from "@mui/material/Box";
// import CommentBottomDrawer from "../Components/FeedView/CommentBottomDrawer";
// import VideoProductsBottomDrawer from "../Components/FeedView/VideoProductsBottomDrawer";
// import ProductsDescBottomDrawer from "../Components/FeedView/ProductsDescBottomDrawer";
// import ProductDebugDrawer from "../Components/ProductDebugDrawer";
// import BlockLandscape from "../Components/FeedView/BlockLandscape";
import Container from "@mui/material/Container";
import LoadingScreen from "../../Components/Common/LoadingScreen";
// import useScreenOrientation from '../../Hooks/useScreenOrientation';
// import useDetectKeyboardOpen from "use-detect-keyboard-open";


export default function FullScreenFeedView(props) {
  // var isLandscape = useScreenOrientation() == "landscape-primary";
  // const isKeyboardOpen = useDetectKeyboardOpen();
  const unmuteOnceRef = useRef(false);
  const [userUnmutedOnce, setUserUnmutedOnce] = useState(false);
  const [lastTime, setLastTime] = useState(new Date());
  const [touchGesturesAllowed, setTouchGesturesAllowed] = useState(true);

  const maybeUpdateGlobalUnmute = useCallback(() => {
    if (!unmuteOnceRef.current) {
      unmuteOnceRef.current = true;
      setUserUnmutedOnce(true);
    }
  }, []);



  // useEffect(() => {
  //   console.log("Screen dimensions changed:", screenWidth, screenHeight);
  // }, [screenWidth, screenHeight]);

  // useEffect(() => {
  //   console.log("visualViewport.width chanegd:", visualViewport.width, visualViewport.height);
  // }, [visualViewport.width, visualViewport.height]);

  // Handle drawer containing video products
  // const [isVideoProductsDrawerVisible, setIsVideoProductsDrawerVisible] =
  //   React.useState(false);
  // const openVPDrawer = React.useCallback(
  //   () => setIsVideoProductsDrawerVisible(true),
  //   []
  // );
  // const closeVPDrawer = React.useCallback(
  //   () => setIsVideoProductsDrawerVisible(false),
  //   []
  // );

  // Handle drawer containing products description
  // const [isProductsDescDrawerVisible, setIsProductsDescDrawerVisible] =
  //   React.useState(false);
  // const openPDDrawer = React.useCallback(
  //   () => setIsProductsDescDrawerVisible(true),
  //   []
  // );
  // const closePDDrawer = React.useCallback(
  //   () => setIsProductsDescDrawerVisible(false),
  //   []
  // );

  // Handle the drawer containing the comments.
  // const [isVisible, setIsVisible] = React.useState(false);
  // const openDrawer = React.useCallback(() => setIsVisible(true), []);
  // const closeDrawer = React.useCallback(() => setIsVisible(false), []);
  // const [showModal, setShowModal] = useState(false);

  // const handleOpenModal = React.useCallback(() => setShowModal(true), []);
  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const trackUserInteractions = (nextPage, previousPage) => {
    var current_date_time = new Date();
    var seconds =
      (current_date_time.getTime() - lastTime.getTime()) / 1000;
    setLastTime(current_date_time);
    props.postFeedback(previousPage, "time_spent", seconds);
  };

  // Dynamically resize the pager height using event listener with any viewport resize.
  // const [pagerStyles, setPagerStyles] = useState({
  //     width: window.innerWidth > 800 ? "35%" : "100%",
  //     height: "100vh",
  //     overflow: "auto",
  // });

  // const updateStyles = () => {
  //     setPagerStyles({
  //         ...pagerStyles,
  //         height: "100vh",
  //     });
  // };

  // useEffect(() => {
  //     visualViewport.addEventListener("resize", updateStyles);
  //     return () => {
  //         visualViewport.removeEventListener("resize", updateStyles);
  //     };
  // });

  // Update the current index.
  // Handle interactions when the page changes.
  // Handle constructiuon and destruction of pages.
  const handlePageChange = (nextPage, previousPage) => {
    // console.log("handlePageChange called:", nextPage, previousPage);
    // console.log("handlePageChange called:", nextPage, previousPage);
    if (nextPage == previousPage && nextPage == 0) {
      setLastTime(new Date());
      // This is the case on startup, when an extra call is made.
      // props.setCurrentIndex(0);
      // do nothing, we set the index on getting the first feed response.
    } else {
      // Update the current index. We use this to update the comments in the comments drawer.
      props.setCurrentIndex(nextPage);
      trackUserInteractions(nextPage, previousPage);
    }
  };

  if (props.feed_response.length === 0 || props.screenDimensions.screenWidth == 0 || props.screenDimensions.screenHeight == 0) {
    return <LoadingScreen text={""} />;
  }

  let pagerMethods = null;

  return (
    <>
      {props.feed_response.length > 0 && (
        <Box
          className="pager-box"
          sx={{
            marginLeft: "0",
            marginRight: "0",
            marginTop: !isMobile ? props.screenDimensions.topHeightOffSet + "px" : 0,
          }}
        >
          <Container component="main" style={{ padding: 0, width: props.screenDimensions.screenWidth }}>
            <Pager
              ref={node => (pagerMethods = node)}
              className="pager"
              // onNavigationStart={handlePageStart}
              onPageSelected={handlePageChange}
              orientation="vertical"
              animationStyle="opacity"
              initialPage={props.current_index <= 0 ? 0 : props.current_index}
              ease="easeOutQuad"
              wheelScroll={false}
              touchGestures={touchGesturesAllowed}
              wrapperStyle={{
                // The height should be integer for onPageSelected to work correctly.
                // We have modified the condition to trigger, when s % size == 0.
                height: props.screenDimensions.screenHeight,
                overflow: "hidden",
                width: props.screenDimensions.screenWidth,
                backgroundColor: "black"
              }}
            >
              {
                props.feed_response.map((item, key) => {
                  return (
                    <MediaItem
                      key={key}
                      item={item}
                      isRendered={props.isRendered[key]}
                      postFeedback={props.postFeedback}
                      index={key}
                      isActive={item.screen_type === "PROMO_VIDEO" ? key == props.current_index : undefined}
                      screenHeight={props.screenDimensions.screenHeight}
                      screenWidth={props.screenDimensions.screenWidth}
                      userUnmutedOnce={userUnmutedOnce}
                      maybeUpdateGlobalUnmute={maybeUpdateGlobalUnmute}
                      setDrawerOpenStateFn={props.setDrawerOpenStateFn}
                      isAuthenticated={props.isAuthenticated}
                      setTouchGesturesAllowed={setTouchGesturesAllowed}
                    />
                  );
                })
              }
            </Pager>
            {!isMobile &&
              <div
                style={{
                  position: "absolute",
                  width: "100px",
                  top: "40%",
                  zIndex: 1,
                  left: "70%",
                }}
              >
                <Button
                  variant="text"
                  color="custom"
                  onClick={() => {
                    pagerMethods.previous();
                    if (props.current_index - 1 >= 0) {
                      handlePageChange(props.current_index - 1, props.current_index)
                    }
                  }}>
                  Previous
                </Button>
                <Button variant="text" color="custom" onClick={() => {
                  pagerMethods.next();
                  if (props.current_index + 1 < props.feed_response.length) {
                    handlePageChange(props.current_index + 1, props.current_index)
                  }
                }}>
                  Next
                </Button>
              </div>
            }
            {/* {props.feed_response.length > 0 && (
              <CommentBottomDrawer
                onClose={closeDrawer}
                isVisible={isVisible}
              />
            )} */}
            {/* {props.feed_response.length > 0 && (
              <VideoProductsBottomDrawer
                onClose={closeVPDrawer}
                isVisible={isVideoProductsDrawerVisible}
                postFeedback={props.postFeedback}
              />
            )} */}
            {/* {props.feed_response.length > 0 && (
              <ProductsDescBottomDrawer
                onClose={closePDDrawer}
                isVisible={isProductsDescDrawerVisible}
                postFeedback={props.postFeedback}
              />
            )} */}
            {/* <BlockLandscape isVisible={isMobile && isLandscape} /> */}
            {/* <div
              style={{
                zIndex: 1,
              }}
            >
              {props.feed_response.length > 0 && (
                <ProductDebugDrawer
                  showModal={showModal}
                  product_debug_data={
                    props.feed_response[props.current_index].product_debug
                  }
                  collection_context={props.collection_context}
                  handleCloseModal={handleCloseModal}
                />
              )}
            </div> */}
          </Container>
        </Box>
      )}
    </>
  );
}

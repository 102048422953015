import React from "react";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";

const iconStyles = {
  color: "white",
};

const FooterLinks = {
  whatsappText1: "Tired of searching for your perfect outfit?",
  whatsappText2: "Get a personal lookbook curated for your style.",
  whatsapp: "https://wa.me/917428499551?text=" + encodeURI("Hi! I'm interested in getting a personalized shopping collection."),
  instagram: "https://www.instagram.com/fits.by.zive/",
};

function GetLookbookSection({ collectionItemsLoaded }) {
  return (
    <Box
      style={{
        padding: "3%",
        color: "white",
      }}
      bgcolor={collectionItemsLoaded ? "primary.light" : null}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color="primary.contrastText"
    >
      <Typography textAlign="center" color="primary.contrastText" variant="subtitle1">
        {FooterLinks.whatsappText1}
      </Typography>
      <Typography textAlign="center" color="primary.contrastText" variant="body1">
        {FooterLinks.whatsappText2}
      </Typography>
      <Button
        variant="contained"
        data-tut="get_free_lookbook"
        style={{
          marginTop: "1em",
          borderRadius: "5px",
          textTransform: "none",
          justifyContent: "flex-start",
          backgroundColor: "#303E8C",
        }}
        href={"https://docs.google.com/forms/d/e/1FAIpQLScwKlbopR3JS1KcvXBZP65qzimob_DJXNcI-m8gUhEDYaIX4A/viewform"}
      >
        <Typography
          style={{ color: "white" }}
          variant="button"
          fontSize="1.3em"
        >Get My Free Lookbook</Typography>
      </Button>
    </Box>
  );
}

function SocialIconsSection({ collectionItemsLoaded }) {
  return (
    <Box
      style={{
        border: "1px solid black",
        justifyContent: "center"
      }}
      color="primary.contrastText"
    >
      <Typography textAlign="center" color="primary.contrastText" variant="subtitle1">
        Stay in Touch
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          marginRight: "-1em",
        }}
      >
        <IconButton
          style={iconStyles}
          href={FooterLinks.instagram}
          data-tut="insta_follow"
        >
          <InstagramIcon
            style={{ fontSize: 30 }}
            sx={{ color: "primary.contrastText" }}
          />
        </IconButton>
        {/* <IconButton
          style={iconStyles}
          href={FooterLinks.whatsapp}
          data-tut="whatsapp_connect"
        >
          <WhatsAppIcon
            style={{ fontSize: 30 }}
            sx={{ color: "primary.contrastText" }}
          />
        </IconButton> */}
      </Box>
    </Box>
  )
}

const Footer = ({ collectionItemsLoaded }) => {
  return (
    <Box
      style={{
        width: "100%",
        textAlign: "center",
        marginTop: !collectionItemsLoaded ? "10vh" : "0vh"
      }}
    >
      {/* <GetLookbookSection collectionItemsLoaded={collectionItemsLoaded} /> */}
      <SocialIconsSection collectionItemsLoaded={collectionItemsLoaded} />
    </Box>
  );
};

export default Footer;

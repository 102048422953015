import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingScreen = ({ width = "100%", height = "90vh", text = "" }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        width: width,
        height: height,
      }}
    >
      <CircularProgress sx={{ margin: "0 auto", color: "black" }} />
      <p style={{ color: "white" }}> {text}</p>
    </Grid>
  );
};

export default LoadingScreen;

import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import CardItem from "../../Components/CollectionItem/CardItem";
import LoadingScreen from "../../Components/Common/LoadingScreen";

//MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

const Collection = () => {
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState(null);
  const [searchParams] = useSearchParams();
  const debug = searchParams.get("debug") === "true";
  const showCollectionId = searchParams.get("id");

  const navigate = useNavigate();
  const redirectForm = () => {
    navigate("/collectionForm");
    window.location.reload();
  };
  // useEffect(() => {
  //   const fetchMedia = async () => {
  //     console.log(
  //       "%c(API call)\nshow one collection of id " + showCollectionId,
  //       "color:yellow;"
  //     );
  //     const items = isAuth
  //       ? await require("./../../Models/data/collections_list.json").filter(
  //         (list) => list.id === showCollectionId
  //       )[0]
  //       : [];
  //     setCollections(items);
  //     setLoading(false);
  //   };
  //   setTimeout(fetchMedia, 1000);
  // }, [0]);

  if (loading) return <LoadingScreen />;

  return (
    <>
      <Toolbar />
      <Container component="main" maxWidth="md">
        <Box>
          <Typography variant="h4" textAlign="center">
            {collections.list_name}
          </Typography>
          <Grid
            container
            spacing={2}
            columns={12}
            display="flex"
            style={{ marginTop: "1em" }}
          >
            {collections.list
              .filter((col) => col.media !== null)
              .map((collection) => {
                return (
                  <Grid item xs={6} md={4} key={collection.id}>
                    <CardItem collection={collection} debug={debug} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Container>
      <Toolbar />
      <Toolbar />
    </>
  );
};

export default Collection;

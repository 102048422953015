import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import "./selectable_card_style.css"
import Button from '@mui/material/Button';

function SelectableCardBase(props) {
    var isSelected = props.selected ? "selected" : "";
    var className = "selectable " + isSelected;
    return (
        <div className="card">
            <div className={className} onClick={props.onClick}>
                {props.children}
                <div className="check">
                    <span className="checkmark">
                        <CheckIcon />
                    </span>
                </div>
            </div>
        </div>
    );
}


function ImageBox(props) {
    return (
        <SelectableCardBase
            onClick={props.onClick}
            selected={props.selected}
        >
            <Box
            >
                <Box
                    style={{
                        height: "100%",
                        height: "150px",
                        backgroundImage: `url(${props.image_path})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>
                </Box>
                {props.subtitle && <Box style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                }}>
                    <Typography style={{ color: 'white' }}>
                        {props.subtitle}
                    </Typography>
                </Box>}
            </Box>
        </SelectableCardBase>
    );
}

function ImageGrid(props) {
    return (
        <Grid
            container
            spacing={1}
            style={{
                width: "100%",
                margin: "-4px"
            }}
            item
            xs={12}>
            {
                props.answers
                    .map((item, index) => (
                        <Grid item key={index} xs={6}>
                            <ImageBox
                                image_path={item.image_path}
                                subtitle={item.title}
                                onClick={() => { props.onListChanged(index) }}
                                selected={props.selected[index]}
                            />
                            <Button
                                onClick={() => { window.open(item.label, "_blank") }}
                                fullWidth
                                sx={{
                                    color: "white",
                                    fontSize: "0.1em"
                                }}
                            >
                                Check Product
                            </Button>
                        </Grid>
                    ))
            }
        </Grid>
    );
}

export default ImageGrid;
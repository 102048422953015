import React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const SetPassword = ({ userObject, handleChange, handlePasswordSubmit }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="password"
            value={userObject.password}
            onChange={handleChange}
            label="Password"
            required
            fullWidth
          />
          <p style={{ fontSize: "0.8em" }}>
            Password should be atleast 8 characters and have atleast 1 digit.
          </p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="password_confirm"
            value={userObject.password_confirm}
            onChange={handleChange}
            label="Confirm Password"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            style={{
              borderRadius: "2em",
            }}
            onClick={handlePasswordSubmit}
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SetPassword;

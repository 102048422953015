import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewIcon from '@mui/icons-material/GridView';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root, ": {
        color: "white",
        backgroundColor: 'rgba(120, 120, 120, 0.5)'
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: 'rgba(80, 80, 80, 0.7)'
    }
});

// 


export default function ToggleForTile({ gridMode, setGridMode }) {
    const handleChange = (
        event,
        newAlignment,
    ) => {
        // console.log("newAlignment:", newAlignment);
        if (newAlignment == "grid") {
            setGridMode(true);
        } else {
            setGridMode(false);
        }
    };

    const children = [
        <ToggleButton value="grid" key="grid">
            <Typography>Grid</Typography>
        </ToggleButton>,
        <ToggleButton value="feed" key="feed">
            <Typography>Feed</Typography>
        </ToggleButton>,
    ];

    const control = {
        value: gridMode ? "grid" : "feed",
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // TODO Replace with Stack
                '& > :not(style) + :not(style)': { mt: 2 },
            }}
        >
            <ToggleButtonGroup size="small" {...control} aria-label="Grid Feed Toggle">
                {children}
            </ToggleButtonGroup>
        </Box>
    );
}
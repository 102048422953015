import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const GreetingItem = ({
  image,
  message,
  imageFixed = false,
  width = "100%",
  height = "100%",
}) => {
  return (
    <>
      <Box
        style={{
          width: width,
          height: height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 0,
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: imageFixed ? "fixed" : "scroll",
        }}
      >
        {message !== undefined && message !== "" && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backdropFilter: "blur(2px)",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              padding="5%"
              textAlign="center"
              color={"black"}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default GreetingItem;

import React, { useState, useRef, useEffect } from "react";
import Container from "@mui/material/Container";
import QuizOptions from "./QuizOptions";
import SchematicForm from "./SchamaticForms";
import Button from '@mui/material/Button';
import { GetDefaultValuesFromSchema, GetSchemaFormRatingsFromValues } from './SchamaticForms';
import { useCustomForm } from './SchamaticForms/CustomForm';
import { getSelectedQuizOptions } from './QuizOptions';
import { postTagsFeedback } from '../../../Store/feature_lookup';
import { connect } from "react-redux";

const formFieldsSchema = [
    {
        "key": "comments",
        "type": "INPUT_STRING",
        "meta": {}
    },
    {
        "key": "is_good",
        "type": "BOOLEAN",
        "meta": { "true_text": "Yes, for sure", "false_text": "No, its bad" }
    },
    {
        "key": "apparel_type",
        "type": "MULTICLASS",
        "meta": { "values": ["male", "female", "unisex"] }
    },
    {
        "key": "category_in_pic",
        "type": "MULTILABEL",
        "meta": { "values": ["dress", "pants", "tops", "shoes"] }
    }
];

const quizOptions = [
    {
        "image_path": "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
        "text": "I am not sure what should be my style - exploring my personal style",
        "label": "option1"
    },
    {
        "image_path": "https://images.unsplash.com/photo-1496747611176-843222e1e57c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80",
        "text": "I am not satisfied with my current wardrobe - looking for an overhaul",
        "label": "option2"
    },
    {
        "image_path": "https://images.unsplash.com/photo-1469334031218-e382a71b716b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
        "text": "I can't search outfits which suit my body - finding fashion fit for my body",
        "label": "option3"
    },
    {
        "image_path": "https://images.unsplash.com/photo-1445205170230-053b83016050?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80",
        "text": "I am confused from too many options - shortlisting what-to-buy",
        "label": "option4"
    },
    {
        "image_path": "https://images.unsplash.com/photo-1558769132-cb1aea458c5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
        "text": "I know my vibe but not enough options - wanting more of what-I-like",
        "label": "option5"
    }
];

const ratingOptions1 = {
    "type": "SCHEMA_FORM",
    "schema_def": {
        "options": formFieldsSchema
    },
    "quiz_def": {}
}

const ratingOptions2 = {
    "type": "QUIZ",
    "schema_def": {},
    "quiz_def": {
        "quiz_key": "selected",
        "options": quizOptions,
        "options_type": "IMAGE",
        "multiselect": true,
        "num_selectable": 2
    }
}

function RatingOptions(props) {
    const [ratingOptionsDef, setRatingOptionsDef] = useState(undefined);
    const [selectedQuizAnswers, setSelectedQuizAnswers] = useState(undefined);
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema([]));

    useEffect(() => {
        if (props.feed_response && props.feed_response[props.current_index] && props.feed_response[props.current_index].rating_with_context) {
            const rating_options_def = props.feed_response[props.current_index].rating_with_context.rating_options;
            setRatingOptionsDef(rating_options_def);
            if (rating_options_def.quiz_def.options != undefined) {
                setSelectedQuizAnswers(new Array(rating_options_def.quiz_def.options.length).fill(0))
            }
            else {
                setSelectedQuizAnswers(new Array(0).fill(0))
            }
            if (rating_options_def.schema_def.options != undefined) {
                setInitialValues(GetDefaultValuesFromSchema(rating_options_def.schema_def.options))
            }
        }
    }, [props.feed_response, props.current_index]);

    // TODO(siddhant): This will  not work, as we dont check in custom form if the initial values change.
    const {
        values,
        handleChange,
        init
    } = useCustomForm({
        initialValues
    });

    function handleSubmit() {
        if (ratingOptionsDef != undefined && ratingOptionsDef.type == "QUIZ") {
            const feedback = getSelectedQuizOptions(selectedQuizAnswers, ratingOptionsDef.quiz_def.options, ratingOptionsDef.quiz_def.quiz_key);
            props.postTagsFeedback(props.current_index, feedback);
        }
        else if (ratingOptionsDef != undefined && ratingOptionsDef.type == "SCHEMA_FORM") {
            console.log("Submit was clicked with data", GetSchemaFormRatingsFromValues(ratingOptionsDef.schema_def.options, values));
        }
        else {
            throw "Undefined rating options."
        }
    }

    return (
        <Container
            maxWidth="xs"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            {ratingOptionsDef != undefined && ratingOptionsDef.type == "QUIZ" &&
                <QuizOptions
                    quiz_options={ratingOptionsDef.quiz_def.options}
                    selected={selectedQuizAnswers}
                    setSelected={setSelectedQuizAnswers}
                    options_type={ratingOptionsDef.quiz_def.options_type}
                    multiselect={ratingOptionsDef.quiz_def.multiselect}
                    num_selectable={ratingOptionsDef.quiz_def.num_selectable}
                />
            }
            {ratingOptionsDef != undefined && ratingOptionsDef.type == "SCHEMA_FORM" &&
                <SchematicForm
                    formFieldsSchema={ratingOptionsDef.schema_def.options}
                    values={values}
                    handleChange={handleChange}
                />
            }
            <Button
                onClick={handleSubmit}
                fullWidth
                sx={{
                    color: "red",
                    backgroundColor: "white", marginTop: "30px"
                }}
            >
                Submit
            </Button>
        </Container>
    )
}


const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    feed_response: state.feature_lookup.feed_response,
    current_index: state.feature_lookup.current_index
});

const mapDispatchToProps = (dispatch) => ({
    postTagsFeedback: (index, feedbackObj) => dispatch(postTagsFeedback(index, feedbackObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingOptions);
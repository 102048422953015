import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "quiz_questions",
    initialState: {
        quiz_id: -1,
        description: {},
        fetching_quiz: false,
        saving_preferences: false,
        user_preferences: {},
    },
    reducers: {
        // action => action handler
        quizDescriptionFetchStarted: (state, action) => {
            state.fetching_quiz = true;
        },
        fetchQuizDescription: (state, action) => {
            const server_description = action.payload.server_response.description;
            state.quiz_id = action.payload.data.quiz_id;
            state.description = server_description;
            state.fetching_quiz = false;
            var local_selected_answers = {};
            for (var index = 0; index < server_description.questions.length; index++) {
                var answers = server_description.questions[index].options;
                local_selected_answers[index] = new Array(answers.length).fill(0);
            }
            state.user_preferences = local_selected_answers;
        },
        quizDescriptionFetchFailed: (state, action) => {
            ErrorMessage("Error fetching quiz description. Please contact the admin.");
            state.fetching_quiz = false;
        },
        saveUserPreferenceStarted: (state, action) => {
            state.saving_preferences = true;
        },
        saveUserPreferenceSuccess: (state, action) => {
            state.saving_preferences = false;
            SuccessMessage("Preferences saved successfully.");
        },
        saveUserPreferenceFailed: (state, action) => {
            state.saving_preferences = false;
            ErrorMessage("Preferences save failed. Contact the admin");
        },
        updateUserPreferenceAtIndex: (state, action) => {
            var index = action.payload.index;
            var response = action.payload.response;
            state.user_preferences[index] = response;
        },
    }
});

export const {
    quizDescriptionFetchStarted,
    fetchQuizDescription,
    quizDescriptionFetchFailed,
    saveUserPreferenceStarted,
    saveUserPreferenceSuccess,
    saveUserPreferenceFailed,
    updateUserPreferenceAtIndex
} = slice.actions;

export default slice.reducer;


export const loadQuizDescription = (quiz_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/styling/fetch_quiz_with_id/",
            data_to_server: { quiz_id: quiz_id, fetch_template: 1 },
            data: { quiz_id: quiz_id },
            method: "post",
            onStart: quizDescriptionFetchStarted.type,
            onSuccess: fetchQuizDescription.type,
            onError: quizDescriptionFetchFailed.type
        })
    );
};

export const updatePreferencesAtIndex = (index, response) => (dispatch, getState) => {
    return dispatch({ type: updateUserPreferenceAtIndex.type, payload: { index: index, response: response } });
};

function GetConstraintsForQuestion(question, selected_answer) {
    let answer_keys = [];
    for (var index = 0; index < selected_answer.length; index++) {
        if (selected_answer[index] == 1) {
            answer_keys.push(question.options[index].code);
        }
    }
    return {
        'question': question.code,
        'option': answer_keys
    }
}

function GetAllConstraints(questions, selected_answers) {
    if (questions) {
        var constraints = {};
        for (var question_index = 0; question_index < questions.length; question_index++) {
            let constraint = GetConstraintsForQuestion(questions[question_index], selected_answers[question_index]);
            constraints[constraint.question] = constraint.option;
        }
    }
    return constraints;
}


export const savePreferences = () => (dispatch, getState) => {
    var questions = getState().quiz_questions.description.questions;
    var preferences = getState().quiz_questions.user_preferences;
    var quiz_id = getState().quiz_questions.quiz_id;
    var responses = GetAllConstraints(questions, preferences);
    return dispatch(
        apiCallBegan({
            url: "/styling/save_quiz_answers/",
            data_to_server: {
                response: JSON.stringify(responses),
                quiz_id: quiz_id
            },
            data: {},
            method: "post",
            onStart: saveUserPreferenceStarted.type,
            onSuccess: saveUserPreferenceSuccess.type,
            onError: saveUserPreferenceFailed.type
        })
    );
};
import React from 'react';
import Button from '@mui/material/Button';
import InstagramIcon from '@mui/icons-material/Instagram';
import { isMobile } from 'react-device-detect';

const styles = {
    floatingButton: {
        position: 'fixed',
        bottom: isMobile ? 60 : '20px',
        right: '20px',
        backgroundColor: '#FFDEE0',
        color: '#333',
        padding: '10px 20px',
        borderRadius: '5px',
        fontSize: isMobile ? '10px' : '14px',
        textDecoration: 'none',
        zIndex: 9999,
    },
};

const FloatingButton = () => {
    return (
        <Button
            variant="contained"
            style={styles.floatingButton}
            href="https://www.instagram.com/fits.by.zive/"
            target="_blank"
            startIcon={<InstagramIcon />}
        >
            {isMobile ? 'Follow us' : 'Follow us on Instagram'}
        </Button>
    );
};

export default FloatingButton;
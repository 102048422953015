import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ConvertSizeInfoToHeaderAndRows(size_info) {
    const header = [];
    const rows = [];
    var num_measurement_keys = -1;
    for (var index = 0; index < size_info.length; index++) {
        if (index == 0) {
            header.push('');
        }
        header.push(size_info[index]['name']);
        num_measurement_keys = size_info[index]['measurements'].length;
    }

    for (var measure_index = 0; measure_index < num_measurement_keys; measure_index++) {
        var row = []
        for (var index = 0; index < size_info.length; index++) {
            if (index == 0) {
                row.push(size_info[index]['measurements'][measure_index].key);
            }
            row.push(size_info[index]['measurements'][measure_index].value);
        }
        rows.push(row);
    }
    return [header, rows];
}

function CreateSizeTable({ size_info }) {
    console.log(size_info);
    const [header, rows] = ConvertSizeInfoToHeaderAndRows(size_info);
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {header.map((key, index) => {
                            return (
                                <TableCell key={index}>{key}</TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                        >
                            {row.map((key, index_local) => {
                                return (
                                    <TableCell align="left" key={index_local}>{key}</TableCell>
                                );
                            })}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    )
}

export default function SizeChart({ size_chart }) {
    const [expanded, setExpanded] = React.useState(-1);

    const handleChange =
        (panel) => (event, newExpanded) => {
            if (newExpanded) {
                setExpanded(panel);
            }
            else {
                setExpanded(-1);
            }
        };

    return (
        <Box>
            {Object.keys(size_chart).map((key, index) => {
                return (
                    <Accordion expanded={expanded == index} key={index} onChange={handleChange(index)} sx={{
                        backgroundColor: "secondary.main"
                    }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>{key.toUpperCase()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CreateSizeTable size_info={size_chart[key]} />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    )
}
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import UserModel from "./userModel";
import { connect } from "react-redux";
import { resetPassword, submitOtp, requestOtp } from "../../../Store/auth";

import PrimaryInfo from "../../Components/AccountInfo/PrimaryInfo";
import SetPassword from "../../Components/AccountInfo/SetPassword";
import OTPPage from "../../Components/Auth/OTPPage";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function NewAccountInfo(props) {
  const { state } = useLocation();
  const [userObject, setUserObject] = useState(
    new UserModel({
      otp: props.otp,
      phone_number: props.phone_number,
    })
  );
  const [isPasswordPage, setIsPasswordPage] = useState(undefined);

  const handleChange = (e) => {
    setUserObject({
      ...userObject,
      [e.target.name]: e.target.value,
    });
  };

  const requestOTP = () => {
    props.requestOtp({ phone_number: userObject.phone_number });
    setUserObject({
      ...userObject,
      otp: "",
    });
  };

  useEffect(() => {
    if (props.otp_verified) {
      setIsPasswordPage(true);
    }
  }, [props.otp_verified])

  const handleOTPSubmit = (e) => {
    props.submitOtp(userObject.otp);
  };

  const handlePasswordSubmit = (e) => {
    props.resetPassword({
      phone_number: props.phone_number,
      otp: props.otp,
      password1: userObject.password,
      password2: userObject.password_confirm,
    });
    // Lets override user object here.
    setUserObject({
      ...userObject,
      password: '',
      password_confirm: '',
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Account Information
        </Typography>

        <Box sx={{ mt: 3 }}>
          {isPasswordPage === undefined && (
            <PrimaryInfo />
          )}
          {isPasswordPage === false && (
            <OTPPage
              userObject={userObject}
              setUserObject={setUserObject}
              handleSubmit={handleOTPSubmit}
              requestOTP={requestOTP}
              setIsOTPLogin={setIsPasswordPage}
            />
          )}
          {isPasswordPage === true && (
            <SetPassword
              userObject={userObject}
              handleChange={handleChange}
              handlePasswordSubmit={handlePasswordSubmit}
            />
          )}
          {/* {isPasswordPage === undefined && (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{
                borderRadius: "2em",
                marginTop: "1em",
              }}
              onClick={() => setIsPasswordPage(false)}
            >
              Change Password
            </Button>
          )} */}
        </Box>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  phone_number: state.auth.phone_number,
  otp: state.auth.otp,
  otp_verified: state.auth.otp_verified
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (formValues) => dispatch(resetPassword(formValues)),
  submitOtp: (otp) => dispatch(submitOtp(otp)),
  requestOtp: (formValues) => dispatch(requestOtp(formValues, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAccountInfo);

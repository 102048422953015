import React, { useRef } from "react";
import { getMobileOperatingSystem } from '../../../../../Hooks/getOS';
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageList from "@mui/material/ImageList";
import Container from "@mui/material/Container";

const VideoProductsTileItem = ({
    image,
    product_url,
    product_brand,
    product_price,
    slide_index,
    feedback_label,
    postFeedback,
    affiliate_link,
    available,
    colWidth = 50,
    colWeight = 2,
    rowWeight = 2,
}) => {

    const imageRef = useRef();
    const operation_system = getMobileOperatingSystem();

    const showFeed = () => {
        var url_to_open = product_url;
        if (affiliate_link && affiliate_link != '') {
            url_to_open = affiliate_link;
        }
        postFeedback(slide_index, feedback_label != '' ? "video_drawer_item_clicked_" + product_url : feedback_label + "__" + product_url, 1.0);
        if (operation_system == 'iOS') {
            window.open(url_to_open, "_self");
        }
        else {
            window.open(url_to_open, "_blank");
        }
    };

    return (
        <ImageListItem cols={colWeight} rows={rowWeight}>
            <img
                ref={imageRef}
                src={image}
                loading="lazy"
                onClick={showFeed}
                style={{
                    maxidth: colWidth * colWeight,
                    height: colWidth * rowWeight,
                    objectFit: "cover",
                    filter: available == "NOT_AVAILABLE" ? "brightness(0.6)" : "brightness(1.0)"
                }}
            />
            <ImageListItemBar
                title={product_brand}
                subtitle={product_price}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            />
        </ImageListItem>
    );
};


function ProductTilesHelper({ slide_index, feedback_label, product_info, postFeedbackFn, tileItemWidth }) {
    console.log("tileItemWidth:", tileItemWidth);
    return (
        <Container
            component="main"
            maxWidth="sm"
            style={{ padding: 0 }}
        >
            <ImageList
                sx={{
                    width: "100%",
                    height: "inherit",
                    padding: 0,
                    borderTopLeftRadius: "1em",
                    borderTopRightRadius: "1em",
                    margin: "0",
                }}
                variant="quilted"
                cols={2}
            >
                {product_info.map((product_item, key) => {
                    return (
                        <VideoProductsTileItem
                            key={key}
                            image={product_item['image_url']}
                            product_url={product_item['url']}
                            product_brand={product_item['brand']}
                            product_price={product_item['broad_category'] + " / " + (product_item['available'] == "NOT_AVAILABLE" ? "Out of Stock" : product_item['currency'] + ' ' + product_item['price'])}
                            slide_index={slide_index}
                            feedback_label={feedback_label}
                            affiliate_link={product_item['affiliate_link'] ? product_item['affiliate_link'] : ""}
                            colWidth={tileItemWidth}
                            rowWeight={1}
                            colWeight={1}
                            postFeedback={postFeedbackFn}
                            available={product_item['available']}
                        />
                    );
                })}
            </ImageList>
        </Container>
    )
}

export default function ProductTiles({
    currentImageIndex,
    slide_index,
    product_info,
    product_info_list,
    postFeedbackFn,
    tileItemWidth
}) {
    return (
        <>
            {
                product_info.length > 0 && <ProductTilesHelper
                    slide_index={slide_index}
                    feedback_label={""}
                    product_info={product_info}
                    postFeedbackFn={postFeedbackFn}
                    tileItemWidth={tileItemWidth}
                />
            }
            {
                product_info_list.length > 0 && <ProductTilesHelper
                    slide_index={slide_index}
                    feedback_label={String(product_info_list[currentImageIndex]['id'])}
                    product_info={product_info_list[currentImageIndex]['info']}
                    postFeedbackFn={postFeedbackFn}
                    tileItemWidth={tileItemWidth}
                />
            }
        </>

    )
}
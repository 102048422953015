export const MobileTheme = {
    "colorScheme": "DARK",
    "name": "Synthwave Funk Pop",
    "video_src": "/bg_video_12s_half.mp4",
    "img_src": "https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8",
    "colorPalette": [
        "#9488BF",
        "#303E8C",
        "#0F1740"
    ],
    "secondaryPalette": [
        "#8C377E",
        "#A63251"
    ],
    "contrastText": "#ffffff",
    "isMotion": true
};

export const DesktopTheme = {
    "colorScheme": "DARK",
    "name": "City Pop",
    "video_src": "https://assets.mixkit.co/videos/preview/mixkit-conceptual-urban-fashion-42581-large.mp4",
    "img_src": "",
    "colorPalette": [
        "#9488BF",
        "#303E8C",
        "#0F1740"
    ],
    "secondaryPalette": [
        "#8C377E",
        "#A63251"
    ],
    "contrastText": "#ffffff",
    "isMotion": true,
    "img_src": "https://images.unsplash.com/photo-1518123417771-4a83068af6e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8"
};
import React, { useEffect, useState } from "react";

import CollectionListItem from "../../Components/CollectionList";
import LoadingScreen from "../../Components/Common/LoadingScreen";

import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import { connect } from "react-redux";
import { fethcHomeScreenCollections } from '../../../Store/fetch_collection_list';

const CollectionLists = (props) => {
  useEffect(() => {
    props.fethcHomeScreenCollections(6);
  }, []);

  if (props.collection_list.length === 0) return <LoadingScreen />;

  return (
    <>
      <Toolbar />
      <Container component="main" maxWidth="lg">
        {props.collection_list.map((list, key) => {
          return <CollectionListItem key={key} collectionsList={list} />;
        })}
      </Container>
      <Toolbar />
      <Toolbar />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  collection_list: state.fetch_collection_list.collection_list,
});

const mapDispatchToProps = (dispatch) => ({
  fethcHomeScreenCollections: (num_to_fetch) => dispatch(fethcHomeScreenCollections(num_to_fetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionLists);
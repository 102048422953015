import React, { useEffect } from "react";
import { isMobile } from 'react-device-detect';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';


import IconButton from "@mui/material/IconButton";

// import { fetchCart } from '../../../Store/cart_actions';
import { connect } from "react-redux";

function Appbar(props) {
  // useEffect(() => {
  //   if (props.cart.items_count == undefined) {
  //     props.fetchCart();
  //   }
  // }, []);

  if (!isMobile) return <DesktopNavBar items_count={props.cart.items_count == undefined ? 0 : props.cart.items_count} />;
  return (
    <>
      <Box
        color="transparent"
        elevation={0}
        style={{
          backdropFilter: "blur(0px)",
          position: "absolute",
          top: 0,
          zIndex: 50,
          width: "100%",
        }}
      >
        <Toolbar>
          <Typography
            variant="h4"
            component="div"
            fontWeight="500"
            style={{
              flexGrow: 1,
            }}
            fontStyle={{ color: "white" }}
            textAlign="center"
          >
            <img src={`${process.env.PUBLIC_URL}/ZiveLogoBlack.svg`} style={{ height: "60px" }} />
          </Typography>
        </Toolbar>
      </Box>
    </>
  );
}


const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cart: state.cart_actions.cart,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchCart: () => dispatch(fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);


const DesktopNavBar = ({ items_count }) => {
  return (
    <>
      <AppBar
        sx={{
          bgcolor: "primary.main"
        }}
        elevation={0}
        position="fixed"
        style={{
          backdropFilter: !true ? "blur(0px)" : "blur(0px)",
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
            fontStyle={{ color: "primary.textContrast" }}
          >
            <img src={`${process.env.PUBLIC_URL}/ZiveLogoBlack.svg`} style={{ height: "60px" }} />
          </Typography>
          <IconButton href="/" style={{ marginRight: "1em" }} data-tut="desktop_home">
            <HomeIcon sx={{ color: "primary.contrastText" }} size="large" />
          </IconButton>
          {/* <IconButton href="/cart" style={{ marginRight: "1em" }} data-tut="desktop_shop_cart">
            <Badge badgeContent={items_count} color="secondary">
              <ShoppingCartIcon
                sx={{ color: "primary.contrastText" }}
                size="large"
              />
            </Badge>
          </IconButton> */}
          <IconButton href="/profile" style={{ marginRight: "1em" }} data-tut="desktop_profile">
            <AccountCircleIcon
              sx={{ color: "primary.contrastText" }}
              size="large"
            />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};
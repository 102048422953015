import React from "react";
// Components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import { Pagination, Lazy } from "swiper";
import ProductDebugInfo from './CarouselItemProductDebugInfo';
import ProductDescriptionButton from './CarouselItemProductDescriptionButton';
import UpvoteDownvoteReactions from './CarouselItemRatings';
import Box from "@mui/material/Box";

// import required modules.
import "./CarouselStyle.css";

const aspectRatio = 1.3;

const maxTags = 2;

function shuffle(array) {
  let currentIndex = array.length, randomIndex;
  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}


function CarouselSlideImage({ img }) {
  return (
    <img
      effect="blur"
      onError={(event) => (event.target.style.display = "none")}
      onLoad={(event) =>
        (event.target.style.display = "inline-block")
      }
      className="swiper-lazy"
      data-src={img}
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
    />
  );
}

function DisplayTags(props) {
  const arr = new Array(...props.tags);
  const top_k_arr = shuffle(arr).slice(0, maxTags);
  return (
    <>
      {
        top_k_arr.map((tag, key) => {
          return (
            <span
              key={key}
              style={{
                fontWeight: "bold",
                fontStyle: "italic"
              }}
            > {"#" + tag}</span>
          )
        })
      }
    </>
  );
}

function CarouselItem({
  imageList,
  index,
  product_features,
  postFeedbackFn,
  ratings,
  setDrawerOpenStateFn,
  score_annotations,
  screenWidth,
  screenHeight,
  debug_info = false
}) {
  var upDownMargin = Math.max(50, parseInt((screenHeight - screenWidth * aspectRatio) / 2));
  const boxHeight = screenHeight - 2 * upDownMargin;

  function handleSlideChanged() {
    if (swiper.current) {
      postFeedbackFn(index, "carousel_image_swipe" + swiper.current.activeIndex.toString(), 1.0);
    }
    else {
      postFeedbackFn(index, "carousel_image_swipe", 1.0);
    }
  }

  const swiper = React.useRef(null);
  const setSwiper = (newSwiper) => {
    swiper.current = newSwiper;
  };

  return (
    <>
      <Box
        style={{
          maxWidth: window.innerWidth,
          height: "inherit",
          position: "relative",
        }}
        sx={{ backgroundColor: "secondary.main" }}
      >
        <Box height={upDownMargin} className="top-margin-carousel" />
        <Box
          style={{
            margin: 0,
            height: boxHeight,
            width: screenWidth
          }}
        >
          <Swiper
            onTransitionEnd={handleSlideChanged}
            pagination={true}
            loop={false}
            preloadImages={false}
            modules={[Pagination, Lazy]}
            lazy={{
              loadPrevNext: false,
              loadOnTransitionStart: true,
            }}
            onSwiper={setSwiper}
          >
            {/* Display all unique image paths. Since we apply lazy loading, we don't apply upper bound on how many images to pre-load. */}
            {[...new Set(imageList)].map((img, key) => {
              return (
                <SwiperSlide
                  style={{
                    width: screenWidth,
                    height: boxHeight,
                    backgroundImage: `url(${process.env.PUBLIC_URL}loading.gif)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  key={key}
                >
                  <CarouselSlideImage
                    img={img}
                  />
                </SwiperSlide>
              );
            })}
            <ProductDescriptionButton
              index={index}
              product_features={product_features}
              imageList={imageList}
              setDrawerOpenStateFn={setDrawerOpenStateFn}
              postFeedbackFn={postFeedbackFn}
            />
            <ProductDebugInfo
              index={index}
              debug_info={debug_info}
              setDrawerOpenStateFn={setDrawerOpenStateFn}
              score_annotations={score_annotations}
            />
            {/* <div style={{
              position: "absolute",
              top: 15,
              left: 10,
              zIndex: 100
            }}>
              {product_features.brands_info != undefined && product_features.brands_info != undefined && product_features.brands_info.info.top_tags &&
                < DisplayTags tags={product_features.brands_info.info.top_tags}></DisplayTags>
              }
            </div> */}
          </Swiper>
        </Box>
        <div style={{ marginTop: "10px" }}>
          {product_features.brands_info != undefined && !isEmpty(product_features.brands_info) && product_features.brands_info.info.top_tags != undefined &&
            < DisplayTags tags={product_features.brands_info.info.top_tags}></DisplayTags>
          }
        </div>

        {/* <UpvoteDownvoteReactions
          index={index}
          upDownMargin={upDownMargin}
          postFeedbackFn={postFeedbackFn}
          ratings={ratings}
        /> */}
      </Box>
    </>
  );
}
export default CarouselItem;
export default function user(user) {
    if (user === undefined) {
        this.username = 'Sam'
        this.phone_number = ''
        this.old_password = ''
        this.password = ''
        this.password_confirm = ''
        this.first_name = ''
        this.last_name = ''
        this.otp = ""
    } else {
        this.username = user.username === undefined ? 'Sam' : user.username
        this.phone_number = user.phone_number === undefined ? '' : user.phone_number
        this.old_password = user.old_password === undefined ? '' : user.old_password
        this.password = user.password === undefined ? '' : user.password
        this.password_confirm = user.password_confirm === undefined ? '' : user.password_confirm
        this.first_name = user.first_name === undefined ? '' : user.first_name
        this.last_name = user.last_name === undefined ? '' : user.last_name
        this.otp = user.otp === undefined ? '' : user.otp
    }
}
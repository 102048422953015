import React, { useEffect, useState } from "react";

// MUI
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
// import RateReviewIcon from '@mui/icons-material/RateReview';
import InfoIcon from '@mui/icons-material/Info';
import "./Sidebar.css";
import { Typography } from "@mui/material";

const Sidebar = ({
  screen_type,
  item_id,
  item_name,
  item_index,
  postFeedbackFn,
  ratings,
  setDrawerOpenStateFn,
  isAuthenticated,
  thumbsUpDown = false,
  showBrandStory = false
}) => {
  const [upVoteColor, setUpVoteColor] = useState("#a0a0a0");
  const [downVoteColor, setDownVoteColor] = useState("#a0a0a0");
  const [wishlistState, setWishlistState] = useState(false);

  const shareEvent = async () => {
    const productId = item_id === undefined ? "no_id" : item_id;
    try {
      if (screen_type === "PRODUCT") {
        await navigator.share({
          title: item_name,
          url: `/feed?product=${productId}`,
        });
        postFeedbackFn(item_index, "product_shared", 1.0);
      }
      else if (screen_type === "PROMO_VIDEO") {
        await navigator.share({
          title: item_name,
          url: `/feed?video=${productId}`,
        });
        postFeedbackFn(item_index, "video_shared", 1.0);
      }
    } catch (err) {
      console.log("Error: " + err);
    }
  };

  function upvoteFn() {
    setUpVoteColor("green");
    setDownVoteColor("#a0a0a0");
    postFeedbackFn(item_index, "side_up_down_vote", 1.0);
  }

  function downvoteFn() {
    setDownVoteColor("#82fa86");
    setUpVoteColor("#a0a0a0");
    postFeedbackFn(item_index, "side_up_down_vote", 0.0);
  }

  function wishlistFn() {
    if (wishlistState) {
      setWishlistState(false);
      postFeedbackFn(item_index, "wishlist", 0.0);
    } else {
      setWishlistState(true);
      postFeedbackFn(item_index, "wishlist", 1.0);
    }
  }

  useEffect(() => {
    if (ratings.side_up_down_vote != undefined) {
      if (ratings.side_up_down_vote == 1.0) {
        setUpVoteColor("green");
        setDownVoteColor("#a0a0a0");
      } else if (ratings.side_up_down_vote == 0.0) {
        setDownVoteColor("red");
        setUpVoteColor("#a0a0a0");
      } else {
        throw "Undefined side up down ratings.";
      }
    }

    // Initialize the wish list feedback state.
    if (ratings.wishlist != undefined && ratings.wishlist == 1.0) {
      setWishlistState(true);
    }
  }, []);

  return (
    <Box
      style={{
        zIndex: 10,
        position: "absolute",
        top: "50%",
        padding: "2%",
        right: 0,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      {/* Thumbs Up */}
      {thumbsUpDown && (
        <Box display="flex" flexDirection="column">
          <IconButton
            style={{
              color: upVoteColor,
              height: 60,
              width: 60,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            aria-label="thumbs-up"
            onClick={upvoteFn}
          >
            <ThumbUpIcon style={{ transform: "scale(1.5)" }} />
          </IconButton>
        </Box>
      )}

      {/* Thumbs Down */}
      {thumbsUpDown && (
        <Box display="flex" flexDirection="column">
          <IconButton
            style={{
              color: downVoteColor,
              height: 60,
              width: 60,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            aria-label="thumbs-down"
            onClick={downvoteFn}
          >
            <ThumbDownIcon style={{ transform: "scale(1.5)" }}></ThumbDownIcon>
          </IconButton>
        </Box>
      )}

      {!thumbsUpDown && (
        <Box display="flex" flexDirection="column">
          <IconButton
            style={{
              color: wishlistState ? "#9488BF" : "#a0a0a0",
              height: 60,
              width: 60,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            data-tut="sidebar_save"
            aria-label="wishlist"
            onClick={isAuthenticated ? wishlistFn : () => { setDrawerOpenStateFn(true, "login_to_continue"); }}
          >
            {wishlistState ?
              <BookmarkIcon style={{ transform: "scale(1.5)" }}></BookmarkIcon> :
              <BookmarkBorderIcon style={{ transform: "scale(1.5)" }}>
              </BookmarkBorderIcon>
            }
          </IconButton>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}> Save</Typography>
        </Box>
      )}
      {/* COMMENTS */}
      {/* <Box display="flex" flexDirection="column">
        <IconButton
          style={{
            color: "#a0a0a0",
            height: 60,
            width: 60,
            backgroundColor: "rgba(0, 0, 0, 0)",
          }}
          onClick={() => { setDrawerOpenStateFn(true, "comment"); }}
        >
          <MessageIcon style={{ transform: "scale(1.5)" }} />
        </IconButton>
        <Typography variant="subtitle2" style={{ textAlign: "center" }}> Comment</Typography>
      </Box> */}
      {/* Contextual Feedback Button */}
      {/* <Box display="flex" flexDirection="column">
        <IconButton
          style={{
            color: "#a0a0a0",
            height: 60,
            width: 60,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
          onClick={() => { setDrawerOpenStateFn(true, "custom_feedback"); }}
        >
          <RateReviewIcon style={{ transform: "scale(1.5)" }} />
        </IconButton>
        <Typography variant="subtitle2" style={{ textAlign: "center" }}> Feedback</Typography>
      </Box> */}
      {/* SHARE */}
      {(screen_type === "PRODUCT" || screen_type === "PROMO_VIDEO") && (
        <Box display="flex" flexDirection="column">
          <IconButton
            style={{
              color: "#a0a0a0",
              height: 60,
              width: 60,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            data-tut="sidebar_share"
            onClick={shareEvent}
          >
            <ShareOutlinedIcon style={{ transform: "scale(1.5)" }} />
          </IconButton>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}> Share</Typography>
        </Box>
      )}
      {
        showBrandStory && <Box display="flex" flexDirection="column">
          <IconButton
            style={{
              color: "#a0a0a0",
              height: 60,
              width: 60,
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            onClick={() => { setDrawerOpenStateFn(true, "brand_story"); }}
          >
            <InfoIcon style={{ transform: "scale(1.5)" }} />
          </IconButton>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>About</Typography>
        </Box>
      }
    </Box>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import PrimaryInfo from "../../Components/AccountInfo/PrimaryInfo";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";


export default function UpdatePrimaryInfo(props) {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Primary Info
                </Typography>

                <Box sx={{ mt: 3 }}>
                    <PrimaryInfo navigatePreviousOnSubmit={true} />
                </Box>
            </Box>
        </Container>
    );
}
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { ErrorMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "fetch_insta_outfits_feed",
    initialState: {
        collection_list: [],
        // end_of_item_reached signifies if the end of items was reached and we should not make any more calls.
        end_of_item_reached: false,
    },
    reducers: {
        // action => action handler
        fetchInstaOutfitsFeedStarted: (state, action) => {
        },
        fetchInstaOutfitsFeedSuccess: (state, action) => {
            if (action.payload.data.start_from < state.collection_list.length) {
                // This can heppen when react runs same code twice in dev mode and call the API with same params twice.
                // Looks like a duplicate API request
                return;
            }
            state.collection_list = [...state.collection_list, ...action.payload.server_response.response.items];;
            // TODO(siddhant): Currently we overwrite, so its alright, but we need to support concatenation logic.
            state.end_of_item_reached = action.payload.server_response.response.end_of_item_reached;
        },
        fetchInstaOutfitsFeedFailed: (state, action) => {
            ErrorMessage("Error fetching collections.");
        },
    }
});

export const {
    fetchInstaOutfitsFeedStarted,
    fetchInstaOutfitsFeedSuccess,
    fetchInstaOutfitsFeedFailed
} = slice.actions;

export default slice.reducer;

export const fetchInstaOutfitsFeed = (num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().fetch_insta_outfits_feed.collection_list.length;
    var end_of_item_reached = getState().fetch_insta_outfits_feed.end_of_item_reached;
    if (end_of_item_reached) {
        return
    }

    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_insta_outfit_feed/",
            data_to_server: { start_from: start_from, num_to_fetch: num_to_fetch },
            data: { start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchInstaOutfitsFeedStarted.type,
            onSuccess: fetchInstaOutfitsFeedSuccess.type,
            onError: fetchInstaOutfitsFeedFailed.type
        })
    );
};
import React from "react";
import { useNavigate } from "react-router-dom";

import CardItem from "../CollectionItem/CardItem";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "./CollectionListItem.css";

// MUI
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const numOfSlides = window.innerWidth < 900 ? 1.4 : 2.6

const CollectionListItem = ({ collectionsList }) => {
  let shuffledList = collectionsList.collections;

  const navigate = useNavigate();

  const redirectCollection = () => {
    navigate(`/collection?id=${collectionsList._id}`);
  };

  return (
    <>
      <Box className="list-container" style={{ marginBottom: "3em" }}>
        <Box display="flex">
          <Typography
            variant="subtitle1"
            style={{
              display: "flex",
              flexGrow: 1,
              zIndex: 1,
            }}
          >
            {collectionsList.visible_text}
          </Typography>
          {/* <Button onClick={redirectCollection} style={{ fontSize: "0.8em" }}>see more</Button> */}
        </Box>
        <Swiper
          slidesPerView={numOfSlides}
          spaceBetween={20}
          freeMode={true}
          watchSlidesProgress
          navigation={true}
          modules={[FreeMode, Navigation]}
          className="collection-list-swiper"
          cssMode={true}
        >
          {shuffledList.map((collection, key) => {
            return (
              <SwiperSlide
                key={key}
                data-tut="sample_collection"
              >
                <CardItem collection={collection} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* <Divider style={{ marginTop: "3vw", color: "black" }} /> */}
      </Box>
    </>
  );
};

export default CollectionListItem;

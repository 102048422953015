import React, { useState, useRef, useEffect } from "react";

export const useCustomForm = ({ initialValues }) => {
    const [values, setValues] = useState(initialValues || {});
    const formRendered = useRef(true);

    useEffect(() => {
        if (!formRendered.current) {
            setValues(initialValues);
        }
        formRendered.current = false;
    }, []);


    const handleChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        if (event.target.type == "checkbox") {
            setValues({ ...values, [name]: event.target.checked });
        }
        else {
            setValues({ ...values, [name]: value });
        }
        try {
            // event persiste does not exist for select multiple.
            event.persist();
        }
        catch (err) {
            ;
        }
    };

    const init = (updatedValues) => {
        setValues(updatedValues);
    };

    return {
        values,
        handleChange,
        init
    };
};

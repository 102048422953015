import React, { useEffect } from "react";
//MUI
import Container from "@mui/material/Container";
import CheckoutSummaryCard from '../../Components/Cart/CheckoutSummary';
import AddressCards from '../../Components/Cart/AddressCards';
import { fetchCart, fetchAllAddress, placeOrder } from '../../../Store/cart_actions';
import { ErrorMessage } from "../../../Controllers/Notifications";
import { useNavigate } from "react-router-dom";
import { updateFirstAndLastName } from "../../../Store/auth";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { connect } from "react-redux";

const Checkout = (props) => {
    const navigate = useNavigate();
    const [selectedAddress, setSelectedAddress] = React.useState(0);

    useEffect(() => {
        props.fetchCart();
        props.fetchAllAddress();
    }, []);

    useEffect(() => {
        if (props.placing_order) {
            // Just started placing order. Lets no do anything.
            ;
        }
        else {
            // placing_order completed.
            // Either, its default, or order was placed or failed.
            //  Signifies failed.
            if (props.order_placed_id == -2) {
                navigate('/');
            }
            else if (props.order_placed_id >= 0) {
                navigate('/order_details/' + props.order_placed_id + '/');
            }
            else {
                // Lets not do anything.
            }
        }
    }, [props.placing_order, props.order_placed_id])

    function placeOrder() {
        if (props.first_name == '' && props.last_name == '') {
            ErrorMessage("Name should be specified.");
        }

        else if (selectedAddress < props.address.length) {
            props.placeOrder(props.address[selectedAddress].address_id);
        }
        else {
            ErrorMessage("Address should be specified.");
        }
        // console.log("selectedAddress", props.address);
    }

    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: 100, overflow: "scroll", marginBottom: 60 }}>
            <CheckoutSummaryCard
                cart={props.cart}
                buttonText={"Place Order"}
                buttonAction={() => { placeOrder() }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: "10px", paddingBottom: "10px" }}>
                <Typography variant="subtitle2" component="div">
                    <span style={{ float: "left" }}>Name: {props.first_name} {props.last_name}</span>
                    <span style={{ float: "right" }}>
                        <IconButton aria-label="edit" onClick={() => { navigate('/primary_info') }} style={{ height: 20, width: 20 }} sx={{ color: "primary.contrastText" }}>
                            <BorderColorIcon style={{ transform: "scale(0.7)" }} />
                        </IconButton>
                    </span>
                </Typography>
            </Box>
            <AddressCards
                address_list={props.address}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
            />
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    cart: state.cart_actions.cart,
    address: state.cart_actions.address,
    order_placed_id: state.cart_actions.order_placed_id,
    placing_order: state.cart_actions.placing_order,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCart: () => dispatch(fetchCart()),
    fetchAllAddress: () => dispatch(fetchAllAddress()),
    placeOrder: (addressId) => dispatch(placeOrder(addressId)),
    updateFirstAndLastName: (formValues) => dispatch(updateFirstAndLastName(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const intialTime = {
  seconds: 30,
  minutes: 1,
};
const OTPLength = 6;

const OTPPage = ({
  userObject,
  setUserObject,
  handleSubmit,
  requestOTP,
  setIsOTPLogin,
  switchLink = '',
  switchText = ''
}) => {
  const [countDown, setCountDown] = useState(intialTime);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isRequestNewOTP, setIsRequestNewOTP] = useState(undefined);

  useEffect(() => {
    handleRequest();
  }, []);

  useEffect(() => {
    if (userObject.otp.length === OTPLength) {
      setDisableSubmit(false);
    } else setDisableSubmit(true);
  }, [userObject.otp, isRequestNewOTP]);

  useEffect(() => {
    let intervalID = 0;
    if (isRequestNewOTP === false) {
      let intervalID = setTimeout(() => {
        if (countDown.seconds > 0) {
          setCountDown({
            ...countDown,
            seconds: countDown.seconds - 1,
          });
        }
        if (countDown.seconds === 0) {
          if (countDown.minutes === 0) {
            clearInterval(intervalID);
          } else {
            setCountDown({
              ...countDown,
              seconds: 59,
              minutes: countDown.minutes - 1,
            });
          }
        }
      }, 1000);
    }
    return () => {
      clearTimeout(intervalID);
    };
  }, [isRequestNewOTP, countDown]);

  useEffect(() => {
    if (countDown.minutes === 0 && countDown.seconds === 0) {
      setIsRequestNewOTP(true);
    }
  }, [countDown]);

  const getTimer = () => {
    return (
      "0" +
      countDown.minutes.toString() +
      ":" +
      (countDown.seconds < 10 ? `0${countDown.seconds}` : countDown.seconds)
    );
  };

  const handleRequest = () => {
    setCountDown(intialTime);
    requestOTP(true);
    setIsRequestNewOTP(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Typography
          style={{
            marginTop: "0.4em",
            marginLeft: "0.5em",
          }}
        >
          {getTimer()}
        </Typography>
        <Button
          style={{
            borderRadius: "2em",
            textTransform: "none",
            justifyContent: "flex-end",
          }}
          fullWidth
          onClick={handleRequest}
          disabled={!isRequestNewOTP}
        >
          <Typography> Request new OTP </Typography>
        </Button>
      </Box>
      <OtpInput
        name="otp"
        value={userObject.otp}
        onChange={(newValue) =>
          setUserObject({
            ...userObject,
            otp: newValue,
          })
        }
        numInputs={OTPLength}
        label="OTP"
        isInputNum={true}
        containerStyle={{ width: "100%" }}
        inputStyle={{
          width: "100%",
          height: "3em",
          marginLeft: "0.5em",
          marginRight: "0.5em",
          fontSize: "1em",
        }}
      />
      <Button
        fullWidth
        variant="contained"
        disabled={disableSubmit || isRequestNewOTP}
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          borderRadius: "2em",
        }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      {!isRequestNewOTP &&
        <Typography textAlign="right">
          Sent OTP through SMS to +{userObject.phone_number}
        </Typography>
      }
      {switchLink != '' && <Button
        style={{
          borderRadius: "2em",
          textTransform: "none",
          justifyContent: "flex-end",
        }}
        fullWidth
        href={switchLink}
      >
        <Typography>{switchText}</Typography>
      </Button>
      }
      <Box
        display="flex"
        flexDirection="row"
        style={{
          position: "fixed",
          bottom: "4em",
        }}
      >
        <Button
          style={{
            borderRadius: "2em",
            textTransform: "none",
            justifyContent: "flex-start",
          }}
          fullWidth
          onClick={() => {
            setIsOTPLogin(undefined);
            setUserObject({
              ...userObject,
              ['otp']: '',
            });
          }}
        >
          <ArrowBackIcon />
          <Typography>Back</Typography>
        </Button>
      </Box>
    </>
  );
};

export default OTPPage;
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SizeDropDown from './SizeDropdown';
import CloseIcon from '@mui/icons-material/Close';
import QuantityCounter from './QuantityCounter';
import Grid from '@mui/material/Grid';
import {
    useNavigate,
} from "react-router-dom";


function GetVariantProductImage(variant, product) {
    if (variant.images && variant.images.length > 0) {
        return variant.images[0];
    } else {
        return product.image_url;
    }
}
export default function ProductCard({ product, quantity, variant, updateQuantity, removeItem, isOrder }) {
    let navigate = useNavigate();
    const [variant1_value, setVariant1_value] = React.useState(variant.variant1_value);
    const [variant2_value, setVariant2_value] = React.useState(variant.variant2_value);
    const [variant3_value, setVariant3_value] = React.useState(variant.variant3_value);
    const [variant4_value, setVariant4_value] = React.useState(variant.variant4_value);

    const init = React.useRef(false);
    React.useEffect(() => {
        setVariant1_value(variant.variant1_value);
        setVariant2_value(variant.variant2_value);
        setVariant3_value(variant.variant3_value);
        setVariant4_value(variant.variant4_value);
    }, [variant]);

    if (quantity == 0) {
        return (
            <></>
        );
    }

    return (
        <Card variant="outlined" sx={{ display: 'flex', position: "relative", borderWidth: "2px", my: 1, backgroundColor: "secondary.main" }} >
            <CardMedia
                component="img"
                sx={{ width: 151, height: 151 }}
                image={GetVariantProductImage(variant, product)}
                alt={product.title}
                onClick={() => {
                    navigate('/product/' + product.product_id);
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <CardContent sx={{ flex: '1 0 auto' }}
                    onClick={() => {
                        navigate('/product/' + product.product_id);
                    }}>
                    <Typography component="div" variant="subtitle2">
                        {product.title}
                    </Typography>
                    <Typography variant="body1" component="div" style={{ fontSize: "0.6em" }}>
                        {product.brand}
                    </Typography>
                    <Typography variant="body1" component="div">
                        Rs {variant.discount_price}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: "flex-start", pl: 1, pb: 1 }}>
                    <Grid container spacing={0} style={{ width: "100%" }}>
                        <Grid item xs={9}>
                            {variant.variant1_name != "" &&
                                <SizeDropDown availableValue={[variant1_value]} selectedValue={variant1_value} setSelectedValue={setVariant1_value} label={variant.variant1_name}></SizeDropDown>
                            }
                            {variant.variant2_name != "" &&
                                <SizeDropDown availableValue={[variant2_value]} selectedValue={variant2_value} setSelectedValue={setVariant2_value} label={variant.variant2_name}></SizeDropDown>
                            }
                            {variant.variant3_name != "" &&
                                <SizeDropDown availableValue={[variant3_value]} selectedValue={variant3_value} setSelectedValue={setVariant3_value} label={variant.variant3_name}></SizeDropDown>
                            }
                            {variant.variant4_name != "" &&
                                <SizeDropDown availableValue={[variant4_value]} selectedValue={variant4_value} setSelectedValue={setVariant4_value} label={variant.variant4_name}></SizeDropDown>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <QuantityCounter quantity={quantity} setQuantity={updateQuantity} isOrder={isOrder}></QuantityCounter>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <div style={{
                position: "absolute",
                right: 0,
                top: 5,
                width: 25,
                height: 25,
            }}>
                {!isOrder &&
                    <IconButton aria-label="remove" style={{ width: 20, height: 20 }} sx={{ color: "primary.contrastText" }} onClick={() => { removeItem() }}>
                        <CloseIcon style={{ transform: "scale(0.8)" }} />
                    </IconButton>
                }
            </div>
        </Card>
    );
}
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const RatingContext = ({
    rating_context,
    setDrawerOpenStateFn,
    imageFixed = false,
    width = "100%",
    height = "100%",
}) => {
    return (
        <>
            <Box
                style={{
                    width: width,
                    height: height,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 0,
                    backgroundImage: `url(${rating_context.image_path})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: imageFixed ? "fixed" : "scroll",
                }}
            >
                {
                    rating_context.title !== undefined && rating_context.title !== "" && (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backdropFilter: "blur(2px)",
                                backgroundColor: "rgba(255, 255, 255, 0.4)",
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="h6"
                                padding="5%"
                                textAlign="center"
                                color={"black"}
                            >
                                {rating_context.title}
                            </Typography>
                        </Box>
                    )
                }
                <Box
                    style={{
                        display: "flex",
                        marginBottom: "1em",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            marginBottom: "2em",
                            textTransform: "none",
                            padding: "1em"
                        }}
                        onClick={() => { setDrawerOpenStateFn(true, "rating_options"); }}
                    >
                        {rating_context.action_text == undefined ?
                            <>
                                <Typography variant="h6">Select Items</Typography>
                            </> :
                            <Typography variant="h6">{rating_context.action_text}</Typography>
                        }
                    </Button>
                </Box>

            </Box>
        </>
    );
};

export default RatingContext;

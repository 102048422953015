import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import VideoCard from "./VideoCard";
import ImageItem from "./ImageItem";

//MUI
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const CardItem = ({ collection }) => {
  const [componentWidth, setComponentWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      let width = 0;
      if (window.innerWidth > 800) {
        width = window.innerWidth / 8 - window.innerWidth / 80;
      } else {
        width = window.innerWidth / 5;
      }
      setComponentWidth(width);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const navigate = useNavigate();

  const redirect = () => {
    const collectionId = collection.id === undefined ? "no_id" : collection.id;
    navigate(`/feed?collection=${collectionId}`);
  };

  return (
    <>
      {collection.visible_name != null && (
        <Card
          sx={{
            maxWidth: "100%",
            border: "none",
            boxShadow: "none",
            position: "relative",
            borderRadius: "1em",
            backgroundColor: "secondary.main",
          }}
        >
          <CardActionArea onClick={redirect}>
            {collection.media_type == "VIDEO" && (
              <VideoCard src={collection.video} componentWidth={componentWidth} />
            )}
            {collection.media_type == "IMAGE" && (
              <ImageItem image={collection.image_url} componentWidth={componentWidth} />
            )}
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ paddingLeft: "2%", paddingBottom: "2%" }}
            >
              {collection.visible_name}
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ paddingLeft: "2%", paddingBottom: "2%" }}
            >
              {collection.subtitle != '' ? collection.subtitle : '.'}
            </Typography>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

export default CardItem;

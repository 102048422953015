import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "bottom_drawer",
    initialState: {
        isOpen: false,
        drawerType: ""
    },
    reducers: {
        openDrawer: (state, action) => {
            state.isOpen = action.payload.open;
            if (action.payload.drawerType != "NA" && action.payload.drawerType != "NA1") {
                state.drawerType = action.payload.drawerType;
            }
        },
    }
});

export const {
    openDrawer
} = slice.actions;

export default slice.reducer;

export const setDrawerOpenStateFn = (open, drawerType) => (dispatch, getState) => {
    return dispatch({ type: openDrawer.type, payload: { open: open, drawerType: drawerType } });
};
import './App.css';

// Router.
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from "react-router-dom";

// MUI imports.
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";

// Local Components.

// Auth flows.
import UpdatePrimaryInfo from './Views/Pages/UpdatePrimaryInfo';
import Logout from './Views/Pages/Logout';
import UnifiedAuth from './Views/Pages/UnifiedAuth';

// Home / Dashboard.
import Dashboard from './Views/Pages/Dashboard';

// Single and list of collections view.
import Collection from './Views/Pages/Collection';
import CollectionLists from './Views/Pages/CollectionLists';

// User Profile.
import Profile from './Views/Pages/Profile'
import AccountInfo from './Views/Pages/AccountInfo';

// User Quiz flow
// import Quiz from './Views/Pages/Quiz';

// Bottom swipeable drawer.
import SwipeableDrawerView from './Views/Pages/SwipeableDrawer';

// Navigational bars
import Navbar from './Views/Components/NavBars/Navbar';
import Appbar from './Views/Components/NavBars/Appbar';

// Shopping and cart details.
import Cart from './Views/Pages/Cart';
import Checkout from './Views/Pages/Checkout';
import AddressForm from './Views/Pages/AddressForm';

import ProductPage from './Views/Pages/ProductPage';

// User Item Feeds
import FeedView from './Views/Pages/Feed';

// Orders and order details.
import OrderDetails from './Views/Pages/OrderDetails';
import Orders from './Views/Pages/Orders';

import InstaFeedWithBanner from './Views/Pages/InstaLikeFeed';

import RatingOptions from './Views/Components/RatingOptions';

// Fixed imports
import FixedNotification from './Controllers/Notifications';

// Get the app theme.
import GetStatelessTheme from './Controllers/GetStatelessTheme';
import { GridModeProvider } from './GridModeContext';

// Redux based imports.
import { reduxStore, reduxPersistStore } from "./Store/configureStore";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
// We inject store here, so we can dispatch and query state from outside the component tree.
// We do this specifically in axios interceptors.
import { injectStore } from "./Store/middleware/api";
injectStore(reduxStore);


function AppHelper() {
  return (
    <Switch>
      <Route path="/topics" element={<Dashboard />} />
      <Route path="/feed" element={<FeedView />} />
      < Route path="/profile" element={< Profile />} />
      < Route path="/account" element={< AccountInfo />} />
      < Route path="/authentication" element={< UnifiedAuth />} />
      < Route path="/logout" element={< Logout />} />
      < Route path="/collectionLists" element={< CollectionLists />} />
      < Route path="/collection" exact element={< Collection />} />
      < Route path="/cart" element={< Cart />} />
      < Route path="/checkout" element={< Checkout />} />
      < Route path="/address" element={< AddressForm />} />
      < Route path="/product/:productId" element={< ProductPage />} />
      < Route path="/orders" element={< Orders />} />
      < Route path="/order_details/:orderId" element={< OrderDetails />} />
      < Route path="/primary_info" element={< UpdatePrimaryInfo />} />
      < Route path="/p/:pageName" element={< Dashboard />} />
      < Route path="/rating_options" element={< RatingOptions />} />
      < Route path="/" exact element={< FeedView />} />
      {/* <Route path="/unified_auth" element={<UnifiedAuth />} /> */}
      {/* <Route path="/register" element={<Register />} /> */}
      {/* <Route path="/new-account" element={<NewAccountInfo />} /> */}
      {/* <Route path="/quiz/:quizId" element={<Quiz theme={theme} />} /> */}
    </Switch >
  );
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={GetStatelessTheme()}>
        <CssBaseline />
        <FixedNotification />
        <Router>
          <Provider store={reduxStore}>
            <PersistGate loading={null} persistor={reduxPersistStore}>
              <GridModeProvider>
                <Appbar />
                <SwipeableDrawerView />
                <AppHelper />
                <Navbar />
              </GridModeProvider>
            </PersistGate >
          </Provider >
        </Router >
      </ThemeProvider >
    </div >
  );
}

export default App;

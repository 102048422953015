import { createTheme } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { MobileTheme, DesktopTheme } from '../Constants/theme';

const fontStyleOptions = {
    typography: {
        fontFamily: 'Work Sans',
        h1: {
            fontFamily: 'Merriweather',
        },
        h2: {
            fontFamily: 'Merriweather',
        },
        h4: {
            fontFamily: 'Merriweather',
        },
        h3: {
            fontFamily: 'Merriweather',
        },
        h5: {
            fontFamily: 'Merriweather',
        },
        h6: {
            fontFamily: 'Merriweather',
        },
        subtitle1: {
            fontFamily: 'Work Sans',
            fontSize: "1.2em"
        },
        subtitle2: {
            fontFamily: 'Work Sans',
            fontSize: "1.0em"
        },
        body1: {
            fontFamily: 'Work Sans',
            fontSize: "0.9em"
        },
        button: {
            fontFamily: 'Merriweather',
        },
        overline: {
            fontFamily: 'Merriweather',
        },
    }
}

const iosSwitchOverride = {
    MuiSwitch: {
        styleOverrides: {
            root: {
                width: 46,
                height: 26,
                padding: 0,
                margin: 8,
            },
            switchBase: {
                padding: 1,
                '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + $track': {
                        opacity: 1,
                        border: 'none',
                    },
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 13,
                border: '1px solid #bdbdbd',
                backgroundColor: '#315167',
                opacity: 1,
                transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
    }
}

const getMUITheme = (theme) => {
    const isDark = theme.colorScheme === "DARK"
    const main = isDark ? theme.secondaryPalette[0] : theme.secondaryPalette[1]
    const secondary = isDark ? theme.secondaryPalette[1] : theme.secondaryPalette[0]
    const background = isDark ? theme.colorPalette[2] : theme.colorPalette[0]
    const palette = {
        palette: {
            mode: !isDark ? 'light' : 'dark',
            primary: {
                main: "#FFFFFF",
                contrastText: "#000000"
            },
            secondary: {
                main: '#e1e1e1',
            },
            background: {
                default: "#f1f1f1",
                paper: '#ffffff',
            },
            error: {
                main: '#d50000',
            },
            text: {
                primary: "#000000"
            },
            custom: {
                light: '#000000',
                main: '#000000',
                dark: '#000000',
                contrastText: 'rgba(255, 255, 255, 1.0)',
            }
        }
    }
    return createTheme({ ...palette, ...fontStyleOptions, components: { ...iosSwitchOverride } })
}

export default function getStatelessTheme() {
    if (isMobile) {
        return getMUITheme(MobileTheme);
    }
    else {
        return getMUITheme(DesktopTheme);
    }
}

export function getThemeConfig() {
    if (isMobile) {
        return MobileTheme;
    }
    else {
        return DesktopTheme;
    }
}
import React from "react";
import Box from "@mui/material/Box";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Button from "@mui/material/Button";
import {
    useNavigate,
    useLocation,
} from "react-router-dom";


export function OpenProductPage({ productId, setDrawerOpenStateFn }) {
    let navigate = useNavigate();
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "10px",
                paddingRight: "10px",
                marginBottom: "1em",
            }}
        >
            <Button
                fullWidth
                variant="contained"
                style={{
                    marginTop: "5px",
                    textTransform: "none",
                    border: '1px solid'
                }}
                onClick={() => {
                    setDrawerOpenStateFn(false, "NA");
                    navigate('/product/' + productId);
                }}
            >
                <ShoppingBagIcon style={{ marginRight: "5px" }} />
                View product details.
            </Button>
        </Box>
    )
}

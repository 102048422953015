import React, { useEffect, useState } from 'react'

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PasswordPage = ({
    userPassword,
    setUserPassword,
    handleSubmit,
    setPageState,
}) => {

    const [disableSubmit, setDisableSubmit] = useState(true)
    useEffect(() => {
        if (userPassword.length > 0) {
            setDisableSubmit(false)
        }
        else {
            setDisableSubmit(true)
        }
    }, [userPassword])


    return (
        <>
            <Box sx={{ mt: 2 }}>
                <TextField
                    className="round-textfield"
                    name="password"
                    value={userPassword}
                    onChange={(event) => { setUserPassword(event.target.value) }}
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiInputLabel-root": { color: "primary.contrastText" }
                    }}
                />
            </Box>
            <Button
                fullWidth
                variant="contained"
                disabled={disableSubmit}
                color="custom"
                style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    borderRadius: "2em",
                }}
                onClick={handleSubmit}
            >
                Submit
            </Button>
            <Button
                style={{
                    borderRadius: "2em",
                    textTransform: "none",
                    justifyContent: "flex-start",
                }}
                fullWidth
                onClick={() => {
                    setPageState('home');
                    setUserPassword('');
                }}
                color="custom"
            >
                <ArrowBackIcon />
                <Typography>Back</Typography>
            </Button>

        </>
    );
};

export default PasswordPage
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddressCard from "./AddressCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "./AddressCards.css";
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// MUI
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const numOfSlides = window.innerWidth < 900 ? 1.4 : 1.8

const AddressCards = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: "10px", paddingBottom: "10px" }}>
                <Typography variant="subtitle2" component="div">
                    <span style={{ float: "left" }}>Shipping Address</span>
                    <span style={{ float: "right" }}>
                        <IconButton aria-label="edit" onClick={() => { navigate('/address') }} style={{ height: 20, width: 20 }} sx={{ color: "primary.contrastText" }}>
                            <AddCircleIcon style={{ transform: "scale(0.7)" }} />
                        </IconButton>
                    </span>
                </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
                <Swiper
                    slidesPerView={numOfSlides}
                    spaceBetween={20}
                    freeMode={true}
                    watchSlidesProgress
                    navigation={true}
                    modules={[FreeMode, Navigation]}
                >
                    {props.address_list.map((address, key) => {
                        return (
                            <SwiperSlide
                                key={key}
                            >
                                <AddressCard address={address} isSelected={props.selectedAddress == key} index={key} onSelected={props.setSelectedAddress} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
        </>
    );
};

export default AddressCards;

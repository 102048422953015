import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

export default function Policy({ policy }) {
    const [expanded, setExpanded] = React.useState(-1);

    const handleChange =
        (panel) => (event, newExpanded) => {
            if (newExpanded) {
                setExpanded(panel);
            }
            else {
                setExpanded(-1);
            }
        };

    return (
        <Box>
            {Object.keys(policy).map((key, index) => {
                return (
                    <Accordion expanded={expanded == index} onChange={handleChange(index)} sx={{
                        backgroundColor: "secondary.main"
                    }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>{key.toUpperCase()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {policy[key]}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    )
}
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player'
import styles from "./VideoItem.css"
import { getMobileOperatingSystem } from '../../../../../Hooks/getOS'
import IconButton from "@mui/material/IconButton/IconButton";
import {
  PlayArrow,
  Pause,
  VolumeOff
} from "@mui/icons-material";

import ProductCarousel from "./ProductCarousel";

// import UpvoteDownvoteReactions from '../Carousel/CarouselItemRatings';

// MUI
import Box from "@mui/material/Box";
import ShopItemButtonTransparent from '../Common/ShopActionButton';
import ReplayIcon from '@mui/icons-material/Replay';

// Prevent Native video fullscreen on ios devices
// https://stackoverflow.com/questions/5054560/can-i-avoid-the-native-fullscreen-video-player-with-html5-on-iphone-or-android
const VideoItem = ({
  src,
  drawer_trigger_text,
  has_items,
  product_info,
  index,
  setDrawerOpenStateFn,
  postFeedbackFn,
  should_play,
  screenHeight,
  screenWidth,
  userUnmutedOnce,
  maybeUpdateGlobalUnmute,
  showProgressBar = false
}) => {
  const operation_system = getMobileOperatingSystem();

  const hasUserUpdatedMute = useRef();

  useEffect(() => {
    // User hasn't updated mute so far for this video. So, we copy over the global value if the gloabl value was changed.
    // We however only do this on Android, as it still doesn't work on IOS.
    if (!hasUserUpdatedMute.current && userUnmutedOnce) {
      if (operation_system != 'iOS') {
        setVideoState({ ...videoState, muted: false });
      }
    }
  }, [userUnmutedOnce]);

  const [videoState, setVideoState] = useState({
    isPlay: false,
    currentSeek: 0,
    durationOfVideo: 0,
    muted: operation_system != 'iOS' && userUnmutedOnce ? false : true
  });

  const refHostVideo = useRef();
  const handleMute = () => {
    maybeUpdateGlobalUnmute();
    // Update that user has already updated mute here, its default value will not be changed.
    hasUserUpdatedMute.current = true;
    setVideoState({ ...videoState, muted: !muted });
  };

  const handleOnProgress = ({ playedSeconds }) => {
    setVideoState({ ...videoState, currentSeek: playedSeconds });
  };

  const handleRestart = (event) => {
    setVideoState({ ...videoState, currentSeek: 0 });
    refHostVideo.current.seekTo(0);
    event.stopPropagation();
  };

  const handleSeekChange = ({ target }) => {
    setVideoState({ ...videoState, currentSeek: target.value });
    refHostVideo.current.seekTo(target.value);
  };
  const handlePlay = (event) => {
    setVideoState({ ...videoState, isPlay: true, durationOfVideo: refHostVideo.current.getDuration() });
    event.stopPropagation();
  };
  const handlePause = (event) => {
    setVideoState({ ...videoState, isPlay: false });
    event.stopPropagation();
  };
  const { isPlay, currentSeek, durationOfVideo, muted } = videoState;

  useEffect(() => {
    setVideoState({ ...videoState, isPlay: should_play });
  }, [should_play]);

  const onVideoPress = () => {
    handleMute();
  }
  return (
    <>
      <Box
        style={{
          width: screenWidth,
          height: screenHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <ReactPlayer
          url={src}
          className={styles.reactPlayer}
          width="100%"
          height="100%"
          muted={muted}
          playing={isPlay}
          ref={refHostVideo}
          // onProgress={handleOnProgress}
          controls={false}
          playsinline={true}
          loop={true}
        />
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            left: "0%",
            top: "0%",
            width: "100%",
            height: "85%",
            backgroundColor: "rgba(0,0,0,0)",
          }}
          onClick={onVideoPress}
        >
        </div>
        {muted &&
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              left: "40%",
              top: "40%"
            }}
          >
            <IconButton
              style={{
                color: "rgba(255, 255, 255, 0.8)",
                height: 100,
                width: 100,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
              }}
              onClick={handleMute}>
              <VolumeOff style={{
                height: 60,
                width: 60,
              }} fontSize='inherit' />
            </IconButton>
          </div>
        }
        {/* Optionally show index, can be useful when tagging items. */}
        {/* <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            zIndex: 1,
            left: 10,
          }}
        >
          <p>
            {index}
          </p>
        </div> */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            zIndex: 1,
            left: 10,
          }}
        >
          <div align="center" style={{ float: 'left', width: "10%" }}>
            {!isPlay ? (<IconButton
              style={{
                color: 'white',
                height: 60,
                width: 60,
                backgroundColor: "rgba(0, 0, 0, 0)",
              }}
              onClick={handlePlay}>
              <PlayArrow fontSize='inherit' />
            </IconButton>) :
              (<IconButton
                style={{
                  color: 'white',
                  height: 60,
                  width: 60,
                  backgroundColor: "rgba(0, 0, 0, 0)",
                }}
                onClick={handlePause}>
                <Pause fontSize='inherit' />
              </IconButton>)
            }
          </div>
          {showProgressBar &&
            <div align="center" style={{ float: 'left', width: "90%", marginTop: "15px" }}>
              <input
                type='range'
                min={0}
                max={durationOfVideo}
                value={currentSeek}
                onInput={(e) => handleSeekChange(e)}
                style={{ width: '100%' }}
              />
            </div>
          }
          {!showProgressBar &&
            <div align="center" style={{ float: 'left', width: "10%" }}>
              <IconButton
                style={{
                  color: 'white',
                  height: 60,
                  width: 60,
                  backgroundColor: "rgba(0, 0, 0, 0)",
                }}
                onClick={handleRestart}>
                <ReplayIcon fontSize='inherit' />
              </IconButton>
            </div>
          }
        </div>
      </Box>
      <div
        style={{
          position: "absolute",
          width: "75%",
          bottom: 50,
          zIndex: 1,
          left: 0,
        }}
      >
        {/* <UpvoteDownvoteReactions
          index={index}
          upDownMargin={50}
          postFeedbackFn={postFeedbackFn}
          ratings={{}}
        /> */}
        {
          has_items && drawer_trigger_text != '' && operation_system == 'iOS' &&
          <ShopItemButtonTransparent
            clickFn={() => {
              setDrawerOpenStateFn(true, "video_product_list");
              postFeedbackFn(index, 'video_product_drawer', 1.0);
            }}
            upperText={""}
            middleText={drawer_trigger_text}
            lowerText={""}
            leftImage={""}
            rightCircleClickFn={() => { }}
            rightCircleText={"View"}
            data_tutorial_label={"video_explore"}
          ></ShopItemButtonTransparent>
        }
        {
          has_items && operation_system != 'iOS' &&
          <div style={{ paddingLeft: "20px" }}>
            <ProductCarousel product_info={product_info} />
          </div>
        }
      </div>
    </>
  );
};

export default VideoItem;
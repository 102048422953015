import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function CheckoutSummaryCard({ showButton = true, ...props }) {
    return (
        <Box sx={{ minWidth: 275, paddingTop: "10px", paddingBottom: "20px" }}>
            <Card variant="outlined" style={{ border: "1px solid black" }}>
                <React.Fragment>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Total Items Value</span>
                                <span style={{ float: "right" }}>Rs {props.cart.total_price != undefined ? props.cart.total_price + props.cart.total_customer_profit : 0}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Shipping</span>
                                <span style={{ float: "right" }}>Free</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Taxes</span>
                                <span style={{ float: "right" }}>Inc.</span>
                            </Typography>
                            <Typography variant="body1" component="div" style={{ paddingBottom: "10px" }}>
                                <span style={{ float: "left" }}>Discount</span>
                                <span style={{ float: "right" }}>Rs {props.cart.total_customer_profit != undefined ? props.cart.total_customer_profit : 0}</span>
                            </Typography>
                            <Divider />
                            <Typography variant="subtitl1" component="div" style={{ paddingTop: "10px" }}>
                                <span style={{ float: "left" }}>Total</span>
                                <span style={{ float: "right" }}>Rs {props.cart.total_price != undefined ? props.cart.total_price : 0}</span>
                            </Typography>
                        </Box>
                    </CardContent>
                    {showButton && <CardActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0.5em",
                                textTransform: "none",
                                borderRadius: "10px",
                                paddingLeft: "40px",
                                paddingRight: "40px"
                            }}
                            color="custom"
                            onClick={() => { props.buttonAction(); }}
                        >
                            <Typography
                                variant="button"
                                padding="0.2em"
                                fontSize="1.3em"
                            >
                                {props.buttonText}
                            </Typography>
                        </Button>
                    </CardActions>
                    }
                </React.Fragment>
            </Card>
        </Box>
    );
}
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AboutBrand from './AboutBrand';
import Policy from './Policy';
import SizeChart from './SizeChart';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function isEmpty(obj) {
    if (obj == undefined) {
        return false;
    }
    return Object.keys(obj).length === 0;
}

function GetDefaultValue(info, policy, size_chart) {
    if (!isEmpty(info)) {
        return 0;
    }
    else if (!isEmpty(policy)) {
        return 1;
    }
    else if (!isEmpty(size_chart)) {
        return 2;
    }
    else {
        return -1;
    }
}


export default function Details({ info, policy, size_chart }) {
    const [value, setValue] = React.useState(GetDefaultValue(info, policy, size_chart));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // If both are undefined or empty, we return straight away.
    if (isEmpty(info) && isEmpty(policy) && isEmpty(size_chart)) {
        return (
            <>
            </>
        )
    }

    return (
        <Box sx={{ width: '100%', marginTop: "20px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {!isEmpty(info) && <Tab label="About" style={{
                        color: "black",
                        backgroundColor: "#e1e1e1",
                        boxShadow: "5px 5px 5px #aaaaaa",
                        marginRight: "10px"

                    }} {...a11yProps(0)} />}
                    {!isEmpty(policy) && <Tab label="Policy" style={{
                        color: "black",
                        backgroundColor: "#e1e1e1",
                        boxShadow: "5px 5px 5px #aaaaaa",
                        marginRight: "10px"
                    }} {...a11yProps(1)} />}
                    {!isEmpty(size_chart) && <Tab label="Size Chart" style={{
                        color: "black",
                        backgroundColor: "#e1e1e1",
                        boxShadow: "5px 5px 5px #aaaaaa"
                    }} {...a11yProps(2)} />}
                </Tabs>
            </Box>
            {!isEmpty(info) &&
                <TabPanel value={value} index={0}>
                    <AboutBrand info={info} />
                </TabPanel>
            }
            {!isEmpty(policy) && <TabPanel value={value} index={1}>
                <Policy policy={policy} />
            </TabPanel>}
            {!isEmpty(policy) && <TabPanel value={value} index={2}>
                <SizeChart size_chart={size_chart} />
            </TabPanel>}
        </Box>
    );
}
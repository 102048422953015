import React from "react";
import CardItem from "../CollectionItem/CardItem";

//MUI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const CollectionItemsGrid = ({ collectionsList }) => {
    return (
        <Container component="main" maxWidth="md">
            <Box>
                <Typography variant="h5" textAlign="center">
                    {collectionsList.visible_text}
                </Typography>
                <Grid
                    container
                    spacing={2}
                    columns={12}
                    display="flex"
                    style={{ marginTop: "1em" }}
                >
                    {collectionsList.collections
                        .map((collection, key) => {
                            return (
                                <Grid item xs={12} md={6} key={key}>
                                    <CardItem collection={collection} />
                                </Grid>
                            );
                        })}
                </Grid>
            </Box>
        </Container>
    );
};

export default CollectionItemsGrid;

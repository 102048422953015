import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import FullScreenFeedView from "./FullScreenFeedView";
// import FullScreenFeedViewSwiper from "./FullScreenFeedViewSwiper";
//Components
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import LoadingScreen from "../../Components/Common/LoadingScreen";
import ActionItem from "../../Components/FeedView/Screens/ActionItem";
import Box from "@mui/material/Box";
import PhotoGalleryWithBaner from '../LinkIBio';
import ToggleForTile from './ToggleForTile';
import { useGridMode } from '../../../GridModeContext';

import {
  fetchFeedFeaturesFromProductId,
  fetchFeedFeaturesFromCollectionId,
  postScoreFeedback,
  setCurrentIndexFn,
  cleanUpFeedFn,
  fetchFeedFeaturesFromVideoId,
  fetchProductWishlist,
  fetchVideoWishlist,
  fetchFeedFeaturesForOutfitsFeed
} from '../../../Store/feature_lookup';

import {
  updateSwipeInstructionFn
} from '../../../Store/auth';


import { setDrawerOpenStateFn } from '../../../Store/bottom_drawer';

const NUM_ITEMS_TO_FETCH_IN_API_CALL = 18;
const NUM_ITEMS_TO_FETCH_IN_FIRST_API_CALL = 3;
const NUM_REMAINING_BEFORE_API_CALL = 5;
const no_collection_found_item = {
  "id": "43567gfd546tyfhg6yjhtg2",
  "screen_type": "ACTION",
  "show_interactions": false,
  "message": "Sorry, the collection is empty.",
  "action": ["HOME"],
  "image": "https://images.unsplash.com/photo-1566305977571-5666677c6e98?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=945&q=80",
  "actionText": ["Go To Home Screen"]
};


const FeedView = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const collectionID = searchParams.get("collection");
  const productID = searchParams.get("product");
  const videoID = searchParams.get("video");
  const { gridMode, setGridMode } = useGridMode();

  function fetchItems(num_items) {
    if (productID !== null) {
      props.fetchFeedFeaturesFromProductId(productID, num_items);
    }
    else if (videoID !== null) {
      props.fetchFeedFeaturesFromVideoId(videoID, num_items);
    }
    else if (collectionID !== null) {
      if (collectionID == "saved_products") {
        props.fetchProductWishlist(num_items);
      }
      else if (collectionID == "saved_videos") {
        props.fetchVideoWishlist(num_items);
      }
      else {
        props.fetchFeedFeaturesFromCollectionId(collectionID, num_items);
      }
    }
    else {
      props.fetchFeedFeaturesForOutfitsFeed(num_items);
      // console.log("Undefined.");
    }
  }

  useEffect(() => {
    window.onpopstate = e => {
      // This function is triggered when the back button is clicked.
      // Set the drawer to close, just in case it was open and someone clicked a back button.
      props.setDrawerOpenStateFn(false, "NA");
    };
  });

  useEffect(() => {
    if (props.current_index > 0 && collectionID == props.collection_id) {
      // We already have items of this collection loaded, so we don't do anything.
      ;
    }
    else {
      // Cleanup the older collection before we make a new API call.
      props.cleanUpFeedFn();
      fetchItems(NUM_ITEMS_TO_FETCH_IN_FIRST_API_CALL);
    }

    return () => {
      // console.log("destroyed.");
      // We call cleanupFeed before we fetch the new feed. This enables us to resume from the place where the person left off in case they plan to come back to the same collection.
      // props.cleanUpFeedFn();
    }
  }, []);

  useEffect(() => {
    // Make a call to fetch more items if `num_remaining_before_api_call` items are remanining.
    if (props.current_index >= 0 && props.total_items_fetched >= 0) {
      if (props.total_items_fetched - props.current_index == NUM_REMAINING_BEFORE_API_CALL) {
        fetchItems(NUM_ITEMS_TO_FETCH_IN_API_CALL);
      }
      else if (props.total_items_fetched - props.current_index < NUM_REMAINING_BEFORE_API_CALL) {
        // We still make the call if we haven't received the response yet. Ideally we should keep track of the last sent API call
        // and not send a new one if its not needed.
        fetchItems(NUM_ITEMS_TO_FETCH_IN_API_CALL);
      }
      else {
        ;
      }
    }
  }, [props.current_index]);

  const postFeedback = useCallback((index, feedbackKey, feedbackScore) => {
    props.postScoreFeedback(index, feedbackKey, feedbackScore);
  }, []);


  const setCurrentIndex = useCallback((index) => {
    props.setCurrentIndex(index);
  }, []);

  useEffect(() => {
    if (props.show_swipe_instruction) {
      props.setDrawerOpenStateFn(true, "swipe_instruction");
      const timer = setTimeout(() => {
        props.setDrawerOpenStateFn(false, "NA");
        props.updateSwipeInstructionFn();
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, []);

  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  // Only applicable on desktop.
  const topHeightOffSet = 64;

  // Only applicable on mobile.
  const bottomNavHeight = 52;

  function setScreenDimensions(oldScreeHeight) {
    var h = !isMobile ? parseInt(visualViewport.height - topHeightOffSet) : parseInt(visualViewport.height - bottomNavHeight);

    // This most likely means that address bar changed, hence we resize. 
    // Resize will also be called when keyboard opens, this condition ensures that we dont resize then.

    // We also check screenHeight == 0, since this will be the case upon init.

    // We only allow resize in 2 cases:
    // 1) Init: where oldScreeHeight will be set to 0.
    // 2) Resize of delta < 80, which will be the case when address bar resizes.
    // For all other cases, we dont resize, for example, when the keyboard opens up. 

    if (!(Math.abs(oldScreeHeight - h) < 80) && !(oldScreeHeight == 0)) {
      return;
    }
    if (isMobile) {
      setScreenHeight(h);
      setScreenWidth(visualViewport.width);
    }
    else if (!isMobile) {
      setScreenHeight(h);
      setScreenWidth(Math.min(visualViewport.width, 444));
    }
  }

  useEffect(() => {
    setScreenDimensions(screenHeight);
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenDimensions(screenHeight);
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
    // The handle resize function depends on screenHeight, so we update the listener when screenHeight changes.
  }, [screenHeight]);

  if (gridMode) {
    return (
      <>
        <PhotoGalleryWithBaner
          fetchItems={fetchItems}
          gridMode={gridMode}
          setGridMode={setGridMode}
          topHeightOffSet={!isMobile ? topHeightOffSet : 0}
        />
      </>
    )
  }

  return (
    <>
      {
        props.feed_response.length > 0 &&
        <>
          <FullScreenFeedView
            feed_response={props.feed_response}
            postFeedback={postFeedback}
            current_index={props.current_index}
            setCurrentIndex={setCurrentIndex}
            isRendered={props.isRendered}
            collection_context={props.collection_context}
            setDrawerOpenStateFn={props.setDrawerOpenStateFn}
            screenDimensions={{
              screenWidth: screenWidth,
              screenHeight: screenHeight,
              topHeightOffSet: topHeightOffSet,
              bottomNavHeight: bottomNavHeight
            }
            }
            isAuthenticated={!(props.access_token == null || props.access_token == undefined)}
          />
          <div style={{
            position: "absolute",
            top: !isMobile ? topHeightOffSet + 15 : 15,
            left: 10,
            zIndex: 100
          }}>
            <ToggleForTile
              gridMode={gridMode}
              setGridMode={setGridMode}
            />
          </div>
        </>
      }
      {
        (props.feed_response.length == 0 && !props.end_of_item_reached) &&
        <LoadingScreen text={""} />
      }
      {
        (props.feed_response.length == 0 && props.end_of_item_reached) &&
        <Box
          className="pager-box"
          sx={{
            marginLeft: isMobile ? "0" : parseInt((visualViewport.width - screenWidth) / 2) + "px",
            marginTop: !isMobile ? topHeightOffSet + "px" : 0,
            width: screenWidth,
            height: screenHeight,
          }}
        >
          <ActionItem item={no_collection_found_item} />
        </Box>
      }
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  access_token: state.auth.access_token,
  feed_response: state.feature_lookup.feed_response,
  feed_response_cache: state.feature_lookup.feed_response_cache,
  current_index: state.feature_lookup.current_index,
  collection_id: state.feature_lookup.collection_id,
  isRendered: state.feature_lookup.isRendered,
  total_items_fetched: state.feature_lookup.total_items_fetched,
  collection_context: state.feature_lookup.collection_context,
  show_swipe_instruction: state.auth.show_swipe_instruction,
  end_of_item_reached: state.feature_lookup.end_of_item_reached
});

const mapDispatchToProps = (dispatch) => ({
  fetchFeedFeaturesFromProductId: (product_id, num_to_fetch) => dispatch(fetchFeedFeaturesFromProductId(product_id, num_to_fetch)),
  fetchFeedFeaturesFromCollectionId: (collection_id, num_to_fetch) => dispatch(fetchFeedFeaturesFromCollectionId(collection_id, num_to_fetch)),
  postScoreFeedback: (index, score_key, score_value) => dispatch(postScoreFeedback(index, score_key, score_value)),
  setCurrentIndex: (index) => dispatch(setCurrentIndexFn(index)),
  cleanUpFeedFn: () => dispatch(cleanUpFeedFn()),
  fetchFeedFeaturesFromVideoId: (video_id, num_to_fetch) => dispatch(fetchFeedFeaturesFromVideoId(video_id, num_to_fetch)),
  fetchProductWishlist: (num_to_fetch) => dispatch(fetchProductWishlist(num_to_fetch)),
  fetchVideoWishlist: (num_to_fetch) => dispatch(fetchVideoWishlist(num_to_fetch)),
  setDrawerOpenStateFn: (open, drawerType) => dispatch(setDrawerOpenStateFn(open, drawerType)),
  updateSwipeInstructionFn: () => dispatch(updateSwipeInstructionFn()),
  fetchFeedFeaturesForOutfitsFeed: (num_to_fetch) => dispatch(fetchFeedFeaturesForOutfitsFeed(num_to_fetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedView);
import React from "react";

// Components
import LoadingScreen from "../Common/LoadingScreen";
import Sidebar from "./Sidebar";

// MUI
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// Screens
import CarouselItem from "./Screens/Carousel";
import VideoItem from "./Screens/Video/VideoItem";
import GreetingItem from "./Screens/Greeting";
import FeedbackItem from "./Screens/Feedback";
import ActionItem from "./Screens/ActionItem";
import RatingContext from "./Screens/RatingContext";
import CarouselImagesVideo from './Screens/CarouselImagesVideo';

export function getImagesListFromProductFeatures(product_features) {
  var imageList = [];
  if (product_features.media.base_image != "") {
    imageList.push(product_features.media.base_image);
  }
  imageList = imageList.concat(product_features.media.additional_images);
  return imageList;
}

const Media = ({
  item,
  postFeedback,
  isRendered,
  index,
  isActive,
  screenWidth,
  screenHeight,
  userUnmutedOnce,
  maybeUpdateGlobalUnmute,
  setDrawerOpenStateFn,
  isAuthenticated,
  setTouchGesturesAllowed
}) => {
  if (isRendered == undefined) {
    return <LoadingScreen height={screenHeight} text={"undef:" + index} />;
  }
  return (
    <Container
      style={{
        margin: "0 0",
        padding: "0 0",
        height: screenHeight,
        width: screenWidth,
        position: "relative",
      }}
    >
      {isRendered === false && (
        <div style={{ height: "100%", width: "100%" }}></div>
      )}
      {isRendered === true && item.screen_type === "PROMO_VIDEO" && item.video.carousel_images && item.video.carousel_images.length > 0 && (
        <CarouselImagesVideo
          imageList={item.video.carousel_images}
          index={index}
          postFeedbackFn={postFeedback}
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          setDrawerOpenStateFn={setDrawerOpenStateFn}
          drawer_trigger_text={item.video.drawer_trigger_text}
          has_items={item.video.products_info.length > 0}
          product_info={item.video.products_info}
          product_info_list={item.video.products_list ? item.video.products_list : []}
          setTouchGesturesAllowed={setTouchGesturesAllowed}
          carouselTitle={item.title ? item.title : ""}
        ></CarouselImagesVideo>
      )
      }
      {
        isRendered === true && item.screen_type === "PROMO_VIDEO" && (!item.video.carousel_images || item.video.carousel_images.length == 0) && (
          <VideoItem
            src={item.video.url}
            poster_src={item.video.first_frame_image_url}
            drawer_trigger_text={item.video.drawer_trigger_text}
            has_items={item.video.products_info.length > 0}
            product_info={item.video.products_info}
            index={index}
            setDrawerOpenStateFn={setDrawerOpenStateFn}
            postFeedbackFn={postFeedback}
            should_play={isActive}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            userUnmutedOnce={userUnmutedOnce}
            maybeUpdateGlobalUnmute={maybeUpdateGlobalUnmute}
          />
        )
      }
      {
        isRendered === true && item.screen_type === "PRODUCT" && (
          <CarouselItem
            imageList={getImagesListFromProductFeatures(item.product)}
            index={index}
            setDrawerOpenStateFn={setDrawerOpenStateFn}
            product_features={item.product}
            ratings={
              item.product_ratings != undefined ? item.product_ratings : {}
            }
            postFeedbackFn={postFeedback}
            score_annotations={item.score_annotations}
            screenWidth={screenWidth}
            screenHeight={screenHeight}
          />
        )
      }
      {
        isRendered === true && item.screen_type === "GREET" && (
          <GreetingItem image={item.image} message={item.message} />
        )
      }
      {
        isRendered === true && item.screen_type === "ACTION" && (
          <ActionItem item={item} />
        )
      }
      {
        isRendered === true && item.screen_type === "FEEDBACK" && (
          <FeedbackItem item={item} />
        )
      }
      {
        isRendered === true && item.screen_type === "RATING_WITH_CONTEXT" && (
          <RatingContext
            rating_context={item.rating_with_context.rating_context}
            setDrawerOpenStateFn={setDrawerOpenStateFn}
          />
        )
      }
      {
        isRendered === true && item.show_interactions === true && (
          <Box>
            <Sidebar
              item={item}
              screen_type={item.screen_type}
              item_id={item.id != undefined ? item.id : ""}
              item_name={item.product != undefined ? item.product.name[0] : ""}
              ratings={
                item.product_ratings != undefined ? item.product_ratings : {}
              }
              postFeedbackFn={postFeedback}
              item_index={index}
              setDrawerOpenStateFn={setDrawerOpenStateFn}
              isAuthenticated={isAuthenticated}
              showBrandStory={item.product != undefined && item.product.brands_info != undefined && item.product.brands_info.info != undefined && item.product.brands_info.info.story.length > 0}
            />
          </Box>
        )
      }
    </Container >
  );
};

export const MediaItem = React.memo(Media);

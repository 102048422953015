import React from "react";
import { useEffect, useState, useCallback, useRef, createContext, useContext } from "react";
import Gallery from "react-photo-gallery";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import LandingPageBanner from "../../Components/Dashboard/LandingPageBanner";
import LandingPageMessage from "../../Components/Dashboard/LandingPageMessage";
import { isMobile } from 'react-device-detect';
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ToggleForTile from '../Feed/ToggleForTile';
import { setCurrentIndexFn, postScoreFeedback } from '../../../Store/feature_lookup';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FloatingButton from '../InstaLikeFeed/FollowUsButton';


const BASE_DASH_CONNFIG = {
    "media_desktop": "https://images.unsplash.com/photo-1490481651871-ab68de25d43d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80",
    "type_media_desktop": "IMAGE",
    "media_mobile": "https://images.unsplash.com/photo-1603400521630-9f2de124b33b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    "type_media_mobile": "IMAGE",
    "messages": ["YOUR NEW STYLE IS READY"]
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RenderedImageContext = createContext({
    handleImageClick: (event, { photo, index }) => { }
});

const CustomImageComponent = (props) => {
    const { photo, index } = props;
    const { handleImageClick } = useContext(RenderedImageContext);
    const localRef = useRef(null); // Define a local ref
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    useEffect(() => {
        if (!props.hasScrolled && props.index === props.current_index && localRef.current) {
            localRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            });
            props.setHasScrolled(true);
        }
    }, [props.index, props.current_index, localRef, props.hasScrolled, props.setHasScrolled]);

    return (
        <div style={{ position: "relative", cursor: "pointer" }}>
            <img
                ref={localRef}
                src={photo.src}
                alt={photo.title}
                width={isMobile ? String(photo.desired_width) + "px" : String(photo.width) + "px"}
                height={isMobile ? String(photo.desired_height) + "px" : String(photo.height) + "px"}
                onClick={(event) => handleImageClick(event, { photo, index })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                    filter: hovered ? "brightness(0.8)" : "brightness(1)", // Apply hover effect
                }}
            />
            {photo.title != "" &&
                <div
                    style={{
                        position: "absolute",
                        bottom: 5,
                        left: "10%",
                        right: "10%",
                        width: "80%",
                        background: "rgba(0, 0, 0, 0.3)",
                        color: "#fff",
                        padding: isMobile ? "1px" : "5px",
                        textAlign: "center",
                        fontSize: isMobile ? "10px" : "15px",
                        whiteSpace: "nowrap", // Add this line to prevent text from wrapping
                        overflow: "hidden", // Add this line to hide any overflowed text
                        textOverflow: "ellipsis", // Add this line to show an ellipsis (...) when text is truncated
                    }}
                >
                    {photo.title}
                </div>
            }
        </div >
    );
};

const Outfits = (props) => {
    const [gridPhotos, setGridPhotos] = useState(new Array());
    const [hasScrolled, setHasScrolled] = useState(false);
    let current_index_for_slides = props.current_index;

    const handleImageClick = useCallback((event, { photo, index }) => {
        props.setCurrentIndex(index);
        props.setGridMode(false);
        props.postScoreFeedbackFn(index, "grid_click", 1.0);
    }, [props.feed_response, props.feed_response_cache]);

    useEffect(() => {
        var local_arr = [];
        for (var index = 0; index < props.feed_response.length; index++) {
            if (props.feed_response[index].screen_type == 'PRODUCT') {
                // width: 300,
                // height: 400,
                local_arr.push({
                    src: props.feed_response[index].product.media.base_image,
                    desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                    desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                    width: 300,
                    height: 300,
                    title: "",
                    index: index
                });
            }
            else if (props.feed_response[index].screen_type == 'PROMO_VIDEO') {
                if (props.feed_response[index].video.carousel_images && props.feed_response[index].video.carousel_images.length > 0) {
                    // width: parseInt(props.feed_response[index].video.carousel_images[0]['image_width']),
                    // height: parseInt(props.feed_response[index].video.carousel_images[0]['image_height']),
                    local_arr.push({
                        src: props.feed_response[index].video.carousel_images[0]['image_url'],
                        desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                        desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                        width: 300,
                        height: 300,
                        title: props.feed_response[index].title ? props.feed_response[index].title : "",
                        index: index
                    });
                }
                else {
                    // width: 900,
                    // height: 1600,
                    if (props.feed_response[index].video.first_frame_image_url != '') {
                        local_arr.push({
                            src: props.feed_response[index].video.first_frame_image_url,
                            desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            width: 300,
                            height: 300,
                            title: "",
                            index: index
                        });
                    }
                    else {
                        // width: 900,
                        // height: 1600,
                        local_arr.push({
                            src: 'https://images.unsplash.com/photo-1600172454520-134a542a2255?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80',
                            desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            width: 300,
                            height: 300,
                            title: "",
                            index: index
                        });
                    }
                }
            }
        }
        for (var index = 0; index < props.feed_response_cache.length; index++) {
            if (props.feed_response_cache[index].screen_type == 'PRODUCT') {
                // width: 300,
                // height: 400,
                local_arr.push({
                    src: props.feed_response_cache[index].product.media.base_image,
                    desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                    desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                    width: 300,
                    height: 300,
                    title: "",
                    index: props.feed_response.length + index
                });
            }
            if (props.feed_response_cache[index].screen_type == 'PROMO_VIDEO') {
                if (props.feed_response_cache[index].video.carousel_images && props.feed_response_cache[index].video.carousel_images.length > 0) {
                    // width: parseInt(props.feed_response_cache[index].video.carousel_images[0]['image_width']),
                    // height: parseInt(props.feed_response_cache[index].video.carousel_images[0]['image_height']),
                    local_arr.push({
                        src: props.feed_response_cache[index].video.carousel_images[0]['image_url'],
                        desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                        desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                        width: 300,
                        height: 300,
                        title: "",
                        index: props.feed_response.length + index
                    });
                }
                else {
                    if (props.feed_response_cache[index].video.first_frame_image_url != '') {
                        // width: 300,
                        // height: 400,
                        local_arr.push({
                            src: props.feed_response_cache[index].video.first_frame_image_url,
                            desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            width: 300,
                            height: 300,
                            title: "",
                            index: props.feed_response.length + index
                        });
                    }
                    else {
                        // width: 300,
                        // height: 400,
                        local_arr.push({
                            src: 'https://images.unsplash.com/photo-1600172454520-134a542a2255?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80',
                            desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                            width: 300,
                            height: 300,
                            title: "",
                            index: props.feed_response.length + index
                        });
                    }
                }
            }
        }
        setGridPhotos(local_arr);
    }, [props.feed_response, props.feed_response_cache]);

    const contextValue = {
        handleImageClick
    };

    const innerContainerStyle = {
        padding: 0, // Remove padding
        margin: 0, // Remove margin
    };
    return (
        <Container component="main" maxWidth="md" style={isMobile ? innerContainerStyle : null}>
            <RenderedImageContext.Provider value={contextValue}>
                <Gallery
                    photos={gridPhotos}
                    targetRowHeight={isMobile ? 100 : 300}
                    renderImage={(props) => <CustomImageComponent
                        {...props}
                        index={props.photo.index}
                        current_index={current_index_for_slides}
                        hasScrolled={hasScrolled}
                        setHasScrolled={setHasScrolled}
                    />
                    }
                />
            </RenderedImageContext.Provider>
        </Container>
    );
};

function GetBannerMediaType(isMobile, metadata) {
    try {
        var x = metadata.media.representative_image;
        return "IMAGE";
    }
    catch (err) {
        return isMobile ? BASE_DASH_CONNFIG["type_media_mobile"] : BASE_DASH_CONNFIG["type_media_desktop"];
    }
}

function GetBannerMediaSrc(isMobile, metadata) {
    try {
        if (metadata.media.representative_image == "") {
            return isMobile ? BASE_DASH_CONNFIG["media_mobile"] : BASE_DASH_CONNFIG["media_desktop"]
        }
        return metadata.media.representative_image;
    }
    catch (err) {
        return isMobile ? BASE_DASH_CONNFIG["media_mobile"] : BASE_DASH_CONNFIG["media_desktop"]
    }


}
function GetBannerMessageList(isMobile, metadata) {
    return BASE_DASH_CONNFIG["messages"];
    // try {
    //     if (metadata && metadata.title && metadata.subtitle) {
    //         var heading = metadata.title + ":" + metadata.subtitle;
    //         return [heading];
    //     }
    //     else {
    //         return [''];
    //     }
    // }
    // catch (err) {
    //     return BASE_DASH_CONNFIG["messages"];
    // }
}

function PhotoGalleryWithBaner(props) {

    const containerStyle = {
        marginTop: '2em',
        padding: 0, // Remove padding
        margin: 0, // Remove margin
        width: '100%', // Cover entire width
    };

    return (
        <Container
            style={{
                minWidth: "100%",
                minHeight: "100vh",
                padding: 0
            }}
        >
            <Box
                style={{
                    marginTop: !isMobile ? "4em" : 0,
                    height: "25vh"
                }}
            >
                {
                    <LandingPageBanner
                        mediaType={GetBannerMediaType(isMobile, props.collection_metadata)}
                        mediaSrc={GetBannerMediaSrc(isMobile, props.collection_metadata)}
                    />
                }
                {
                    <LandingPageMessage
                        messageList={GetBannerMessageList(isMobile, props.collection_metadata)}
                        isMobile={isMobile}
                    />
                }
                {!isMobile && <div style={{
                    position: "absolute",
                    top: props.topHeightOffSet + 15,
                    left: 10,
                    zIndex: 100
                }}>
                    <ToggleForTile gridMode={props.gridMode} setGridMode={props.setGridMode} />
                </div>}
            </Box>
            <Box
                style={{
                    width: "100%",
                    minHeight: "60vh",
                    position: "absolute",
                    paddingBottom: isMobile ? 56 : 0
                }}
            >
                <Container maxWidth="lg" style={isMobile ? containerStyle : null} >
                    <Outfits
                        feed_response={props.feed_response}
                        feed_response_cache={props.feed_response_cache}
                        current_index={props.current_index}
                        setCurrentIndex={props.setCurrentIndex}
                        setGridMode={props.setGridMode}
                        postScoreFeedbackFn={props.postScoreFeedbackFn}
                    />
                    {
                        (props.feed_response.length == 0 && !props.end_of_item_reached) &&
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            style={{
                                width: "100%",
                            }}
                        >
                            <CircularProgress sx={{ margin: "0 auto", color: "black" }} />
                            {/* <p style={{ color: "white" }}> {text}</p> */}
                        </Grid>
                    }
                    {props.feed_response.length > 0 && !props.end_of_item_reached && <Box
                        style={{
                            padding: "3%",
                            color: "white",
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        color="primary.contrastText"
                    >
                        <Button
                            color="custom"
                            style={{
                                borderRadius: "2em",
                            }}
                            variant="contained"
                            disabled={false}
                            onClick={(e) => props.fetchItems(20)}
                        >
                            <Typography variant="h6">Load More</Typography>
                        </Button>
                    </Box>
                    }
                </Container>
            </Box>
            <FloatingButton />
        </Container >
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    feed_response: state.feature_lookup.feed_response,
    feed_response_cache: state.feature_lookup.feed_response_cache,
    end_of_item_reached: state.feature_lookup.end_of_item_reached,
    collection_metadata: state.feature_lookup.metadata,
    current_index: state.feature_lookup.current_index,
    collection_id: state.feature_lookup.collection_id,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentIndex: (index) => dispatch(setCurrentIndexFn(index)),
    postScoreFeedbackFn: (index, score_key, score_value) => dispatch(postScoreFeedback(index, score_key, score_value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryWithBaner);
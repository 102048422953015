import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// MUI
import Box from "@mui/material/Box";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

export default function ShopItemButtonTransparent({
    clickFn,
    upperText,
    middleText,
    lowerText,
    leftImage,
    rightCircleText,
    rightCircleClickFn,
    data_tutorial_label
}) {
    return (
        <Button
            variant="contained"
            onClick={clickFn}
            data-tut={data_tutorial_label}
            style={{
                marginTop: "10px",
                borderRadius: "0em 2em 2em 0em",
                textTransform: "none",
                width: "100%",
                color: "#F1F3CE",
                backgroundColor: "rgba(50, 50, 50, 0.50)",
                height: "60px",
                paddingLeft: "10px",
                paddingRight: "5px",
            }}
        >
            {leftImage && <div
                style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    backgroundImage: "url(" + leftImage + ")",
                    backgroundPosition: "center",
                }}
            ></div>
            }
            <div
                style={{ flexGrow: 1, width: "10px", height: "100%", color: "white" }}
            >
                <div
                    style={{
                        marginLeft: "10px",
                        height: "25%",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "white",
                    }}
                >
                    {upperText}
                </div>
                <div
                    style={{
                        marginLeft: "10px",
                        height: "45%",
                        overflow: "hidden",
                        fontSize: "15px",
                        color: "white",
                    }}
                >
                    {middleText}
                </div>
                <div
                    style={{
                        marginLeft: "10px",
                        height: "30%",
                        fontSize: "10px",
                        overflow: "hidden",
                        color: "white",
                    }}
                >
                    {lowerText}
                </div>
            </div>
            <Box
                sx={{
                    justifyContent: "right",
                }}
                display="flex"
            >
                <div
                    style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                    }}
                    onClick={rightCircleClickFn}
                >
                    <Typography
                        align="center"
                        style={{ fontSize: "13px", marginTop: "16px" }}
                    >
                        {rightCircleText}
                    </Typography>
                </div>
            </Box>
        </Button>
    );
}

function ShopItemButton({ url, mrp, discounted_price, brand }) {
    return (
        <Button
            variant="contained"
            href={url}
            target="_blank"
            style={{
                marginTop: "10px",
                borderRadius: "2em",
                textTransform: "none",
                width: "80%",
                color: "#F1F3CE",
                backgroundColor: "#F94144",
                height: "40px",
                padding: "10px",
            }}
        >
            <ShoppingBagIcon />
            <Typography variant="caption">view at&nbsp;</Typography>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {brand}
            </Typography>
            <Box
                sx={{
                    justifyContent: "right",
                }}
                display="flex"
            >
                {mrp == discounted_price || mrp === 0 || (
                    <Typography
                        variant="caption"
                        style={{ textDecoration: "line-through" }}
                        gutterBottom
                    >
                        ₹{mrp}
                    </Typography>
                )}
                <Typography variant="body1">&nbsp;₹{discounted_price}&nbsp;</Typography>
            </Box>
        </Button>
    );
}
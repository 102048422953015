import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemsInCartTitle from './ItemsInCartTitle';
import ProductCard from './ProductCard';
import Box from "@mui/material/Box";

const CartItems = ({ isOrder = false, ...props }) => {
    const navigate = useNavigate();
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: "10px", paddingBottom: "10px" }}>
                <ItemsInCartTitle numItemsCart={props.item_count} isOrder={isOrder} />
                {props.items != undefined ?
                    props.items.map((cart_item, key) => {
                        return (
                            <ProductCard
                                product={cart_item.product}
                                quantity={cart_item.quantity}
                                variant={cart_item.variant}
                                isOrder={isOrder}
                                updateQuantity={(quantity) => {
                                    props.updateCartItemQuantity(cart_item.product.id, cart_item.variant.id, quantity);
                                }}
                                removeItem={() => { props.removeCartItem(cart_item.id) }}
                                key={key} />
                        );
                    })
                    :
                    <></>
                }
            </Box>
        </>
    );
};

export default CartItems;

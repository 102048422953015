import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";


const Profile = (props) => {
  return (
    <>
      <Box
        style={{
          height: "100vh",
          overflow: "hidden"
        }}
      >
        <Box
          className="outer-box"
          style={{
            minWidth: "100%",
            minHeight: "100%",
            position: "relative",
            marginTop: "12vh",
          }}
        >
          <Container
            className="inner-container"
            component="main"
            maxWidth="sm"
            style={{
              paddingTop: "10px",
            }}
          >
            <Typography variant='h6' fontSize="1.2em" style={{ marginBottom: "20px", textAlign: "center" }}>
              {props.first_name == "" ? "Hey there, Welcome to Zive." : "Hey " + props.first_name + ", Welcome to Zive."}
            </Typography>
            <Divider sx={{ bgcolor: "primary.contrastText" }} />
            {props.access_token != undefined && <AccountInfoOption />}
            <Divider sx={{ bgcolor: "primary.contrastText" }} />
            {/* {props.access_token != undefined && <>
              <MyOrders />
              <Divider sx={{ bgcolor: "primary.contrastText" }} />
            </>
            } */}
            {/* {props.access_token != undefined && <>
              <SavedProductsOption />
              <Divider sx={{ bgcolor: "primary.contrastText" }} />
            </>
            } */}
            {props.access_token != undefined && <>
              <SavedVideosOption />
              <Divider sx={{ bgcolor: "primary.contrastText" }} />
            </>
            }
            <AuthOption access_token={props.access_token} />
            <Divider sx={{ bgcolor: "primary.contrastText" }} />
          </Container>
        </Box>
      </Box>
    </>
  );
};

const AuthOption = (props) => {
  const navigate = useNavigate();
  const authRespond = () => {
    if (props.access_token != undefined) {
      navigate("/logout");
    }
    else {
      navigate("/authentication");
    }
  };
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        onClick={authRespond}
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">{props.access_token != undefined ? "Log Out" : "Sign In"}</Typography>
      </Button>
    </>
  );
};

const AccountInfoOption = () => {
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        href="/account"
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">
          Account Info
        </Typography>
      </Button>
    </>
  );
};

const SavedVideosOption = () => {
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        href="/feed?collection=saved_videos"
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">
          Saved Boards
        </Typography>
      </Button>
    </>
  );
};

const SavedProductsOption = () => {
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        href="/feed?collection=saved_products"
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">
          Saved Products
        </Typography>
      </Button>
    </>
  );
};

const MyOrders = () => {
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        href="/orders"
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">
          My Orders
        </Typography>
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  access_token: state.auth.access_token,
  first_name: state.auth.first_name
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

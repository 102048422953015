import React, { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

function DisplayBrandLines(props) {
    return (
        <>
            {
                props.story.map((storyline, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Typography variant="body1" style={{ fontSize: "1.2em" }}>
                                {storyline}
                            </Typography>
                            <br />
                        </React.Fragment>
                    )
                })
            }
        </>
    );
}

function BrandStory(props) {
    return (
        <>
            <Box style={{ paddingLeft: 16, paddingRight: 16, marginBottom: "4.0em" }}>
                {
                    props.feed_response[props.current_index] != undefined && props.feed_response[props.current_index].product != undefined && props.feed_response[props.current_index].product.brands_info != undefined && props.feed_response[props.current_index].product.brands_info.info != undefined &&
                    <>
                        <Typography variant="h5" style={{ marginTop: "1em", marginBottom: "1em" }}>
                            About {props.feed_response[props.current_index].product.brands_info.info.brand_name}
                        </Typography>
                        <DisplayBrandLines story={props.feed_response[props.current_index].product.brands_info.info.story} />
                    </>

                }
            </Box>
        </>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    feed_response: state.feature_lookup.feed_response,
    current_index: state.feature_lookup.current_index
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandStory);
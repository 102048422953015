import { configureStore } from "@reduxjs/toolkit";
import toast from "./middleware/toast";
import api from "./middleware/api";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers } from "redux";
import quizQuestionsReducer from "./quiz_questions";
import featureLookupReducer from "./feature_lookup";
import fetchCollectionList from './fetch_collection_list';
import cartActionsReducer from './cart_actions';
import orderActionsReducer from './order_actions';
import fetchInstaOutfitsFeedReducer from './fetch_insta_outfits_feed'

import authReducer from './auth';

import bottomDrawerReducer from './bottom_drawer';

// We need to add auth to blacklist, since certain entries of it are blacklisted below.
const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth', 'quiz_questions', 'feature_lookup', 'fetch_collection_list', 'bottom_drawer', 'cart_actions', 'order_actions', 'fetch_insta_outfits_feed']
}

// Lets add name_updated, password_updated and otp_verified to blacklist, since 
// we only use then for that session for redirecting to relevant views.
const authPersistConfig = {
    key: 'auth',
    storage,
    blacklist: ['name_updated', 'password_updated', 'otp_verified']
}

const reducer = persistReducer(rootPersistConfig, combineReducers({
    quiz_questions: quizQuestionsReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    feature_lookup: featureLookupReducer,
    fetch_collection_list: fetchCollectionList,
    bottom_drawer: bottomDrawerReducer,
    cart_actions: cartActionsReducer,
    order_actions: orderActionsReducer,
    fetch_insta_outfits_feed: fetchInstaOutfitsFeedReducer
}));

export const reduxStore = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
        var default_middleware = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ['feature_lookup.feed_response']
            },
            immutableCheck: {
                // Ignore state paths which are large.
                ignoredPaths: ['feature_lookup.feed_response']
            }
        });
        // default_middleware.push(logger({ destination: "console" }));
        default_middleware.push(toast);
        default_middleware.push(api);
        return default_middleware;
    },
});

export const reduxPersistStore = persistStore(reduxStore);
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";


export default function AddressCard({ address, isSelected, index, onSelected }) {
    const navigate = useNavigate();
    const addressEditClicked = (e) => {
        // navigate(`/collection?id=${collectionsList._id}`);
        console.log("Address edit clicked.", address);
        e.stopPropagation();
        navigate(`/address?id=${address.address_id}`);
    };

    return (
        <Box sx={{
            maxWidth: "100%",
            border: "none",
            boxShadow: "none",
            position: "relative",
            borderRadius: "1em",
            bgcolor: "rgba(0, 0, 0, 0)",
            cursor: "pointer",
        }}>
            <Card variant="outlined" sx={{ borderColor: isSelected ? "success.main" : null, borderWidth: "5px", height: "150px", backgroundColor: "secondary.main" }} onClick={() => { onSelected(index) }}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" component="div">
                            {/* <span style={{ float: "left" }}>Default</span> */}
                            <span style={{ float: "right" }}>
                                <IconButton aria-label="edit" onClick={addressEditClicked} style={{ height: 20, width: 20 }} sx={{ color: "primary.contrastText" }}>
                                    <BorderColorIcon style={{ transform: "scale(0.7)" }} />
                                </IconButton>
                            </span>
                        </Typography>
                        <Typography variant="body2" component="div" fontSize={"0.8em"}>
                            {address.address_line1}, <br />
                            {address.address_line2}, <br />
                            {address.city}, {address.state}, <br />
                            {address.country} - {address.postal_code}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
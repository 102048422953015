import React, { useEffect, useState } from "react";
// Components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import { Pagination, Lazy } from "swiper";
import Box from "@mui/material/Box";
import { getMobileOperatingSystem } from '../../../../../Hooks/getOS'
import ShopItemButtonTransparent from '../Common/ShopActionButton';
import Modal from '@mui/material/Modal';
import ProductTiles from './ProductTiles';
import { Typography } from '@mui/material'

// import required modules.
import "./CarouselStyle.css";

import ProductCarousel from "../Video/ProductCarousel";


const DefaultAspectRatio = 1.3;

function CarouselSlideImage({ img }) {
    return (
        <img
            effect="blur"
            onError={(event) => (event.target.style.display = "none")}
            onLoad={(event) =>
                (event.target.style.display = "inline-block")
            }
            className="swiper-lazy"
            data-src={img}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
    );
}


function GetEffectiveWidthAndHeight(image_width, image_height, screenWidth, screenHeight) {
    var scaleFactorX = screenWidth / image_width;
    var scaleFactorY = screenHeight / image_height;
    var effective_box_width_1 = scaleFactorX * image_width;
    var effective_box_height_1 = scaleFactorX * image_height;
    var effective_box_width_2 = scaleFactorY * image_width;
    var effective_box_height_2 = scaleFactorY * image_height;
    var effective_box_width = -1.0;
    var effective_box_height = -1.0;
    if (effective_box_width_1 <= screenWidth && effective_box_height_1 <= screenHeight) {
        effective_box_width = effective_box_width_1;
        effective_box_height = effective_box_height_1;
    }
    else if (effective_box_width_2 <= screenWidth && effective_box_height_2 <= screenHeight) {
        effective_box_width = effective_box_width_2;
        effective_box_height = effective_box_height_2;
    }
    else {
        var upDownMargin_local = Math.max(50, parseInt((screenHeight - screenWidth * DefaultAspectRatio) / 2));
        effective_box_width = screenWidth;
        effective_box_height = screenHeight - 2 * upDownMargin_local;
    }
    return [effective_box_width, effective_box_height];

}
function CarouselImagesVideo({
    imageList,
    index,
    postFeedbackFn,
    screenWidth,
    screenHeight,
    drawer_trigger_text,
    has_items,
    product_info,
    product_info_list,
    setDrawerOpenStateFn,
    setTouchGesturesAllowed,
    carouselTitle
}) {
    const operation_system = getMobileOperatingSystem();
    var [effective_box_width, effective_box_height] = GetEffectiveWidthAndHeight(
        imageList[0]['image_width'],
        imageList[0]['image_height'],
        screenWidth,
        screenHeight
    );
    const [currentIndex, setCurrentIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
        setTouchGesturesAllowed(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setTouchGesturesAllowed(true);
    };

    var upDownMargin = (screenHeight - effective_box_height) / 2;
    var leftMargin = (screenWidth - effective_box_width) / 2;

    function handleSlideChanged() {
        if (swiper.current) {
            postFeedbackFn(index, "video_carousel_image_swipe" + swiper.current.activeIndex.toString(), 1.0);
            setCurrentIndex(swiper.current.activeIndex);
        }
        else {
            postFeedbackFn(index, "video_carousel_image_swipe", 1.0);
        }
    }

    const swiper = React.useRef(null);
    const setSwiper = (newSwiper) => {
        swiper.current = newSwiper;
    };

    return (
        <>
            <Box
                style={{
                    maxWidth: window.innerWidth,
                    height: "inherit",
                    position: "relative",
                }}
                sx={{ backgroundColor: "secondary.main" }}
            >
                {/* <Box height={upDownMargin} className="top-margin-carousel" /> */}
                <Box
                    height={upDownMargin}
                    className="top-margin-carousel"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    alignItems="center"
                    paddingBottom={2}  // assuming Material-UI's spacing system is in use
                >
                    {carouselTitle !== '' &&
                        <Typography
                            variant="h6"
                            style={{ fontStyle: 'italic', color: '#555' }}
                        >
                            {carouselTitle}
                        </Typography>}
                </Box>
                <Box
                    style={{
                        margin: 0,
                        height: effective_box_height,
                        width: effective_box_width,
                        marginLeft: leftMargin,
                    }}
                >
                    <Swiper
                        onTransitionEnd={handleSlideChanged}
                        pagination={true}
                        loop={false}
                        preloadImages={false}
                        modules={[Pagination, Lazy]}
                        lazy={{
                            loadPrevNext: false,
                            loadOnTransitionStart: true,
                        }}
                        onSwiper={setSwiper}
                    >
                        {/* Display all unique image paths. Since we apply lazy loading, we don't apply upper bound on how many images to pre-load. */}
                        {/* [...new Set(imageList)] */}
                        {imageList.map((img, key) => {
                            return (
                                <SwiperSlide
                                    style={{
                                        width: effective_box_width,
                                        height: effective_box_height,
                                        backgroundImage: `url(${process.env.PUBLIC_URL}loading.gif)`,
                                        backgroundPosition: "center",
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                    }}
                                    key={key}
                                >
                                    <CarouselSlideImage
                                        img={img['image_url']}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </Box>
            </Box>
            <Modal
                open={openModal}
                hideBackdrop={false}
                onClose={handleCloseModal}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: parseInt(screenWidth * 80 / 100),
                        maxHeight: parseInt(screenHeight * 70 / 100),
                        backgroundColor: "white",
                        border: '0px solid #000',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.6)', // subtle shadow
                        padding: 4,
                        zIndex: 1300,
                        borderRadius: '8px',
                        overflow: "auto"
                    }}
                >
                    <ProductTiles
                        currentImageIndex={product_info_list.length > 0 ? currentIndex : ''}
                        slide_index={index}
                        product_info={product_info}
                        product_info_list={product_info_list}
                        postFeedbackFn={postFeedbackFn}
                        tileItemWidth={parseInt((screenWidth * 80 / 100) / 2 - 10)}
                    />
                </Box>
            </Modal>
            <div
                style={{
                    position: "absolute",
                    width: "75%",
                    bottom: 50,
                    zIndex: 1,
                    left: 0,
                }}
            >
                {/* */}
                {
                    (has_items || product_info_list.length > 0) && drawer_trigger_text != '' && operation_system == 'iOS' &&
                    <ShopItemButtonTransparent
                        clickFn={() => {
                            // setDrawerOpenStateFn(true, "video_product_list");
                            postFeedbackFn(index, 'video_product_drawer', 1.0);
                            handleOpenModal()
                        }}
                        upperText={""}
                        middleText={drawer_trigger_text}
                        lowerText={""}
                        leftImage={""}
                        rightCircleClickFn={() => { }}
                        rightCircleText={"View"}
                        data_tutorial_label={"video_explore"}
                    ></ShopItemButtonTransparent>
                }
                {
                    (has_items || product_info_list.length > 0) && operation_system != 'iOS' &&
                    <div style={{ paddingLeft: "20px" }}>
                        <ProductCarousel
                            currentImageIndex={product_info_list.length > 0 ? currentIndex : ''}
                            slide_index={index}
                            product_info={product_info}
                            product_info_list={product_info_list}
                            postFeedbackFn={postFeedbackFn}
                        />
                    </div>
                }
            </div>
        </>
    );
}
export default CarouselImagesVideo;
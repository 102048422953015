import { useState, useEffect } from "react";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from "react-redux";

import { fetchAllOrders } from '../../../Store/order_actions';

function processDate(date_str) {
    return date_str.split("T")[0];
}

function Orders(props) {
    useEffect(() => {
        props.fetchAllOrders();
    }, []);

    return (
        <TableContainer component={Paper} style={{ marginTop: 80, marginBottom: 80 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Count</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.orders.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <a href={"/order_details/" + row.id}> {row.code} </a>
                            </TableCell>
                            <TableCell align="right">{row.total_price}</TableCell>
                            <TableCell align="right">{row.items_count}</TableCell>
                            <TableCell align="right">{row.shipping_status}</TableCell>
                            <TableCell align="right">{processDate(row.created_at)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    access_token: state.auth.access_token,
    loading: state.order_actions.loading,
    orders: state.order_actions.orders,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAllOrders: () => dispatch(fetchAllOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
import React from "react";
import { isMobile } from 'react-device-detect';

const mediaStyle = {
  objectFit: "cover",
  width: "100%",
  height: "100% ",
};


const LandingPageBanner = ({ mediaType, mediaSrc }) => {
  return (
    <>
      {
        mediaType == "VIDEO" ?
          <video
            style={mediaStyle}
            loop
            autoPlay
            muted
            src={mediaSrc}
            playsInline
          /> :
          <img
            src={mediaSrc}
            style={mediaStyle}
          />
      }
    </>
  );
};

export default LandingPageBanner;

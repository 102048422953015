import { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

function ItemsInCartTitle({ numItemsCart, isOrder }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: "10px", paddingBottom: "10px" }}>
            <Typography variant="subtitle2" component="div">
                {
                    isOrder ?
                        <span style={{ float: "left" }}>{numItemsCart} Items in your order</span> :
                        <span style={{ float: "left" }}>{numItemsCart} Items in your cart</span>
                }
                <span style={{ float: "right" }}>
                    {/* <Button
                        variant="contained"
                        style={{
                            textTransform: "none",
                            borderRadius: "10px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            fontSize: "0.8em"
                        }}
                        onClick={() => { console.log("clicked") }}
                    >
                        <Typography variant="subtitle2">
                            Clear Cart
                        </Typography>

                    </Button> */}
                </span>
            </Typography>
        </Box>
    );
}
export default ItemsInCartTitle;

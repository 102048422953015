import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { updateFirstAndLastName } from "../../../Store/auth";
import { connect } from "react-redux";


import {
  useNavigate,
} from "react-router-dom";

const PrimaryInfo = (props) => {
  const navigate = useNavigate();
  const [username, setUserName] = useState({
    first_name: props.first_name,
    last_name: props.last_name,
  });

  const handleChange = (e) => {
    setUserName({
      ...username,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    props.updateFirstAndLastName({ 'first_name': username.first_name, 'last_name': username.last_name });
    if (props.navigatePreviousOnSubmit != undefined && props.navigatePreviousOnSubmit) {
      navigate(-1);
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-firstname" sx={{ color: "primary.contrastText" }}>
              First Name
            </InputLabel>
            <OutlinedInput
              name="first_name"
              disabled={false}
              value={username.first_name}
              onChange={handleChange}
              label="First Name"
              sx={{
                backgroundColor: "secondary.main",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-lastname" sx={{ color: "primary.contrastText" }}>
              Last Name
            </InputLabel>
            <OutlinedInput
              name="last_name"
              disabled={false}
              value={username.last_name}
              onChange={handleChange}
              label="Last Name"
              sx={{
                backgroundColor: "secondary.main",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
              }}
            />
          </FormControl>
        </Grid>
        {
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              variant="contained"
              style={{
                borderRadius: "2em",
              }}
              color="custom"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        }
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => (
  {
    ...ownProps,
    phone_number: state.auth.phone_number,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
  }
);

const mapDispatchToProps = (dispatch) => ({
  updateFirstAndLastName: (formValues) => dispatch(updateFirstAndLastName(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryInfo);
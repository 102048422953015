import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function SizeDropDown({ selectedValue, availableValue, setSelectedValue, label }) {

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" component="div" fontSize={"0.6em"} style={{ marginLeft: "10px" }}>
                {label}
            </Typography>
            <Box sx={{ display: 'flex', pl: 1, pb: 1 }}>
                <Select
                    value={selectedValue}
                    label={label}
                    onChange={handleChange}
                    disableUnderline
                    variant={"standard"}
                    disabled
                    sx={{
                        backgroundColor: "secondary.main",
                        "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "black" }
                    }}
                >
                    {availableValue.map((value, key) => {
                        return (
                            <MenuItem value={value} key={key} >{value}</MenuItem>
                        );
                    })}
                </Select>
            </Box>
        </Box>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "order_actions",
    initialState: {
        orders: [],
        order_id_details: {},
        loading: false
    },
    reducers: {
        // action => action handler
        fetchOrderWithIdStarted: (state, action) => {
            state.loading = true;
        },
        fetchOrderWithIdSuccess: (state, action) => {
            let response = action.payload.server_response;
            let order_id = response.id;
            let mapping = {};
            mapping[order_id] = response;
            state.order_id_details = mapping;
            state.loading = false;
        },
        fetchOrderWithIdFailed: (state, action) => {
            ErrorMessage("Cannot fetch order details.");
            state.loading = false;
        },
        fetchAllOrdersStarted: (state, action) => {
            state.loading = true;
        },
        fetchAllOrdersSuccess: (state, action) => {
            state.orders = action.payload.server_response;
            state.loading = false;
        },
        fetchAllOrdersFailed: (state, action) => {
            ErrorMessage("Cannot fetch you orders.")
            state.loading = false;
        },
    }
});

export const {
    fetchOrderWithIdStarted,
    fetchOrderWithIdSuccess,
    fetchOrderWithIdFailed,
    fetchAllOrdersStarted,
    fetchAllOrdersSuccess,
    fetchAllOrdersFailed
} = slice.actions;

export default slice.reducer;


export const fetchOrderWithId = (order_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/order_detail/" + order_id + "/",
            data_to_server: {},
            data: {},
            method: "get",
            onStart: fetchOrderWithIdStarted.type,
            onSuccess: fetchOrderWithIdSuccess.type,
            onError: fetchOrderWithIdFailed.type
        })
    );
};

export const fetchAllOrders = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/orders/",
            data_to_server: {},
            data: {},
            method: "get",
            onStart: fetchAllOrdersStarted.type,
            onSuccess: fetchAllOrdersSuccess.type,
            onError: fetchAllOrdersFailed.type,
        })
    );
};
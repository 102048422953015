import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';


const GridModeContext = createContext();

export const GridModeProvider = ({ children }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isGridModeFromURL = queryParams.get('grid') === 'true';
    const [gridMode, setGridMode] = useState(isGridModeFromURL);

    return (
        <GridModeContext.Provider value={{ gridMode, setGridMode }}>
            {children}
        </GridModeContext.Provider>
    );
};

export const useGridMode = () => {
    return useContext(GridModeContext);
};
import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

const innerBoxStyles = {
  marginTop: "1em",
};

const FeedbackItem = ({ item }) => {
  const [liked, setLiked] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [feedbackText, setFeedbackText] = useState("");
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, name]);
    } else {
      setCheckedItems([...checkedItems.filter((c) => c !== name)]);
    }
  };
  const handleFeedbackTextChange = (event) => {
    setFeedbackText(event.target.value);
  };
  const submitFeedback = () => { };
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Box
          style={{
            padding: "10%",
            marginTop: "100px",
          }}
        >
          <Box display="flex" flexDirection="row">
            <Typography flexGrow="1">Did You like the collection?</Typography>
            <Box paddingLeft="0.5em">
              {liked ? (
                <ThumbUpAltIcon />
              ) : (
                <ThumbUpOffAltIcon onClick={() => setLiked(true)} />
              )}
            </Box>
            <Box paddingLeft="0.5em">
              {liked === false ? (
                <ThumbDownAltIcon />
              ) : (
                <ThumbDownOffAltIcon onClick={() => setLiked(false)} />
              )}
            </Box>
          </Box>
          {liked && (
            <Box
              style={{
                ...innerBoxStyles,
                padding: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                color="secondary"
              >
                <Typography variant="h6">Want more ?</Typography>
              </Button>
            </Box>
          )}
          {liked === false && (
            <Box
              style={{
                ...innerBoxStyles,
              }}
            >
              <Typography variant="h6">{item.question_negative}</Typography>
              <FormGroup>
                {item.options_negative.map((option, key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      control={<Checkbox />}
                      label={option}
                      name={option}
                      onChange={handleCheckboxChange}
                    />
                  );
                })}
              </FormGroup>
            </Box>
          )}
          {liked !== null && (
            <Box
              style={{
                ...innerBoxStyles,
              }}
            >
              <TextField
                placeholder="Let us know more about your experience"
                multiline
                rows={4}
                value={feedbackText}
                onChange={handleFeedbackTextChange}
                fullWidth
              />
            </Box>
          )}
          {liked !== null && (
            <Box
              style={{
                ...innerBoxStyles,
              }}
            >
              <Button
                style={{ textTransform: "none" }}
                variant="contained"
                fullWidth
                onClick={submitFeedback}
              >
                Submit Feedback
              </Button>
            </Box>
          )}
        </Box>
        <Toolbar />
      </Box>
    </>
  );
};

export default FeedbackItem;

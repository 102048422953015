import React from "react";
import { useEffect, useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import LandingPageBanner from "../../Components/Dashboard/LandingPageBanner";
import LandingPageMessage from "../../Components/Dashboard/LandingPageMessage";
import { isMobile } from 'react-device-detect';
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { fetchInstaOutfitsFeed } from '../../../Store/fetch_insta_outfits_feed';
import { useNavigate } from "react-router-dom";
import FloatingButton from './FollowUsButton';


const NUM_TO_FETCH = 12;

const BASE_DASH_CONNFIG = {
    "media_desktop": "https://images.unsplash.com/photo-1490481651871-ab68de25d43d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80",
    "type_media_desktop": "IMAGE",
    "media_mobile": "https://images.unsplash.com/photo-1603400521630-9f2de124b33b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
    "type_media_mobile": "IMAGE",
    "messages": ["YOUR NEW STYLE IS READY"]
};

function GetBannerMediaType(isMobile, metadata) {
    return isMobile ? BASE_DASH_CONNFIG["type_media_mobile"] : BASE_DASH_CONNFIG["type_media_desktop"];
}

function GetBannerMediaSrc(isMobile, metadata) {
    return isMobile ? BASE_DASH_CONNFIG["media_mobile"] : BASE_DASH_CONNFIG["media_desktop"]
}

function GetBannerMessageList(isMobile, metadata) {
    return BASE_DASH_CONNFIG["messages"];
}

function InstaFeedWithBanner(props) {
    const [gridPhotos, setGridPhotos] = useState(new Array());

    const navigate = useNavigate();
    useEffect(() => {
        if (props.collection_list.length == 0) {
            props.fetchInstaOutfitsFeed(NUM_TO_FETCH);
        }
    }, []);

    useEffect(() => {
        var local_arr = [];
        for (var index = gridPhotos.length; index < props.collection_list.length; index++) {
            local_arr.push({
                src: props.collection_list[index]['image'],
                desired_width: isMobile ? parseInt(window.innerWidth / 3) : 300,
                desired_height: isMobile ? parseInt(window.innerWidth / 3) : 300,
                width: 300,
                height: 300,
                title: props.collection_list[index]['title'],
                link: props.collection_list[index]['link'],
            });
        }
        if (gridPhotos.length > 0) {
            setGridPhotos((prevPhotos) => [...prevPhotos, ...local_arr]);
        }
        else {
            setGridPhotos(local_arr);
        }
    }, [props.collection_list]);

    const handleImageClick = useCallback((event, { photo, index }) => {
        navigate("/feed?collection=" + photo.link)
    }, [props.collection_list]);

    const CustomImageComponent = ({ photo }) => {
        const [hovered, setHovered] = useState(false);

        const handleMouseEnter = () => {
            setHovered(true);
        };

        const handleMouseLeave = () => {
            setHovered(false);
        };
        return (
            <div style={{ position: "relative", cursor: "pointer" }}>
                <img
                    src={photo.src}
                    alt={photo.title}
                    width={isMobile ? String(photo.desired_width) + "px" : String(photo.width) + "px"}
                    height={isMobile ? String(photo.desired_height) + "px" : String(photo.height) + "px"}
                    onClick={(event) => handleImageClick(event, { photo })}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        filter: hovered ? "brightness(0.8)" : "brightness(1)", // Apply hover effect
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        bottom: 5,
                        left: "10%",
                        right: "10%",
                        width: "80%",
                        background: "rgba(0, 0, 0, 0.3)",
                        color: "#fff",
                        padding: isMobile ? "1px" : "5px",
                        textAlign: "center",
                        fontSize: isMobile ? "10px" : "15px",
                        whiteSpace: "nowrap", // Add this line to prevent text from wrapping
                        overflow: "hidden", // Add this line to hide any overflowed text
                        textOverflow: "ellipsis", // Add this line to show an ellipsis (...) when text is truncated
                    }}
                >
                    {photo.title}
                </div>
            </div >
        );
    };

    const containerStyle = {
        marginTop: '2em',
        padding: 0, // Remove padding
        margin: 0, // Remove margin
        width: '100%', // Cover entire width
    };

    const innerContainerStyle = {
        padding: 0, // Remove padding
        margin: 0, // Remove margin
    };


    return (
        <Container
            style={{
                minWidth: "100%",
                minHeight: "100vh",
                padding: 0
            }}
        >
            <Box
                style={{
                    marginTop: !isMobile ? "4em" : 0,
                    height: "25vh"
                }}
            >
                {
                    <LandingPageBanner
                        mediaType={GetBannerMediaType(isMobile, props.collection_metadata)}
                        mediaSrc={GetBannerMediaSrc(isMobile, props.collection_metadata)}
                    />
                }
                {
                    <LandingPageMessage
                        messageList={GetBannerMessageList(isMobile, props.collection_metadata)}
                        isMobile={isMobile}
                    />
                }
            </Box>
            <Box
                style={{
                    width: "100%",
                    minHeight: "60vh",
                    position: "absolute",
                    paddingBottom: isMobile ? 56 : 0
                }}
            >
                <Container maxWidth="lg" style={isMobile ? containerStyle : null} >
                    <Container component="main" maxWidth="md" style={isMobile ? innerContainerStyle : null}>
                        <Gallery
                            photos={gridPhotos}
                            targetRowHeight={isMobile ? 100 : 300}
                            renderImage={(props) => <CustomImageComponent {...props} />}
                        />
                    </Container>
                    {!props.end_of_item_reached && <Box
                        style={{
                            padding: "3%",
                            color: "white",
                            marginTop: "10px",
                            marginBottom: "50px"
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        color="primary.contrastText"
                    >
                        <Button
                            style={{
                                borderRadius: "1em",
                                color: "black",
                            }}
                            variant="contained"
                            disabled={false}
                            onClick={(e) => props.fetchInstaOutfitsFeed(NUM_TO_FETCH)}
                        >
                            <Typography variant="subtitle">Load More ...</Typography>
                        </Button>
                    </Box>
                    }
                </Container>
            </Box>
            <FloatingButton />
        </Container >
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    collection_list: state.fetch_insta_outfits_feed.collection_list,
    end_of_item_reached: state.fetch_insta_outfits_feed.end_of_item_reached,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInstaOutfitsFeed: (num_to_fetch) => dispatch(fetchInstaOutfitsFeed(num_to_fetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstaFeedWithBanner);
import React, { useState } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { connect } from "react-redux";

import { postCommentFeedback } from '../../../../Store/feature_lookup';

const Comment = ({ comment }) => {
    return (
        <>
            <Box padding="0.5em">
                <Box width="100%">
                    <Divider textAlign="right">
                        <Typography variant="caption">
                            {comment.timestamp !== "" ? comment.timestamp : ""}
                        </Typography>
                    </Divider>
                </Box>
                <Typography variant="body1" paddingTop="0.4em" paddingLeft="0">
                    {comment.message}
                </Typography>
            </Box>
        </>
    );
};

function CommentsList(props) {
    const [newComment, setNewComment] = useState("");
    const submitComment = (e) => {
        props.postCommentFeedback(props.current_index, newComment);
        setNewComment("");
    };

    return (
        <>
            <Container
                maxWidth="xs"
                style={{
                    background: "white",
                    height: "3.4em",
                    width: "100%",
                    position: "fixed",
                    bottom: 2,
                    padding: 0
                }}
            >
                <TextField
                    label="Comment"
                    variant="filled"
                    fullWidth
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{ input: { color: 'black' } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={submitComment}>
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Container>
            <Box style={{ paddingLeft: 16, paddingRight: 16, marginBottom: "4.0em" }}>
                {props.current_index >= 0 &&
                    props.feed_comments &&
                    props.current_index < props.feed_comments.length &&
                    props.feed_comments[props.current_index] !== null &&
                    props.feed_comments[props.current_index] !== undefined &&
                    props.feed_comments[props.current_index].map((comment, key) => {
                        return <Comment key={key} comment={comment} />;
                    })}
            </Box>
        </>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    feed_comments: state.feature_lookup.feed_comments,
    current_index: state.feature_lookup.current_index
});

const mapDispatchToProps = (dispatch) => ({
    postCommentFeedback: (index, commentText) => dispatch(postCommentFeedback(index, commentText)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsList);
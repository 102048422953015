import React from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Chip from "@mui/material/Chip";

export default function ProductDebugInfo({ index, debug_info, setDrawerOpenStateFn, score_annotations }) {
    return (
        <>
            {debug_info &&
                <div
                    style={{
                        position: "absolute",
                        width: "100px",
                        top: 30,
                        zIndex: 1,
                        left: 30,
                    }}
                >
                    <Chip
                        icon={<ShoppingBagIcon style={{ color: "white" }} />}
                        label={index}
                        variant="outlined"
                        onClick={() => {
                            setDrawerOpenStateFn(true, "product_debug");
                        }}
                        color="default"
                        style={{
                            backgroundColor: "#c5a38f",
                            color: "white",
                            font: "10px",
                        }}
                    />
                </div>
            }
            {debug_info && score_annotations &&
                <div
                    style={{
                        position: "absolute",
                        width: "100px",
                        top: 30,
                        zIndex: 1,
                        right: -30,
                    }}
                >
                    <Chip
                        label={score_annotations.curator_name}
                        variant="outlined"
                        color="default"
                        style={{
                            backgroundColor: "#c5a38f",
                            color: "white",
                            font: "10px",
                        }}
                    />
                    <Chip
                        label={score_annotations.curator_score + "/" + score_annotations.user_score}
                        variant="outlined"
                        color="default"
                        style={{
                            backgroundColor: "#c5a38f",
                            color: "white",
                            font: "10px",
                        }}
                    />
                </div>
            }
        </>
    );
}
import React, { useState, useEffect } from 'react'
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Container from "@mui/material/Container";
import { addOrEditAddress, fetchAllAddress } from '../../../Store/cart_actions';
import { connect } from "react-redux";

import { useNavigate, useSearchParams } from "react-router-dom";

const AddressForm = (props) => {
    const [searchParams] = useSearchParams();
    const addressId = searchParams.get('id');
    const navigate = useNavigate();

    useEffect(() => {
        if (props.address.length == 0) {
            props.fetchAllAddress()
        }
        else {
            let existingAddress = props.address.filter(item => item.address_id == addressId);
            if (existingAddress.length > 0) {
                existingAddress = existingAddress[0];
                setAddress(existingAddress);
            }
        }
    }, [props.address]);

    const [address, setAddress] = useState({
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: ''
    });

    const handleChange = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        props.addOrEditAddress(address);
        navigate('/checkout');
    }
    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: 100 }}>
            <Typography variant='h5'>
                SHIPPING DETAILS
            </Typography>
            <Box component='form' onSubmit={submitHandler}>
                <TextField
                    onChange={handleChange}
                    name="address_line1"
                    value={address.address_line1}
                    label='Address Line 1'
                    type='text'
                    sx={{
                        mb: 2, mt: 2,
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }
                    }}
                    fullWidth
                    required
                />
                <TextField
                    onChange={handleChange}
                    value={address.address_line2}
                    label='Address Line 2'
                    name="address_line2"
                    type='text'
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }

                    }}
                    fullWidth
                />
                <TextField
                    onChange={handleChange}
                    value={address.city}
                    label='City'
                    type='text'
                    name="city"
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }
                    }}
                    fullWidth
                    required
                />
                <TextField
                    onChange={handleChange}
                    value={address.postal_code}
                    name="postal_code"
                    label='Postal Code'
                    type='text'
                    sx={{
                        mb: 2, width: "50%",
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }
                    }}
                    required
                />
                <TextField
                    onChange={handleChange}
                    value={address.state}
                    name="state"
                    label='State'
                    type='text'
                    sx={{
                        mb: 2, width: "50%",
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }
                    }}
                    required
                />
                <TextField
                    onChange={handleChange}
                    value={address.country}
                    name="country"
                    label='Country'
                    type='text'
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "primary.contrastText", color: "primary.contrastText" },
                        "& .MuiFormLabel-root": {
                            color: "primary.contrastText" // or black
                        }
                    }}
                    fullWidth
                    required
                />
                <Box style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Button
                        variant="contained"
                        style={{
                            marginTop: "1em",
                            marginBottom: "0.5em",
                            textTransform: "none",
                            borderRadius: "10px",
                            paddingLeft: "40px",
                            paddingRight: "40px",
                            margin: "auto"
                        }}
                        type='submit'
                        disabled={!address.address_line1 || !address.city || !address.postal_code || !address.country}
                        color="custom"
                        sx={{
                            "&:disabled": {
                                backgroundColor: "secondary.main",
                                color: "primary.contrastText"
                            }
                        }}
                    >
                        <Typography
                            variant="button"
                            padding="0.2em"
                            fontSize="1.3em"
                        >
                            Save Address
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    address: state.cart_actions.address
});

const mapDispatchToProps = (dispatch) => ({
    addOrEditAddress: (address) => dispatch(addOrEditAddress(address)),
    fetchAllAddress: () => dispatch(fetchAllAddress())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
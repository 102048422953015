import React, { useEffect } from "react";
//MUI
import Container from "@mui/material/Container";
import CheckoutSummaryCard from '../../Components/Cart/CheckoutSummary';
import AddressCards from '../../Components/Cart/AddressCards';
import CartItems from '../../Components/Cart/CartItems';
import { fetchCart, removeCartItem, updateCartItemQuantity, fetchAllAddress } from '../../../Store/cart_actions';
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { ErrorMessage } from "../../../Controllers/Notifications";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const Cart = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        props.fetchCart();
    }, []);

    if (props.access_token == null || props.access_token == undefined) {
        return (
            <Container component="main" maxWidth="xs" style={{ marginTop: 100, overflow: "scroll", marginBottom: 60, textAlign: "center" }}>
                <Button
                    variant="contained"
                    style={{
                        marginTop: "1em",
                        marginBottom: "0.5em",
                        textTransform: "none",
                        borderRadius: "10px",
                        paddingLeft: "40px",
                        paddingRight: "40px"
                    }}
                    color="custom"
                    onClick={() => { navigate('/authentication'); }}
                >
                    <Typography
                        variant="button"
                        padding="0.2em"
                        fontSize="1.1em"
                    >
                        Please login to shop
                    </Typography>
                </Button>
            </Container>
        )
    }

    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: 100, overflow: "scroll", marginBottom: 60, textAlign: "center" }}>
            {
                props.cart.items_count > 0 ?
                    <CheckoutSummaryCard
                        cart={props.cart}
                        buttonText={"Checkout"}
                        buttonAction={() => {
                            if (props.cart.items_count > 0) {
                                navigate('/checkout');
                            }
                            else {
                                ErrorMessage("Please add items in the cart.");
                                navigate('/');
                            }
                        }}
                    /> :
                    <Button
                        variant="contained"
                        style={{
                            marginTop: "1em",
                            marginBottom: "0.5em",
                            textTransform: "none",
                            borderRadius: "10px",
                            paddingLeft: "40px",
                            paddingRight: "40px"
                        }}
                        color="custom"
                        onClick={() => { navigate('/'); }}
                    >
                        <Typography
                            variant="button"
                            padding="0.2em"
                            fontSize="1.1em"
                        >
                            Find items. Go to Home !
                        </Typography>
                    </Button>
            }
            <CartItems
                items={props.cart.items}
                item_count={props.cart.items_count}
                updateCartItemQuantity={props.updateCartItemQuantity}
                removeCartItem={props.removeCartItem}
            />
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    access_token: state.auth.access_token,
    cart: state.cart_actions.cart,
    address: state.cart_actions.address
});

const mapDispatchToProps = (dispatch) => ({
    updateCartItemQuantity: (productId, variantId, quantity) => dispatch(updateCartItemQuantity(productId, variantId, quantity)),
    fetchCart: () => dispatch(fetchCart()),
    removeCartItem: (cartItemId) => dispatch(removeCartItem(cartItemId)),
    fetchAllAddress: () => dispatch(fetchAllAddress()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

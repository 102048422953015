import { useState, useEffect } from "react";
import * as React from 'react';
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Lazy } from "swiper";
import { getImagesListFromProductFeatures } from '../../Components/FeedView/MediaItem';
import {
    fetchFeaturesForProductPage
} from '../../../Store/feature_lookup';
import { connect } from "react-redux";
import {
    DisplayProductNameAndPrice,
    GetDiscountedPriceFromFeedResponse,
    GetMrpFromFeedResponse
} from '../../Components/SwipeableDrawer/ProductDetails/ProductDetailsAndPrice'
import { addItemToCart } from '../../../Store/cart_actions';
import { setDrawerOpenStateFn } from '../../../Store/bottom_drawer';

import { HandleVariants } from '../../Components/SwipeableDrawer/ProductDetails/VariantsHandler';
import Details from "./Details";
import Button from "@mui/material/Button";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

function CarouselSlideImage({ img }) {
    return (
        <img
            effect="blur"
            onError={(event) => (event.target.style.display = "none")}
            onLoad={(event) =>
                (event.target.style.display = "inline-block")
            }
            className="swiper-lazy"
            data-src={img}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
    );
}

function ProductPage(props) {
    const { productId } = useParams();
    // Only applicable on desktop.
    const topHeightOffSet = 64;
    // Only applicable on mobile.
    const bottomNavHeight = 52;

    const [screenHeight, setScreenHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0);

    function setScreenDimensions(oldScreeHeight) {
        var h = !isMobile ? parseInt(visualViewport.height - topHeightOffSet) : parseInt(visualViewport.height - bottomNavHeight);

        // This most likely means that address bar changed, hence we resize. 
        // Resize will also be called when keyboard opens, this condition ensures that we dont resize then.

        // We also check screenHeight == 0, since this will be the case upon init.

        // We only allow resize in 2 cases:
        // 1) Init: where oldScreeHeight will be set to 0.
        // 2) Resize of delta < 80, which will be the case when address bar resizes.
        // For all other cases, we dont resize, for example, when the keyboard opens up. 

        if (!(Math.abs(oldScreeHeight - h) < 80) && !(oldScreeHeight == 0)) {
            return;
        }
        if (isMobile) {
            setScreenHeight(h);
            setScreenWidth(visualViewport.width);
        }
        else if (!isMobile) {
            setScreenHeight(h);
            setScreenWidth(Math.min(visualViewport.width, 444));
        }
    }

    useEffect(() => {
        setScreenDimensions(screenHeight);
    }, []);

    useEffect(() => {
        props.fetchFeaturesForProductPage(productId);
    }, [productId]);

    const swiper = React.useRef(null);
    const setSwiper = (newSwiper) => {
        swiper.current = newSwiper;
    };

    const boxHeight = screenHeight * 60 / 100;

    const [mrp, setMrp] = useState(-1.0);
    const [discountPrice, setDiscountPrice] = useState(-1.0);

    const [canBuyVariant, setCanBuyVariant] = useState(false);
    const [selectedVariantId, setSelectedVariantId] = useState(-1);

    useEffect(() => {
        // Change the mrp, discounted price and image source whenever index or feed response changes.
        if (props.productPageFeatures && props.productPageFeatures.price) {
            var mrp = GetMrpFromFeedResponse(props.productPageFeatures);
            var discounted_price = GetDiscountedPriceFromFeedResponse(props.productPageFeatures);
            setMrp(mrp);
            setDiscountPrice(discounted_price);

        }
    }, [props.productPageFeatures]);


    if (!(props.productPageId == productId && props.productPageFeatures)) {
        return <>
        </>
    }
    const productImageList = [...new Set(getImagesListFromProductFeatures(props.productPageFeatures))];
    function setImageSource(imageSource) {
        if (productImageList.includes(imageSource)) {
            let index = productImageList.indexOf(imageSource);
            if (swiper.current) {
                swiper.current.slideTo(index);
            }
        }
    }

    return (
        <>
            <Box
                sx={{
                    marginLeft: isMobile ? "0" : parseInt((visualViewport.width - screenWidth) / 2) + "px",
                    marginTop: !isMobile ? topHeightOffSet + "px" : 0,
                    width: screenWidth,
                    height: screenHeight - 60,
                    overflowX: "scroll",
                }}
            >
                <Swiper
                    pagination={true}
                    loop={false}
                    preloadImages={false}
                    modules={[Pagination, Lazy]}
                    lazy={{
                        loadPrevNext: false,
                        loadOnTransitionStart: true,
                    }}
                    onSwiper={setSwiper}
                >
                    {/* Display all unique image paths. Since we apply lazy loading, we don't apply upper bound on how many images to pre-load. */}
                    {productImageList.map((img, key) => {
                        return (
                            <SwiperSlide
                                style={{
                                    width: screenWidth,
                                    height: boxHeight,
                                    backgroundImage: `url(${process.env.PUBLIC_URL}loading.gif)`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                                key={key}
                            >
                                <CarouselSlideImage
                                    img={img}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <Box
                    style={{
                        padding: "10px",
                    }}
                >
                    <DisplayProductNameAndPrice
                        product_name={props.productPageFeatures.name[0]}
                        brand_name={props.productPageFeatures.brand}
                        product_features={props.productPageFeatures}
                        mrp={mrp}
                        discountPrice={discountPrice}
                    />
                    <Box style={{ display: "flex", width: "100%" }}>
                        {props.productPageFeatures.product_shop != undefined ?
                            <HandleVariants
                                product_shop_info={props.productPageFeatures.product_shop}
                                addItemToCart={props.addItemToCart}
                                setDrawerOpenStateFn={props.setDrawerOpenStateFn}
                                access_token={props.access_token}
                                setMrp={setMrp}
                                setDiscountPrice={setDiscountPrice}
                                setImageSource={setImageSource}
                                setCanBuyVariant={setCanBuyVariant}
                                showButton={false}
                                setSelectedVariantId={setSelectedVariantId}
                            /> :
                            <>
                            </>
                        }
                    </Box>
                </Box>
                {props.productPageFeatures.brands_info &&
                    <Details
                        info={props.productPageFeatures.brands_info.info}
                        policy={props.productPageFeatures.brands_info.policy}
                        size_chart={props.productPageFeatures.brands_info.size_chart}
                    />
                }
            </Box>
            <Box style={{ height: 60 }}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        textTransform: "none",
                        height: 60
                    }}
                    color="custom"
                    sx={{
                        "&:disabled": {
                            backgroundColor: "secondary.main",
                            color: "primary.contrastText"
                        }
                    }}
                    onClick={() => {
                        if (props.access_token == null || props.access_token == undefined) {
                            // ErrorMessage("Please login to add items to cart.");
                            props.setDrawerOpenStateFn(true, "login_to_continue");
                        }
                        else {
                            // get selected variant id here.
                            props.addItemToCart(props.productPageFeatures.product_shop.id, selectedVariantId, 1);
                        }
                    }}
                >
                    <ShoppingBagIcon style={{ marginRight: "5px" }} />
                    {props.access_token == null || props.access_token == undefined ? "Login and Shop" : canBuyVariant ? "Add To Cart" : "Sold Out"}

                </Button>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    productPageId: state.feature_lookup.productPageId,
    productPageFeatures: state.feature_lookup.productPageFeatures,
    access_token: state.auth.access_token,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFeaturesForProductPage: (product_id) => dispatch(fetchFeaturesForProductPage(product_id)),
    addItemToCart: (productId, variant_id, quantity) => dispatch(addItemToCart(productId, variant_id, quantity)),
    setDrawerOpenStateFn: (open, drawerType) => dispatch(setDrawerOpenStateFn(open, drawerType)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
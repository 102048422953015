import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import './ProductCarousel.css';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function ProductCarouselCard({
    slide_index,
    feedback_label,
    image,
    subtitle1,
    subtitle2,
    top_title,
    url,
    available,
    affiliate_link,
    postFeedbackFn
}) {
    return (
        <Card
            sx={{
                border: "3px solid #aaa",
                borderRadius: 2,
                boxShadow: "none",
                position: "relative",
                bgcolor: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={() => {
                // if (available != "NOT_AVAILABLE") {
                postFeedbackFn(slide_index, feedback_label != '' ? "video_drawer_item_clicked_" + url : feedback_label + "__" + url, 1.0);
                if (affiliate_link && affiliate_link != "") {
                    window.open(affiliate_link, "_blank");
                }
                else {
                    window.open(url, "_blank");
                }
            }}
        >

            <CardActionArea>
                <div
                    style={{
                        height: 100,
                        width: 75,
                        borderRadius: 5,
                        overflow: "hidden",
                        borderWidth: "0px",
                    }}
                >
                    <div style={{
                        position: "absolute",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        height: "20px",
                        width: "100%",
                        marginTop: "0px",
                        wordWrap: "break-word",
                        overflow: "hidden",
                        zIndex: "2"
                    }}>
                        <Typography variant="subtitle"
                            style={{
                                paddingLeft: 5,
                                paddingTop: 3,
                                color: "white"
                            }} >
                            {capitalizeFirstLetter(top_title)}
                        </Typography>
                    </div>
                    <img
                        effect="blur"
                        src={image}
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            filter: available == "NOT_AVAILABLE" ? "brightness(0.6)" : "brightness(1.0)"
                        }}
                        loading="lazy"
                    />

                </div>
                <div style={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textAlign: "center",
                }}>
                    {subtitle1 == '' ? <></> :
                        <Typography variant="subtitle"
                            style={{
                                color: "white",
                            }}
                            fontSize={"0.8em"}
                        >
                            {capitalizeFirstLetter(subtitle1)}
                        </Typography>
                    }
                </div>
                <div style={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textAlign: "center"
                }}>
                    {subtitle2 == '' ? <></> :
                        <Typography variant="subtitle"
                            style={{
                                color: "white",
                            }}
                            fontSize={"0.7em"}
                        >
                            {capitalizeFirstLetter(subtitle2)}
                        </Typography>
                    }
                </div>

            </CardActionArea>
        </Card >
    );
}

function ProductCarouselHelper({
    slide_index,
    feedback_label,
    product_info,
    postFeedbackFn
}) {
    return (
        <Swiper
            slidesPerView={3}
            spaceBetween={20}
            freeMode={true}
            watchSlidesProgress
            navigation={true}
            modules={[FreeMode, Navigation]}
            cssMode={true}
        >
            {product_info.map((product_item, key) => {
                return (
                    <SwiperSlide
                        key={key}
                    >
                        <ProductCarouselCard
                            slide_index={slide_index}
                            feedback_label={feedback_label}
                            image={product_item['image_url']}
                            top_title={product_item['brand']}
                            subtitle1={product_item['broad_category']}
                            subtitle2={product_item['available'] == "NOT_AVAILABLE" ? "Out of Stock" : product_item['currency'] + ' ' + product_item['price']}
                            url={product_item['url']}
                            available={product_item['available']}
                            affiliate_link={product_item['affiliate_link'] ? product_item['affiliate_link'] : ""}
                            postFeedbackFn={postFeedbackFn}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}

export default function ProductCarousel({
    currentImageIndex,
    slide_index,
    product_info,
    product_info_list,
    postFeedbackFn
}) {
    return (
        <>
            {
                product_info.length > 0 && <ProductCarouselHelper
                    slide_index={slide_index}
                    feedback_label={""}
                    product_info={product_info}
                    postFeedbackFn={postFeedbackFn}
                />
            }
            {
                product_info_list.length > 0 && <ProductCarouselHelper
                    slide_index={slide_index}
                    feedback_label={String(product_info_list[currentImageIndex]['id'])}
                    product_info={product_info_list[currentImageIndex]['info']}
                    postFeedbackFn={postFeedbackFn}
                />
            }
        </>

    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { connect } from 'react-redux';
import { setDrawerOpenStateFn } from '../../../Store/bottom_drawer';
import { isMobile } from 'react-device-detect';

import CommentsList from '../../Components/SwipeableDrawer/Comments';
import SwipeInstructionContainer from '../../Components/SwipeableDrawer/SwipeUpDownInstructions';
import LoginToContinue from '../../Components/SwipeableDrawer/LoginToContinue';
import VideoProductsList from '../../Components/SwipeableDrawer/VideoProducts';
import ProductDetails from '../../Components/SwipeableDrawer/ProductDetails';
import BrandStory from '../../Components/SwipeableDrawer/BrandStory';
import UnifiedAuth from '../../Pages/UnifiedAuth';
import RatingOptions from '../../Components/RatingOptions';
// import CustomFeedback from '../../Components/SwipeableDrawer/CustomFeedback';


function SwipeableDrawerView(props) {
    const [screenWidth, setScreenWidth] = React.useState(0);

    function setScreenDimensions() {
        if (isMobile) {
            setScreenWidth(visualViewport.width);
        }
        else if (!isMobile) {
            setScreenWidth(Math.min(visualViewport.width, 444));
        }
    }

    React.useEffect(() => {
        if (props.isOpen) {
            setScreenDimensions();
        }
    }, [props.isOpen]);

    function CloseDrawer() {
        props.setDrawerOpenStateFn(false, 'NA');
    }

    function getBackgroundColor(drawerType) {
        if (drawerType == "swipe_instruction") {
            return "rgba(0, 0, 0, 0)";

        }
        else if (drawerType == "login_to_continue") {
            return "background.default";
        }
        else {
            return "primary.main";
        }
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                anchor={"bottom"}
                open={props.isOpen}
                onClose={() => { props.setDrawerOpenStateFn(false, 'NA') }}
                onOpen={() => { props.setDrawerOpenStateFn(true, 'NA1') }}
                sx={{
                    '& > .MuiPaper-root': {
                        backgroundColor: "rgba(0, 0, 0,0)",
                        backgroundImage: 'linear-gradient(rgba(0, 0, 0,0), rgba(0, 0, 0,0))'
                    },
                }}
                ModalProps={{
                    keepMounted: true,
                }}
                disableSwipeToOpen={true}
            >
                <Container
                    component="main"
                    style={{
                        width: screenWidth,
                        maxHeight: props.drawerType != "swipe_instruction" ? parseInt(visualViewport.height * 65 / 100) : visualViewport.height,
                        minHeight: props.drawerType != "swipe_instruction" ? parseInt(visualViewport.height * 40 / 100) : visualViewport.height,
                        padding: 0,
                        backgroundColor: getBackgroundColor(props.drawerType),
                    }}
                >
                    <Box
                        sx={{
                            width: screenWidth,
                            backgroundColor: getBackgroundColor(props.drawerType),
                            padding: 0,
                            margin: 0,
                            minHeight: props.drawerType != "swipe_instruction" ? parseInt(visualViewport.height * 40 / 100) : visualViewport.height,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {props.drawerType == 'login_to_continue' && <UnifiedAuth close_drawer_fn={CloseDrawer} />}
                        {props.drawerType == "" && <></>}
                        {props.drawerType == "swipe_instruction" && <SwipeInstructionContainer></SwipeInstructionContainer>}
                        {props.drawerType == "comment" && <CommentsList />}
                        {props.drawerType == "video_product_list" && <VideoProductsList screenWidth={screenWidth} />}
                        {props.drawerType == "product_details" && <ProductDetails />}
                        {props.drawerType == "brand_story" && <BrandStory />}
                        {props.drawerType == "rating_options" && <RatingOptions />}


                        {/* {props.drawerType == "custom_feedback" && <CustomFeedback />} */}
                    </Box>
                </Container>
            </SwipeableDrawer>
        </React.Fragment >
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    isOpen: state.bottom_drawer.isOpen,
    drawerType: state.bottom_drawer.drawerType
});

const mapDispatchToProps = (dispatch) => ({
    setDrawerOpenStateFn: (open, drawerType) => dispatch(setDrawerOpenStateFn(open, drawerType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwipeableDrawerView);
import { useEffect, useState } from "react";
import React from "react";
import { isMobile } from 'react-device-detect';

//MUI
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";

// Custom components.
import LandingPageBanner from "../../Components/Dashboard/LandingPageBanner";
import LandingPageMessage from "../../Components/Dashboard/LandingPageMessage";
import CollectionListItem from "../../Components/CollectionList";
import Footer from "../../Components/Dashboard/Footer";

import CollectionItemsGrid from '../../Components/CollectionItemsGrid';
// Redux.
import { connect } from "react-redux";
import { fethcHomeScreenCollections } from '../../../Store/fetch_collection_list';

// Config.
import { getThemeConfig } from "../../../Controllers/GetStatelessTheme";
import { NUM_HOME_SCREEN_COLLECTIONS } from '../../../Constants';

import { useParams } from "react-router-dom";

const HOME_DASH_CONNFIG = {
    "media_desktop": "https://dz7i6uwcjewim.cloudfront.net/_Users_siddhantmanocha_Documents_Curience_search_engine_user_collection_feeds_unified_data_ingestion_drive_1O5a8HSd6zEbIExYmSet8NBNt0OYuYoVp_home_page_desktop.mp4",
    "type_media_desktop": "VIDEO",
    "media_mobile": "https://dz7i6uwcjewim.cloudfront.net/_Users_siddhantmanocha_Documents_Curience_search_engine_user_collection_feeds_unified_data_ingestion_drive_1O5a8HSd6zEbIExYmSet8NBNt0OYuYoVp_mobile_home.mp4",
    "type_media_mobile": "VIDEO",
    "messages": ["YOUR NEW STYLE IS READY"]
};

const OTHERS_DASH_CONNFIG = {
    "media_desktop": "https://dz7i6uwcjewim.cloudfront.net/_Users_siddhantmanocha_Documents_Curience_search_engine_user_collection_feeds_unified_data_ingestion_drive_1O5a8HSd6zEbIExYmSet8NBNt0OYuYoVp_browsing_commerce_desktop.mp4",
    "type_media_desktop": "VIDEO",
    "media_mobile": "https://dz7i6uwcjewim.cloudfront.net/_Users_siddhantmanocha_Documents_Curience_search_engine_user_collection_feeds_unified_data_ingestion_drive_1O5a8HSd6zEbIExYmSet8NBNt0OYuYoVp_browsing_commerce_mobile.mp4",
    "type_media_mobile": "VIDEO",
    "messages": ["INSPIRATIONS FOR ALL OCCASIONS"]
};

const Dashboard = (props) => {
    const { pageName } = useParams();
    const [dashConfig, setDashConfig] = useState(undefined);
    const themeConfig = getThemeConfig();

    useEffect(() => {
        const local_page_name = pageName == undefined ? "home" : pageName;
        props.fethcHomeScreenCollections(NUM_HOME_SCREEN_COLLECTIONS, local_page_name);
    }, [pageName]);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        const local_page_name = pageName == undefined ? "home" : pageName;
        if (local_page_name == "home") {
            if (!isEmpty(props.home_page_def)) {
                setDashConfig(props.home_page_def);
            }
            // else {
            //     setDashConfig(HOME_DASH_CONNFIG);
            // }
        }
        else {
            if (!isEmpty(props.home_page_def)) {
                setDashConfig(props.home_page_def);
            }
        }
    }, [props.home_page_def]);

    return (
        <Container
            style={{
                minWidth: "100%",
                minHeight: "100vh",
                padding: 0
            }}
        >
            <Box
                style={{
                    marginTop: !isMobile ? "4em" : 0,
                    height: "25vh"
                }}
            >
                {
                    dashConfig != undefined && <LandingPageBanner
                        mediaType={isMobile ? dashConfig["type_media_mobile"] : dashConfig["type_media_desktop"]}
                        mediaSrc={isMobile ? dashConfig["media_mobile"] : dashConfig["media_desktop"]}
                    />
                }
                {
                    dashConfig != undefined && <LandingPageMessage
                        messageList={dashConfig["messages"]}
                        isMobile={isMobile}
                    />
                }
            </Box>
            <Box
                style={{
                    marginTop: "5vh",
                    width: "100%",
                    minHeight: "60vh",
                    position: "absolute",
                    paddingBottom: isMobile ? 56 : 0
                }}
            >
                <Container maxWidth="lg" style={{ marginBottom: "4em" }}>
                    {/* Show carousel lists if more than one list in COC.  */}
                    {props.collection_list.length > 1 && props.collection_list.map((list, key) => {
                        return <CollectionListItem key={key} collectionsList={list} />;
                    })}
                    {/* Show items grid if exactly one item in the COC. */}
                    {props.collection_list.length == 1 &&
                        <CollectionItemsGrid collectionsList={props.collection_list[0]} />
                    }
                    {/* Show a loading item if non COC. */}
                    {props.collection_list.length == 0 &&
                        <Box style={{
                            textAlign: "center",
                        }}>
                            <CircularProgress disableShrink sx={{ color: "white" }} />
                        </Box>
                    }
                </Container>
                <Footer
                    collectionItemsLoaded={props.collection_list.length > 0}
                />
            </Box>

        </Container >
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    collection_list: state.fetch_collection_list.collection_list,
    home_page_def: state.fetch_collection_list.home_page_def
});

const mapDispatchToProps = (dispatch) => ({
    fethcHomeScreenCollections: (num_to_fetch, local_page_name) => dispatch(fethcHomeScreenCollections(num_to_fetch, local_page_name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function GetVariantsAndAvailabilty(variants, variants_index) {
    let variant_name_key = "variant" + variants_index + "_name";
    let variant_value_key = "variant" + variants_index + "_value";

    var variant_label = "";
    var all_variant_values = [];
    var available_variant_values = [];
    for (const variant of variants) {
        let variant_name = variant[variant_name_key];
        let variant_value = variant[variant_value_key];
        variant_label = variant_name;
        if (!all_variant_values.includes(variant_value)) {
            all_variant_values.push(variant_value);
        }
        if (variant.available && !available_variant_values.includes(variant_value)) {
            available_variant_values.push(variant_value);
        }
    }
    return [variant_label, all_variant_values, available_variant_values];
}


function IsVariantAvailable(variant1_value, variant2_value, variant3_value, variant4_value, all_variants) {
    for (const variant of all_variants) {
        if (variant.variant1_value == variant1_value && variant.variant2_value == variant2_value && variant.variant3_value == variant3_value && variant.variant4_value == variant4_value && variant.available) {
            return true;
        }
    }
    return false;
}

function getMrpDiscountPriceAndImageForVariant(variant1_value, variant2_value, variant3_value, variant4_value, all_variants) {
    for (const variant of all_variants) {
        if (variant.variant1_value == variant1_value && variant.variant2_value == variant2_value && variant.variant3_value == variant3_value && variant.variant4_value == variant4_value) {
            let image = '';
            if (variant.images && variant.images.length > 0) {
                image = variant.images[0];
            }
            return [variant.price, variant.discount_price, image];
        }
    }
    return [-1.0, -1.0, ''];
}

function DisplayVariants({ variant_label, selectedVariantValue, allVariantValues, allAvailableVariantValues, setVariantValue }) {
    if (variant_label == '') {
        return (
            <></>
        );
    }
    return (
        <Box style={{ marginTop: "1em" }}>
            <Typography style={{ flexGrow: 1 }} variant="body1">
                {variant_label}
            </Typography>
            {allVariantValues.map(variant_value => (
                // Add properties via sx causes a delay in color updates, maybe it has to do with active class being set for button or something else.
                <Button
                    key={variant_value}
                    variant={"contained"}
                    size="small"
                    style={{
                        backgroundColor: selectedVariantValue == variant_value ? "#98FB98" : allAvailableVariantValues.includes(variant_value) ? "#f1f1f1" : "#696969",
                        "&:disabled": {
                            backgroundColor: "#696969",
                            color: "primary.contrastText"
                        },
                        border: '1px solid'
                    }}
                    onClick={() => setVariantValue(variant_value)}
                    disabled={!allAvailableVariantValues.includes(variant_value)}
                >
                    {variant_value}
                </Button>
            ))}
        </Box>
    );
}


export function HandleVariants({ product_shop_info, addItemToCart, cart, setDrawerOpenStateFn, access_token, setMrp, setDiscountPrice, setImageSource, setCanBuyVariant = undefined, setSelectedVariantId = undefined, showButton = true }) {
    // product_shop_info: The variants info received from backend.
    // addItemToCart: Function to add item to cart.
    // setDrawerOpenStateFn: function to open the drawer, this is used to trigger login in case persob tries to add item to cart without login.
    // access_token: Access token is used to determine if the person is logged in or not.
    // setMrp: Function which updates the mrp based on the variant that is selected.
    // setDiscountPrice: Function which updates the discounted price based on the variant that is selected.
    // setImageSource: Function that updates the image source based on the variant that is selected.

    var default_variant = product_shop_info.variants.filter(item => item.id == product_shop_info.default_variant)[0];
    const [selectedVariant1, setSelectedVariant1] = useState(default_variant ? default_variant.variant1_value : '');
    const [selectedVariant2, setSelectedVariant2] = useState(default_variant ? default_variant.variant2_value : '');
    const [selectedVariant3, setSelectedVariant3] = useState(default_variant ? default_variant.variant3_value : '');
    const [selectedVariant4, setSelectedVariant4] = useState(default_variant ? default_variant.variant4_value : '');

    const [variant1_label, all_variant1, available_variant1] = GetVariantsAndAvailabilty(product_shop_info.variants, 1);
    const [variant2_label, all_variant2, available_variant2] = GetVariantsAndAvailabilty(product_shop_info.variants, 2);
    const [variant3_label, all_variant3, available_variant3] = GetVariantsAndAvailabilty(product_shop_info.variants, 3);
    const [variant4_label, all_variant4, available_variant4] = GetVariantsAndAvailabilty(product_shop_info.variants, 4);

    useEffect(() => {
        const response = getMrpDiscountPriceAndImageForVariant(selectedVariant1, selectedVariant2, selectedVariant3, selectedVariant4, product_shop_info.variants);
        setMrp(response[0]);
        setDiscountPrice(response[1]);
        if (response[2] != '') {
            setImageSource(response[2]);
        }
        if (setCanBuyVariant != undefined) {
            setCanBuyVariant(IsVariantAvailable(selectedVariant1, selectedVariant2, selectedVariant3, selectedVariant4, product_shop_info.variants));
        }
        if (setSelectedVariantId != undefined) {
            var selected_variant_id = product_shop_info.variants.filter(item => item.variant1_value == selectedVariant1 && item.variant2_value == selectedVariant2 && item.variant3_value == selectedVariant3 && item.variant4_value == selectedVariant4)[0].id;
            setSelectedVariantId(selected_variant_id);
        }
    }, [selectedVariant1, selectedVariant2, selectedVariant3, selectedVariant4]);

    return (
        <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <DisplayVariants
                variant_label={variant1_label}
                selectedVariantValue={selectedVariant1}
                allVariantValues={all_variant1}
                allAvailableVariantValues={available_variant1}
                setVariantValue={setSelectedVariant1}
            >
            </DisplayVariants>
            <DisplayVariants
                variant_label={variant2_label}
                selectedVariantValue={selectedVariant2}
                allVariantValues={all_variant2}
                allAvailableVariantValues={available_variant2}
                setVariantValue={setSelectedVariant2}
            >
            </DisplayVariants>
            <DisplayVariants
                variant_label={variant3_label}
                selectedVariantValue={selectedVariant3}
                allVariantValues={all_variant3}
                allAvailableVariantValues={available_variant3}
                setVariantValue={setSelectedVariant3}
            >
            </DisplayVariants>
            <DisplayVariants
                variant_label={variant4_label}
                selectedVariantValue={selectedVariant4}
                allVariantValues={all_variant4}
                allAvailableVariantValues={available_variant4}
                setVariantValue={setSelectedVariant4}
            >
            </DisplayVariants>
            {showButton && <Box style={{ marginTop: "1em" }}>
                <Button
                    fullWidth
                    variant="contained"
                    style={{
                        marginTop: "5px",
                        textTransform: "none",
                    }}
                    color="custom"
                    sx={{
                        "&:disabled": {
                            backgroundColor: "secondary.main",
                            color: "primary.contrastText"
                        }
                    }}
                    onClick={() => {
                        if (access_token == null || access_token == undefined) {
                            // ErrorMessage("Please login to add items to cart.");
                            setDrawerOpenStateFn(true, "login_to_continue");
                        }
                        else {
                            var selected_variant_id = product_shop_info.variants.filter(item => item.variant1_value == selectedVariant1 && item.variant2_value == selectedVariant2 && item.variant3_value == selectedVariant3 && item.variant4_value == selectedVariant4)[0].id;
                            // get selected variant id here.
                            addItemToCart(product_shop_info.id, selected_variant_id, 1);
                        }
                    }}
                    disabled={!IsVariantAvailable(selectedVariant1, selectedVariant2, selectedVariant3, selectedVariant4, product_shop_info.variants)}
                >
                    <ShoppingBagIcon style={{ marginRight: "5px" }} />
                    {access_token == null || access_token == undefined ? "Login and Shop" : IsVariantAvailable(selectedVariant1, selectedVariant2, selectedVariant3, selectedVariant4, product_shop_info.variants) ? "Add To Cart" : "Sold Out"}
                </Button>
            </Box>
            }
        </Box>
    )
}
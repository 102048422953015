import React from "react";
import ShopItemButtonTransparent from "../Common/ShopActionButton";

export default function ProductDescriptionButton({ index, product_features, imageList, setDrawerOpenStateFn, postFeedbackFn }) {


    function getPriceStringToDisplay(product_features) {
        if (product_features.product_shop == undefined) {
            return "₹" + product_features.price.discounted_price;
        }
        var all_variants_price = [];
        for (var index = 0; index < product_features.product_shop.variants.length; index++) {
            all_variants_price.push(parseFloat(product_features.product_shop.variants[index].discount_price));
        }
        const allEqual = arr => arr.every(v => v === arr[0])
        if (allEqual(all_variants_price)) {
            return "₹" + all_variants_price[0];
        }
        else {
            var smallest_number = 1000000000.0;
            var largest_number = 0.0;
            for (const variant_price of all_variants_price) {
                if (variant_price > 0) {
                    if (variant_price < smallest_number) {
                        smallest_number = variant_price;
                    }
                    if (variant_price > largest_number) {
                        largest_number = variant_price
                    }
                }

            }
            return "₹" + smallest_number + "-" + "₹" + largest_number;
        }
    }

    function openInNewTab(url) {
        window.open(url, '_blank');
    }

    function onButtonClickFn(url) {
        return (e) => {
            openInNewTab(url);
            // Dont allow any other button click to be triggered when opening to new tab.
            e.stopPropagation();
            postFeedbackFn(index, 'product_opened_out', 1.0);
        }
    }

    function GetLastTwoWords(title, short_name) {
        if (short_name == "") {
            let parts = title.split(" ");
            let parts_2 = parts.slice(parts.length - 2, parts.length);
            return parts_2.join(" ");
        }
        else {
            return short_name;
        }
    }

    return (
        <div
            style={{
                position: "absolute",
                width: "75%",
                bottom: 10,
                zIndex: 1,
                left: 0,
            }}
        >
            <ShopItemButtonTransparent
                clickFn={() => {
                    setDrawerOpenStateFn(true, "product_details");
                    postFeedbackFn(index, 'product_drawer', 1.0);
                }}
                upperText={getPriceStringToDisplay(product_features)}
                middleText={GetLastTwoWords(product_features.name[0], product_features.short_name)}
                lowerText={product_features.brand}
                leftImage={imageList[0]}
                rightCircleClickFn={product_features.product_shop != undefined && product_features.product_shop.active ? () => { } : onButtonClickFn(product_features.url)}
                rightCircleText={product_features.product_shop != undefined && product_features.product_shop.active ? "Shop" : "View"}
                data_tutorial_label={"product_details"}
            ></ShopItemButtonTransparent>
        </div >
    );
}
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


export function GetMrpFromFeedResponse(product) {
    if (product == undefined) {
        return -1;
    }

    let discounted_price = product.price.discounted_price;
    let mrp = product.price.mrp;

    if (product.product_shop != undefined && product.product_shop.discount_price != undefined) {
        discounted_price = product.product_shop.discount_price;
        mrp = product.product_shop.price;
    }

    if (mrp == discounted_price) {
        return -1;
    }
    if (mrp <= 0) {
        return -1
    }
    return mrp;
}

export function GetDiscountedPriceFromFeedResponse(product) {
    if (product == undefined) {
        return -1;
    }

    let discounted_price = product.price.discounted_price;
    if (product.product_shop != undefined && product.product_shop.discount_price != undefined) {
        discounted_price = product.product_shop.discount_price;
    }
    return discounted_price;
}

export function DisplayProductNameAndPrice({ product_name, brand_name, mrp, discountPrice }) {
    return (
        <Box>
            <Box style={{ display: "flex" }}>
                <>
                    <Typography style={{ flexGrow: 1 }} variant="subtitle1">
                        {product_name}
                    </Typography>
                    {
                        mrp > 0 && mrp != discountPrice ?
                            <Typography
                                variant="caption"
                                style={{ textDecoration: "line-through" }}
                                gutterBottom
                            >
                                ₹{mrp}
                            </Typography> :
                            <></>
                    }
                </>
            </Box>
            <Box style={{ display: "flex" }}>
                <Typography
                    style={{ flexGrow: 1 }}
                    variant="subtitle2"
                >
                    {brand_name}
                </Typography>
                <Typography>
                    ₹{discountPrice}</Typography>
            </Box>
        </Box>
    );
}
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import CheckoutSummaryCard from '../../Components/Cart/CheckoutSummary';
import CartItems from '../../Components/Cart/CartItems';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import { fetchOrderWithId } from '../../../Store/order_actions';

function isCartDefined(order_id_details, orderId) {
    return order_id_details[orderId] != undefined && order_id_details[orderId].cart != undefined && order_id_details[orderId].cart.items_count > 0;
}

function OrderSummary(props) {
    return (
        <Box sx={{ minWidth: 275, paddingTop: "20px", paddingBottom: "20px" }}>
            <Card variant="outlined" style={{ border: "1px solid black" }}>
                <React.Fragment>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Order ID</span>
                                <span style={{ float: "right" }}>{props.code}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Ordered On</span>
                                <span style={{ float: "right" }}>{props.created_at.split("T")[0]}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Order Status</span>
                                <span style={{ float: "right" }}>{props.order_status}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                                <span style={{ float: "left" }}>Payment Status</span>
                                <span style={{ float: "right" }}>{props.payment_status}</span>
                            </Typography>
                            <Typography variant="body1" component="div" style={{ paddingBottom: "10px" }}>
                                <span style={{ float: "left" }}>Shipping Status</span>
                                <span style={{ float: "right" }}>{props.shipping_status}</span>
                            </Typography>
                        </Box>
                    </CardContent>
                </React.Fragment>
            </Card>
        </Box>
    );
}

function OrderAddress({ address }) {
    return (
        <Box sx={{ minWidth: 275, paddingTop: "20px", paddingBottom: "20px" }}>
            <Card variant="outlined" style={{ border: "1px solid black" }}>
                <React.Fragment>
                    <CardContent >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="div" component="div">
                                {address.address_line1}, {address.address_line2} <br />
                                {address.city}, {address.state}, <br />
                                {address.country} - {address.postal_code}
                            </Typography>
                        </Box>
                    </CardContent>
                </React.Fragment>
            </Card>
        </Box>
    );
}


function OrderDetails(props) {
    const navigate = useNavigate();
    const { orderId } = useParams();

    useEffect(() => {
        props.fetchOrderWithId(orderId);
    }, []);

    if (!isCartDefined(props.order_id_details, orderId)) {
        return (
            <>
            </>
        );
    }

    return (
        <Container component="main" maxWidth="xs" style={{ marginTop: 100, overflow: "scroll", marginBottom: 60 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    variant="contained"
                    style={{
                        marginBottom: "2.0em",
                        textTransform: "none",
                        borderRadius: "10px",
                        paddingLeft: "40px",
                        paddingRight: "40px"
                    }}
                    color="custom"
                    onClick={() => { navigate('/'); }}
                >
                    <Typography
                        variant="button"
                        padding="0.2em"
                        fontSize="1.1em"
                    >
                        Go to Home
                    </Typography>
                </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2" component="div">
                    <span style={{ float: "left" }}>Details</span>
                </Typography>
            </Box>
            <OrderSummary
                code={props.order_id_details[orderId].code}
                created_at={props.order_id_details[orderId].created_at}
                order_status={props.order_id_details[orderId].readable_order_status}
                payment_status={props.order_id_details[orderId].readable_payment_status}
                shipping_status={props.order_id_details[orderId].shipping_status}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2" component="div">
                    <span style={{ float: "left" }}>Payment Summary</span>
                </Typography>
            </Box>
            <CheckoutSummaryCard
                cart={props.order_id_details[orderId].cart}
                buttonText={""}
                buttonAction={''}
                showButton={false}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle2" component="div">
                    <span style={{ float: "left" }}>Address</span>
                </Typography>
            </Box>
            <OrderAddress address={props.order_id_details[orderId].address} />
            <CartItems
                items={props.order_id_details[orderId].cart.items}
                item_count={props.order_id_details[orderId].cart.items_count}
                isOrder={true}
                updateCartItemQuantity={() => { }}
                removeCartItem={() => { }}
            />
        </Container>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    access_token: state.auth.access_token,
    loading: state.order_actions.loading,
    order_id_details: state.order_actions.order_id_details,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOrderWithId: (orderId) => dispatch(fetchOrderWithId(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
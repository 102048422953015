import { useState } from "react";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

function QuantityCounter({ quantity, setQuantity, isOrder = false }) {
    let incQuantity = () => {
        setQuantity(Number(quantity) + 1);
    };
    let decQuantity = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    }
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="body1" component="div" fontSize={"0.6em"}>
                Quantity
            </Typography>
            <Box sx={{ display: 'flex', marginTop: "5px" }}>
                {!isOrder &&
                    <IconButton aria-label="previous" onClick={decQuantity} style={{ height: 20, width: 20 }} sx={{ color: "primary.contrastText" }}>
                        <RemoveCircleIcon style={{ transform: "scale(0.7)" }} />
                    </IconButton>
                }
                <Typography variant="body1" component="div" style={{ paddingLeft: 3, paddingRight: 3 }}>
                    {quantity}
                </Typography>
                {!isOrder &&
                    <IconButton aria-label="previous" onClick={incQuantity} style={{ height: 20, width: 20 }} sx={{ color: "primary.contrastText" }}>
                        <AddCircleIcon style={{ transform: "scale(0.7)" }} />
                    </IconButton>
                }
            </Box>
        </Box>
    );
}
export default QuantityCounter;

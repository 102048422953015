import React from "react";

const VideoCard = ({ src, componentWidth }) => {
  return (
    <div
      style={{
        height: 2 * componentWidth,
        width: "100%",
        marginTop: "0.9rem",
        marginBottom: "0.9rem",
        borderRadius: "5%",
        overflow: "hidden",
        borderWidth: "0px",
      }}
    >
      <video
        muted
        autoPlay
        loop
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoCard;

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import BottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useGridMode } from '../../../GridModeContext';
import WindowIcon from '@mui/icons-material/Window';

// import { fetchCart } from '../../../Store/cart_actions';
import { connect } from "react-redux";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: #808080;
  &.Mui-selected {
    color: black;
  }
`);

const Navbar = (props) => {
  const { gridMode, setGridMode } = useGridMode();
  const [value, setValue] = React.useState(gridMode ? '/?grid=true' : '/');
  const navigate = useNavigate();

  const location = useLocation();

  const handleChange = (e, newValue) => {
    if (newValue == location.pathname || (location.pathname == '/' && newValue == "/?grid=true")) {
      ;
    }
    else if (newValue == "/?grid=true") {
      navigate('/');
    }
    else {
      navigate(newValue);
    }
    setValue(newValue);

    if (newValue == "/?grid=true") {
      setGridMode(true);
    }
    if (newValue == "/") {
      setGridMode(false);
    }
  };

  useEffect(() => {
    if (location.pathname == '/') {
      if (gridMode) {
        setValue('/?grid=true');
      }
      else {
        setValue('/');
      }
    }
  }, [gridMode]);

  if (!isMobile) return (<></>);
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 10 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        {/* <BottomNavigationAction value="/" label="Home" icon={<HomeIcon />} data-tut="mobile_home" />
        <BottomNavigationAction value="/?grid=true" label="Home" icon={<HomeIcon />} data-tut="mobile_home" /> */}
        <BottomNavigationAction value="/?grid=true" label="Grid" icon={<WindowIcon />} data-tut="mobile_grid" />
        <BottomNavigationAction value="/" label="Feed" icon={<HomeIcon />} data-tut="mobile_feed" />
        <BottomNavigationAction
          value="/profile"
          label="Profile"
          icon={<AccountCircleIcon />}
          data-tut="mobile_profile"
        />
      </BottomNavigation>
    </Paper>
  );
};


const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cart: state.cart_actions.cart,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchCart: () => dispatch(fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
/*jshint esversion: 6 */
import React from "react";
import "./selectable_card_style.css"
import Grid from '@mui/material/Grid';

function SelectableCardBase(props) {
    var isSelected = props.selected ? "selected" : "";
    var className = "selectable " + isSelected;
    return (
        <div className="card" style={{ width: "100%", marginBottom: "20px", padding: "5px" }}>
            <div className={className} onClick={props.onClick} style={{ width: "100%", height: "100%" }}>
                {props.children}
                <div className="check"><span className="checkmark">✔</span></div>
            </div>
        </div>
    );
}

function SelectableTitleCard(props) {
    const {
        description,
        selected,
        background_color,
        font_color
    } = props;

    return (
        <SelectableCardBase onClick={props.onClick} selected={selected} >
            <div style={{
                width: "100%",
                height: "100%",
                backgroundColor: background_color,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px"
            }}>
                <div style={{
                    width: "80%"
                }}>
                    <p style={{
                        color: font_color
                    }}>{description}</p>
                </div>
            </div>
        </SelectableCardBase >
    );
}


function SelectableCard({ answers, onListChanged, selected, background_color = "white", font_color = "black" }) {
    return (
        <Grid container
            spacing={0}
            style={{
                width: "100%",
            }}>
            <Grid item xs={12} style={{ alignItems: "center" }}>
                <div style={{ width: "70%", margin: "0 auto" }}>
                    {
                        answers.map((answer, index) => {
                            return (
                                <SelectableTitleCard
                                    key={index}
                                    description={answer.text}
                                    selected={selected[index] == 1}
                                    onClick={(e) => onListChanged(index)}
                                    background_color={background_color}
                                    font_color={font_color}
                                />
                            );
                        })
                    }
                </div>
            </Grid >
        </Grid>

    )
}
export default SelectableCard; 

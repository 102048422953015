import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { ErrorMessage } from "./../Controllers/Notifications";
const moment = require('moment');

const SLIDING_WINDOW_SIZE = 20;

function computeIsRendered(feed_response_length, current_index, sliding_window_size) {
    if (feed_response_length > 0) {
        var anchor_point = current_index;
        var true_start = anchor_point - (sliding_window_size / 2);
        if (true_start < 0) {
            true_start = 0;
        }
        var true_end = true_start + sliding_window_size;
        if (true_end > feed_response_length) {
            true_end = feed_response_length
            true_start = feed_response_length - sliding_window_size
        }
        if (true_start < 0) {
            true_start = 0;
        }
        var isRenderedArr = [];
        for (var index = 0; index < feed_response_length; index++) {
            if (index < true_start) {
                isRenderedArr.push(false);
            }
            else if (index >= true_start && index <= true_end) {
                isRenderedArr.push(true);
            }
            else {
                isRenderedArr.push(false);
            }
        }
        return isRenderedArr;
    }
}

const slice = createSlice({
    name: "feature_lookup",
    initialState: {
        current_index: -1,
        product_id: '',
        video_id: '',
        collection_id: '',
        input_type: '',
        feed_response: [],
        feed_comments: [],
        feed_response_cache: [],
        feed_comments_cache: [],
        total_items_fetched: 0,
        // end_of_item_reached signifies if the end of items was reached and we should not make any more calls.
        end_of_item_reached: false,
        metadata: {},
        isRendered: [],
        feedback: {},
        productPageId: '',
        productPageFeatures: {}
    },
    reducers: {
        // action => action handler
        fetchFeedFeaturesStarted: (state, action) => {
        },
        fetchFeedFeaturesProcess: (state, action) => {
            if (action.payload.data.start_from + action.payload.data.num_to_fetch <= state.feed_response.length + state.feed_response_cache.length) {
                return;
                // this implies that this call is a stale call and items from [start_from, num_to_fetch] have already been populated.
            }
            if (action.payload.data.start_from < state.feed_response.length) {
                // This can heppen when react runs same code twice in dev mode and call the API with same params twice.
                // Looks like a duplicate API request
                return;
            }
            if (action.payload.data.input_type == "product_id") {
                state.input_type = 'product_id';
                state.product_id = action.payload.data.product_id;
                state.collection_id = '';
                state.video_id = '';
            }
            else if (action.payload.data.input_type == "video_id") {
                state.input_type = 'video_id';
                state.product_id = '';
                state.video_id = action.payload.data.video_id;
                state.collection_id = '';
            }
            else if (action.payload.data.input_type == "collection_id") {
                state.input_type = 'collection_id';
                state.product_id = '';
                state.video_id = '';
                state.collection_id = action.payload.data.collection_id;
            }
            else if (action.payload.data.input_type == "product_wishlist") {
                state.input_type = 'product_wishlist';
                state.product_id = '';
                state.video_id = '';
                state.collection_id = '';
            }
            else if (action.payload.data.input_type == "video_wishlist") {
                state.input_type = 'video_wishlist';
                state.product_id = '';
                state.video_id = '';
                state.collection_id = '';
            }
            else if (action.payload.data.input_type == "outfit_feed") {
                state.input_type = 'outfit_feed';
                state.product_id = '';
                state.video_id = '';
                state.collection_id = '';
            }
            else {
                throw "Undefined feed input type";
            }
            var product_comments = [];
            var feed_response = action.payload.server_response.features;
            for (var index = 0; index < feed_response.length; index++) {
                if (feed_response[index].product_comments == undefined) {
                    product_comments.push([]);
                }
                else {
                    product_comments.push(feed_response[index].product_comments);
                }
                delete feed_response[index].product_comments;
            }

            var end_of_item_reached = action.payload.server_response.end_of_item_reached;
            var collection_metadata = action.payload.server_response.metadata;

            // If we have some feed items we can scroll to, we delay the state update and handle this next time we update the current index.

            // Cache items and only push state changes, when active index changes. This can avoid changes to views during animations.
            // Currently this is set to false, so it will not be enabled. However, we can update the condition and 
            // push items in a cache, rather than directly pushing to feed_response.
            if (state.current_index >= 0 && state.current_index < state.feed_response.length - 1) {
                // Don't overwrite the cache, rather append to it.
                state.feed_response_cache = [...state.feed_response_cache, ...feed_response];
                state.feed_comments_cache = [...state.feed_comments_cache, ...product_comments];
            }
            else {
                // We are already on last slide, lets make the updates straight away.
                state.isRendered = computeIsRendered(state.feed_response.length + feed_response.length, state.current_index, SLIDING_WINDOW_SIZE);
                state.feed_response = [...state.feed_response, ...feed_response];
                state.feed_comments = [...state.feed_comments, ...product_comments];
            }
            // These are total items which have been fetched so far, this includes both items inside.
            state.total_items_fetched += feed_response.length;
            state.end_of_item_reached = end_of_item_reached;
            state.metadata = collection_metadata;
            // Initialize the active index, if not already done.
            if (state.current_index < 0) {
                state.current_index = 0;
            }
        },
        fetchFeedFeaturesFailed: (state, action) => {
            ErrorMessage("Error fetching features.");
        },
        postScoreStarted: (state, action) => {
        },
        postScoreSuccess: (state, action) => {
            let feedback_key = action.payload.data.item_id + "##" + action.payload.data.screen_type;
            // This feedback has been posted, lets unset it.
            state.feedback[feedback_key] = undefined;
        },
        postScoreFailed: (state, action) => {
            console.log("post score failed.");
        },
        postUrlClickStarted: (state, action) => {
        },
        postUrlClickSuccess: (state, action) => {
        },
        postUrlClickFailed: (state, action) => {
            console.log("post score failed.");
        },
        postCommentStarted: (state, action) => {
            var index = action.payload.data.index;
            var comment = action.payload.data.comment;
            var local_comments = [...state.feed_comments];
            var timestamp = Date.now();
            var time = moment(timestamp).format("DD/MM/YYYY h:mm:ss");
            local_comments[index] = [{
                'timestamp': time,
                'message': comment
            }, ...local_comments[index]];
            state.feed_comments = local_comments;
        },
        postCommentSuccess: (state, action) => {
        },
        postCommentFailed: (state, action) => {
            console.log("Failed to post comment.");
        },
        fetchProductPageFeaturesStart: (state, action) => {
        },
        fetchProductPageFeaturesSuccess: (state, action) => {
            if (action.payload.server_response.features && action.payload.server_response.features.length > 0) {
                let product_id = action.payload.server_response.features[0].id;
                let product_features = action.payload.server_response.features[0].product;
                state.productPageId = product_id;
                state.productPageFeatures = product_features
            }
            else {
                ErrorMessage("Error while fetching product details.");
            }
        },
        fetchProductPageFeaturesFailed: (state, action) => {
            ErrorMessage("Error while fetching product details.");
        },
        setCurrentIndex: (state, action) => {
            state.current_index = action.payload.index;
            var feed_response_length = state.feed_response.length;
            if (state.feed_response_cache.length > 0) {
                if (action.payload.index > state.feed_response.length) {
                    // What if we request an index and its slice does not exist, then we append the slice from feed_response to cache.
                    // This happens in link in bio, since link in bio loops over both feed_response ad feed_response_cache.
                    // We need a feed item which hasn't been rendered yet.
                    var slice_size = (action.payload.index - state.feed_response.length) + 1;
                    state.feed_response = [...state.feed_response, ...state.feed_response_cache.slice(0, slice_size)];
                    state.feed_comments = [...state.feed_comments, ...state.feed_comments_cache.slice(0, slice_size)];
                    state.feed_response_cache = state.feed_response_cache.slice(slice_size);
                    state.feed_comments_cache = state.feed_comments_cache.slice(slice_size);
                }
                else {
                    // We are gonna pre-load certain number of items, lets push enough items, so that it can be rendered in advance.
                    // This also ensures, we incrementally create new divs, instead of creating all at one point.
                    feed_response_length += 1;
                    var first_cache_response = state.feed_response_cache[0];
                    var first_cache_comment = state.feed_comments_cache[0];
                    state.feed_response = [...state.feed_response, first_cache_response];
                    state.feed_comments = [...state.feed_comments, first_cache_comment];
                    state.feed_response_cache = state.feed_response_cache.slice(1);
                    state.feed_comments_cache = state.feed_comments_cache.slice(1);
                }
            }
            state.isRendered = computeIsRendered(feed_response_length, action.payload.index, SLIDING_WINDOW_SIZE);
        },
        cleanUpFeed: (state, action) => {
            state.current_index = -1;
            state.feed_response = [];
            state.feed_comments = [];
            state.feed_response_cache = [];
            state.feed_comments_cache = [];
            state.total_items_fetched = 0;
            state.end_of_item_reached = false;
            state.metadata = {};
            state.isRendered = [];
        },
        updateLocalFeedback: (state, action) => {
            let feedback_key = action.payload.item_id + "##" + action.payload.screen_type;
            let local_feedback = {}
            if (state.feedback[feedback_key] != undefined) {
                local_feedback = state.feedback[feedback_key]
            }
            local_feedback[action.payload.score_key] = action.payload.score_value;
            state.feedback[feedback_key] = local_feedback;
        },
        addtoWishlistStarted: (state, action) => {
        },
        addtoWishlistSuccess: (state, action) => {
        },
        addtoWishlistFailed: (state, action) => {
            ErrorMessage("Error saving to wishlist.");
        },
    }
});

export const {
    fetchFeedFeaturesStarted,
    fetchFeedFeaturesProcess,
    fetchFeedFeaturesFailed,
    postScoreStarted,
    postScoreSuccess,
    postScoreFailed,
    postCommentStarted,
    postCommentSuccess,
    postCommentFailed,
    setCurrentIndex,
    cleanUpFeed,
    updateLocalFeedback,
    addtoWishlistStarted,
    addtoWishlistSuccess,
    addtoWishlistFailed,
    fetchProductPageFeaturesStart,
    fetchProductPageFeaturesSuccess,
    fetchProductPageFeaturesFailed,
    postUrlClickStarted,
    postUrlClickSuccess,
    postUrlClickFailed
} = slice.actions;


export default slice.reducer;

export const fetchFeedFeaturesFromProductId = (product_id, num_to_fetch) => (dispatch, getState) => {
    // There is a chance cache is non empty.
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { product_id: product_id, input_type: "product_id", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { product_id: product_id, input_type: "product_id", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};

export const fetchFeaturesForProductPage = (product_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { product_id: product_id, input_type: "product_id", start_from: 0, num_to_fetch: 1 },
            data: { product_id: product_id, start_from: 0, num_to_fetch: 1 },
            method: "post",
            onStart: fetchProductPageFeaturesStart.type,
            onSuccess: fetchProductPageFeaturesSuccess.type,
            onError: fetchProductPageFeaturesFailed.type
        })
    );
};


export const fetchFeedFeaturesFromVideoId = (video_id, num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { video_id: video_id, input_type: "video_id", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { video_id: video_id, input_type: "video_id", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};


export const fetchFeedFeaturesFromCollectionId = (collection_id, num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { collection_id: collection_id, input_type: "collection_id", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { collection_id: collection_id, input_type: "collection_id", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};

export const fetchFeedFeaturesForOutfitsFeed = (num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_outfit_feed_features/",
            data_to_server: { input_type: "outfit_feed", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { input_type: "outfit_feed", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};

export const fetchProductWishlist = (num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { input_type: "product_wishlist", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { input_type: "product_wishlist", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};

export const fetchVideoWishlist = (num_to_fetch) => (dispatch, getState) => {
    var start_from = getState().feature_lookup.feed_response.length + getState().feature_lookup.feed_response_cache.length;
    var end_of_item_reached = getState().feature_lookup.end_of_item_reached;
    if (end_of_item_reached) {
        return;
    }

    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_feed_features/",
            data_to_server: { input_type: "video_wishlist", start_from: start_from, num_to_fetch: num_to_fetch },
            data: { input_type: "video_wishlist", start_from: start_from, num_to_fetch: num_to_fetch },
            method: "post",
            onStart: fetchFeedFeaturesStarted.type,
            onSuccess: fetchFeedFeaturesProcess.type,
            onError: fetchFeedFeaturesFailed.type
        })
    );
};

export const postCommentFeedback = (index, commentText) => (dispatch, getState) => {
    var item_id = getState().feature_lookup.feed_response[index].id;
    var screen_type = getState().feature_lookup.feed_response[index].screen_type;
    return dispatch(
        apiCallBegan({
            url: "/lookbook/post_comment/",
            data_to_server: { item_id: item_id, screen_type: screen_type, comment: commentText },
            data: { index: index, comment: commentText },
            method: "post",
            onStart: postCommentStarted.type,
            onSuccess: postCommentSuccess.type,
            onError: postCommentFailed.type
        })
    );
};

export const postScoreFeedback = (index, score_key, score_value) => (dispatch, getState) => {
    var item_id = getState().feature_lookup.feed_response[index].id;
    var screen_type = getState().feature_lookup.feed_response[index].screen_type;
    let feedback_key = item_id + "##" + screen_type;

    let collection_id = getState().feature_lookup.collection_id;

    if (score_key == 'time_spent') {
        var local_feedback = Object.assign({}, getState().feature_lookup.feedback[feedback_key]);
        if (local_feedback == undefined) {
            local_feedback = { score_key: score_value };
        }
        else {
            local_feedback[score_key] = score_value;
        }
        return dispatch(
            apiCallBegan({
                url: "/lookbook/post_feedback/",
                data_to_server: { item_id: item_id, screen_type: screen_type, feedback: local_feedback, collection_id: collection_id },
                data: { item_id: item_id, screen_type: screen_type },
                method: "post",
                onStart: postScoreStarted.type,
                onSuccess: postScoreSuccess.type,
                onError: postScoreFailed.type
            })
        );
    }
    else if (score_key == 'wishlist') {
        dispatch(apiCallBegan({
            url: "/lookbook/add_to_wishlist/",
            data_to_server: { item_id: item_id, screen_type: screen_type, score_key: score_key, score_value: score_value, collection_id: collection_id },
            data: { item_id: item_id, screen_type: screen_type },
            method: "post",
            onStart: addtoWishlistStarted.type,
            onSuccess: addtoWishlistSuccess.type,
            onError: addtoWishlistFailed.type
        }));
    }
    else if (score_key.startsWith('video_drawer_item_clicked')) {
        let click_feedback = {};
        click_feedback[score_key] = score_value;
        dispatch(apiCallBegan({
            url: "/lookbook/post_feedback/",
            data_to_server: { item_id: item_id, screen_type: screen_type, feedback: click_feedback, collection_id: collection_id },
            data: { item_id: item_id, screen_type: screen_type },
            method: "post",
            onStart: postUrlClickStarted.type,
            onSuccess: postUrlClickSuccess.type,
            onError: postUrlClickFailed.type
        }));
    }
    else if (score_key.startsWith('grid_click')) {
        let click_feedback = {};
        click_feedback[score_key] = score_value;
        dispatch(apiCallBegan({
            url: "/lookbook/post_feedback/",
            data_to_server: { item_id: item_id, screen_type: screen_type, feedback: click_feedback, collection_id: collection_id },
            data: { item_id: item_id, screen_type: screen_type },
            method: "post",
            onStart: postUrlClickStarted.type,
            onSuccess: postUrlClickSuccess.type,
            onError: postUrlClickFailed.type
        }));
    }
    else {
        dispatch({ type: updateLocalFeedback.type, payload: { item_id: item_id, screen_type: screen_type, score_key: score_key, score_value: score_value } });
    }
};

export const setCurrentIndexFn = (index) => (dispatch, getState) => {
    dispatch({ type: setCurrentIndex.type, payload: { index: index } });
    // Lets close and destroy the drawer.
    dispatch({ type: 'bottom_drawer/openDrawer', payload: { open: false, drawerType: '' } });
};

export const cleanUpFeedFn = () => (dispatch, getState) => {
    dispatch({ type: cleanUpFeed.type, payload: {} });
};


export const postTagsFeedback = (index, feedback_object) => (dispatch, getState) => {
    var item_id = getState().feature_lookup.feed_response[index].id;
    var screen_type = getState().feature_lookup.feed_response[index].screen_type;
    let collection_id = getState().feature_lookup.collection_id;
    return dispatch(
        apiCallBegan({
            url: "/lookbook/post_tags_feedback/",
            data_to_server: { item_id: item_id, screen_type: screen_type, collection_id: collection_id, feedback: feedback_object },
            data: { item_id: item_id, screen_type: screen_type },
            method: "post",
            onStart: postScoreStarted.type,
            onSuccess: postScoreSuccess.type,
            onError: postScoreFailed.type
        })
    );
}
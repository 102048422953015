import * as actions from "../api";
import { API_HOST } from "../../Constants";
import { SuccessMessage, ErrorMessage } from "../../Controllers/Notifications";
import Cookies from 'js-cookie';
import { api_mock } from './mock_api';
import { v4 as uuid } from 'uuid';


var ISMOCK = false;

let store;
export const injectStore = _store => {
    store = _store
}
// Create an axiosApiInstance. We use this to intercept the request to inject  
// access token in the header and intercept the response to refresh the token 
// in case of failure.
const axios = require('axios');
export const axiosApiInstance = axios.create();

// Request interceptor for API calls. Add access token to API calls header.
// If user is not authenticated, create a unique zive id and send it, if it already does not exist.
axiosApiInstance.interceptors.request.use(
    async config => {
        let access_token = store.getState().auth.access_token;
        let identifier = store.getState().auth.identifier;
        if (identifier == undefined) {
            const unique_id = uuid();
            identifier = unique_id;
            // Dispatch and save this header for future use.
            store.dispatch({ type: 'auth/setIdentifier', payload: { identifier: unique_id } });
        }
        if (access_token != undefined) {
            config.headers['Authorization'] = `Bearer ${access_token}`;
        }
        else {
            config.headers['ZIVE-ID'] = identifier;
        }
        const csrftoken = Cookies.get('csrftoken');
        config.headers['X-CSRFToken'] = csrftoken;
        return config;
    },
    error => {
        Promise.reject(error)
    });


function refreshAccessToken() {
    return async function () {
        let refresh_token = store.getState().auth.refresh_token;
        let api_endpoint = `${API_HOST}/auth/token_refresh/`;
        if (refresh_token == null) {
            return;
        }
        try {
            const { data } = await axios({
                method: 'post',
                url: api_endpoint,
                data: { "refresh": refresh_token },
                headers: {
                    'Accept': "application/json",
                    'Content-Type': 'application/json'
                },
            });
            let access_token = data["access"];
            store.dispatch({ type: 'auth/setRefreshedAccessToken', payload: { access_token: access_token } });
            return access_token;
        } catch (error) {
            var reason = '';
            try {
                reason = error.response.data.code;
            }
            catch {
                reason = 'unknown';
            }
            // if the refresh token is invalid, we logout, since we will need to login again.
            if (reason == "token_not_valid") {
                store.dispatch({ type: 'auth/logoutUserAction', payload: { reload: true } });
            }
            if (axios.isAxiosError(error)) {
                console.log("Axios error", error);
            } else {
                console.log("Unexpected error in refreshAccessToken", error);
            }
        }
    }
}

// Response interceptor for API calls.
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken()();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});

async function make_axios_call(method, url, data, headers) {
    if (ISMOCK) {
        const api_response = await api_mock(url, method, data);
        return api_response;
    }
    else {
        try {
            const response = await axiosApiInstance({
                method: method,
                url: `${API_HOST}${url}`,
                data: data,
                headers: headers,
            });
            return [response.data, true];
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return [error.response.data, false];
            } else {
                return ['Internal API error', false];
            }
        }
    }
}


const api = ({ dispatch }) => next => async action => {
    // Bypass, if the api middleware should not act on this action type. 
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data_to_server, data, onStart, onSuccess, onError, onRefreshAccessToken, successApiCallPayload } = action.payload;

    if (onStart) dispatch({ type: onStart, payload: { data: data } });

    next(action);

    var headers = {
        'Accept': "application/json",
        'Content-Type': 'application/json',
    };

    const [response_data, response_status] = await make_axios_call(method, url, data_to_server, headers);

    if (response_status) {
        dispatch(actions.apiCallSuccess(response_data));
    }

    if (response_status && onSuccess) {
        dispatch({ type: onSuccess, payload: { data: data, server_response: response_data } });
        if (successApiCallPayload != undefined) {
            // console.log("successApiCallPayload execute in sucess:", successApiCallPayload);
            dispatch(
                actions.apiCallBegan(successApiCallPayload)
            );
        }
        else {
            // console.log("successApiCallPayload supported:");
            ;
        }
    }

    if (!response_status && onError) {
        dispatch({ type: onError, payload: { server_response: response_data } });
    }
}

export default api;
import React, { useState, useRef, useEffect } from "react";

import Select, { components } from "react-select";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Container from "@mui/material/Container";


function SelectRadio({ tagname, value, updateValue, possibleValues }) {
    return (
        <div style={{ padding: "10px" }}>
            <FormLabel>{tagname}</FormLabel>
            <RadioGroup
                row
                name={tagname}
                value={value}
                onChange={updateValue}
                style={{ marginTop: "10px" }}
            >
                {possibleValues.map((value) => (
                    <FormControlLabel value={value} control={<Radio />} label={value} key={value} />
                ))}
            </RadioGroup>
        </div>
    );
}

function SelectMultiple({ tagname, values, updateValue, possibleValues, isMulti }) {
    function onChange(selectedOptions, action) {
        updateValue({ target: { name: tagname, value: selectedOptions } });
    }

    const selectStyles = {
        menu: base => ({
            ...base,
            zIndex: 100,
            backgroundColor: "black",
            color: "red"
        })
    };

    return (
        <div style={{ padding: "10px" }}>
            <FormLabel>{tagname}</FormLabel>
            <Select
                styles={selectStyles}
                isMulti={isMulti}
                name={tagname}
                value={values}
                options={possibleValues}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onChange}
                closeMenuOnSelect={true}
                autoBlur={true}
            />
        </div>
    );
}

function TextInput({ tagname, value, updateValue }) {
    return (
        <div style={{ padding: "10px" }}>
            <FormLabel>{tagname}</FormLabel>
            <TextField
                name={tagname}
                label={tagname}
                style={{ width: "100%", marginTop: "20px" }}
                placeholder={tagname}
                variant="outlined"
                value={value}
                onChange={updateValue}
            />
        </div>
    )
}

export function GetDefaultValuesFromSchema(formFieldsSchema) {
    var default_value = {};
    for (var index = 0; index < formFieldsSchema.length; index++) {
        if (formFieldsSchema[index].type == "INPUT_STRING") {
            default_value[formFieldsSchema[index].key] = '';
        }
        else if (formFieldsSchema[index].type == "BOOLEAN") {
            default_value[formFieldsSchema[index].key] = formFieldsSchema[index].meta.false_text;
        }
        else if (formFieldsSchema[index].type == "MULTICLASS") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else if (formFieldsSchema[index].type == "MULTILABEL") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else {
            throw "Error";
        }
    }
    return default_value;
}

export function GetSchemaFormRatingsFromValues(formFieldsSchema, values) {
    var response = {};
    for (var index = 0; index < formFieldsSchema.length; index++) {
        if (formFieldsSchema[index].type == "INPUT_STRING") {
            response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
        }
        else if (formFieldsSchema[index].type == "BOOLEAN") {
            response[formFieldsSchema[index].key] = false;
            if (values[formFieldsSchema[index].key] == formFieldsSchema[index].meta.true_text) {
                response[formFieldsSchema[index].key] = true;
            }
        }
        else if (formFieldsSchema[index].type == "MULTICLASS") {
            if (values[formFieldsSchema[index].key].value == undefined) {
                response[formFieldsSchema[index].key] = '';
            }
            else {
                response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key].value;
            }

        }
        else if (formFieldsSchema[index].type == "MULTILABEL") {
            response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key].map((x) => { return x.value })
        }
        else {
            throw "Error";
        }
    }
    return response;
}


export default function SchematicForm({ formFieldsSchema, values, handleChange }) {
    return (
        <Container
            maxWidth="xs"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            {
                formFieldsSchema.map((item, index) => (
                    <React.Fragment key={item.key}>
                        {item.type == "INPUT_STRING" &&
                            <TextInput
                                tagname={item.key}
                                value={values[item.key]}
                                updateValue={handleChange} />
                        }
                        {item.type == "BOOLEAN" &&
                            <SelectRadio
                                tagname={item.key}
                                value={values[item.key]}
                                updateValue={handleChange}
                                possibleValues={[item.meta.true_text, item.meta.false_text]}
                            />
                        }
                        {item.type == "MULTICLASS" &&
                            < SelectMultiple
                                tagname={item.key}
                                values={values[item.key]}
                                updateValue={handleChange}
                                possibleValues={item.meta.values.map((x) => { return { value: x, label: x } })}
                                isMulti={false}
                            />
                        }
                        {item.type == "MULTILABEL" &&
                            < SelectMultiple
                                tagname={item.key}
                                values={values[item.key]}
                                updateValue={handleChange}
                                possibleValues={item.meta.values.map((x) => { return { value: x, label: x } })}
                                isMulti={true}
                            />
                        }
                    </React.Fragment>
                ))
            }
        </Container>
    )
}
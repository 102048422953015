import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "cart_actions",
    initialState: {
        cart: {},
        address: [],
        order_placed_id: -1,
        placing_order: false
    },
    reducers: {
        // action => action handler
        fetchCartStarted: (state, action) => {
        },
        fetchCartSuccess: (state, action) => {
            state.cart = action.payload.server_response;
        },
        fetchCartFailed: (state, action) => {
        },
        addToCartStarted: (state, action) => {
        },
        addToCartSuccess: (state, action) => {
            SuccessMessage("Item added to cart.");
        },
        addtoCartFailed: (state, action) => {
            ErrorMessage("Cannot add item to the cart. " + action.payload.server_response);
        },
        removeCartItemStarted: (state, action) => {
        },
        removeCartItemSuccess: (state, action) => {
            SuccessMessage("Removed item from the cart.");
        },
        removeCartItemFailed: (state, action) => {
            ErrorMessage("Cannot remove item from the cart.");
        },
        updateQtyStarted: (state, action) => {
        },
        updateQtySuccess: (state, action) => {
            // SuccessMessage("Product quantity updated.");
        },
        updateQtyFailed: (state, action) => {
            ErrorMessage("Cannot update product quantity.");
        },
        orderPlaceStarted: (state, action) => {
            state.order_placed_id = -1;
            state.placing_order = true;
        },
        orderPlaceSuccess: (state, action) => {
            SuccessMessage("Your order has been placed.");
            state.order_placed_id = action.payload.server_response.id;
            state.placing_order = false;
        },
        orderPlaceFailed: (state, action) => {
            state.placing_order = false;
            state.order_placed_id = -2;
            ErrorMessage("Failed to place the order.");
        },
        fetchAllAddressStarted: (state, action) => {
        },
        fetchAllAddressSuccess: (state, action) => {
            state.address = action.payload.server_response;
        },
        fetchAllAddressFailed: (state, action) => {
        },
        addOrEditAddressStarted: (state, action) => {
        },
        addOrEditAddressSuccess: (state, action) => {
            SuccessMessage("Address saved.");
        },
        addOrEditAddressFailed: (state, action) => {
            ErrorMessage("Cannot save address.");
        }
    }
});

export const {
    fetchCartStarted,
    fetchCartSuccess,
    fetchCartFailed,
    addToCartStarted,
    addToCartSuccess,
    addtoCartFailed,
    removeCartItemStarted,
    removeCartItemSuccess,
    removeCartItemFailed,
    updateQtyStarted,
    updateQtySuccess,
    updateQtyFailed,
    orderPlaceStarted,
    orderPlaceSuccess,
    orderPlaceFailed,
    fetchAllAddressStarted,
    fetchAllAddressSuccess,
    fetchAllAddressFailed,
    addOrEditAddressStarted,
    addOrEditAddressSuccess,
    addOrEditAddressFailed
} = slice.actions;

export default slice.reducer;


export const fetchCart = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/cart/",
            data_to_server: {},
            data: {},
            method: "get",
            onStart: fetchCartStarted.type,
            onSuccess: fetchCartSuccess.type,
            onError: fetchCartFailed.type
        })
    );
};

const getfetchCartPayload = () => {
    return {
        url: "/lookbook/cart/",
        data_to_server: {},
        data: {},
        method: "get",
        onStart: fetchCartStarted.type,
        onSuccess: fetchCartSuccess.type,
        onError: fetchCartFailed.type
    };
}

export const addItemToCart = (productId, variantId, quantity) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/cart/",
            data_to_server: { product: productId, variant: variantId, quantity: quantity },
            data: {},
            method: "post",
            onStart: addToCartStarted.type,
            onSuccess: addToCartSuccess.type,
            onError: addtoCartFailed.type,
            successApiCallPayload: getfetchCartPayload()
        })
    );
};

export const removeCartItem = (cartItemId) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/cart/" + cartItemId + "/",
            data_to_server: {},
            data: {},
            method: "delete",
            onStart: removeCartItemStarted.type,
            onSuccess: removeCartItemSuccess.type,
            onError: removeCartItemFailed.type,
            successApiCallPayload: getfetchCartPayload()
        })
    );
};

export const updateCartItemQuantity = (productId, variantId, quantity) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/cart/",
            data_to_server: { product: productId, variant: variantId, quantity: quantity },
            data: {},
            method: "post",
            onStart: addToCartStarted.type,
            onSuccess: addToCartSuccess.type,
            onError: addtoCartFailed.type,
            successApiCallPayload: getfetchCartPayload()
        })
    );
};

export const placeOrder = (addressId) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/orders/",
            data_to_server: { address: addressId },
            data: {},
            method: "post",
            onStart: orderPlaceStarted.type,
            onSuccess: orderPlaceSuccess.type,
            onError: orderPlaceFailed.type
        })
    );
};

export const fetchAllAddress = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/lookup_address/",
            data_to_server: {},
            data: {},
            method: "post",
            onStart: fetchAllAddressStarted.type,
            onSuccess: fetchAllAddressSuccess.type,
            onError: fetchAllAddressFailed.type
        })
    );
};

const getfetchAllAddressPayload = () => {
    return {
        url: "/lookbook/lookup_address/",
        data_to_server: {},
        data: {},
        method: "post",
        onStart: fetchAllAddressStarted.type,
        onSuccess: fetchAllAddressSuccess.type,
        onError: fetchAllAddressFailed.type
    };
}

export const addOrEditAddress = (formData) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/lookbook/add_or_update_address/",
            data_to_server: formData,
            data: {},
            method: "post",
            onStart: addOrEditAddressStarted.type,
            onSuccess: addOrEditAddressSuccess.type,
            onError: addOrEditAddressFailed.type,
            successApiCallPayload: getfetchAllAddressPayload()
        })
    );
};
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const innerBoxStyles =
  window.innerHeight > window.innerWidth
    ? {
      width: "100%",
    }
    : {
      width: "100%",
    };

const headingStyles =
  window.innerHeight > window.innerWidth
    ? {}
    : {
      marginTop: "1em",
    };

const ActionItem = ({ item, click_fn = undefined, imageFixed = false, zIndex = 0 }) => {
  const navigate = useNavigate();
  const handleAction = (action) => {
    switch (action) {
      case "HOME":
        navigate("/");
        break;
      case "LOGIN":
        navigate("/authentication");
        break;
      default:
        navigate("/");
        break;
    }
    if (click_fn != undefined) {
      click_fn();
    }
  };

  return (
    <>
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 0,
          backgroundImage: `url(${item.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: imageFixed ? "fixed" : "scroll",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "5em",
            justifyContent: "center",
            backdropFilter: "blur(1px)",
            ...innerBoxStyles,
          }}
        >
          {item.message != "" && <Typography
            variant="h3"
            padding="5%"
            textAlign="center"
            style={headingStyles}
            color={item?.contrastText}
            fontStyle={{ color: item?.contrastText }}
          >
            {item.message}
          </Typography>
          }
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3em",
            justifyContent: "center",
            backdropFilter: "blur(1px)",
            ...innerBoxStyles,
          }}
        >
          {
            item.actionText.map((action_text, index) => {
              return (
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "2em",
                    textTransform: "none",
                    padding: "1em"
                  }}
                  onClick={() => { handleAction(item.action[index]); }}
                >
                  <Typography variant="h4">{action_text}</Typography>
                </Button>
              )
            })
          }
        </Box>
      </Box>
    </>
  );
};

export default ActionItem;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { postScoreFeedback } from '../../../../Store/feature_lookup';
import { addItemToCart } from '../../../../Store/cart_actions';
import { setDrawerOpenStateFn } from '../../../../Store/bottom_drawer';
import { HandleVariants } from './VariantsHandler';
import { DisplayProductNameAndPrice, GetDiscountedPriceFromFeedResponse, GetMrpFromFeedResponse } from './ProductDetailsAndPrice';
import { OpenProductLink } from './OpenProductLink';
import { OpenProductPage } from './OpenProductPage';

function isProductDefined(feed_response, current_index) {
    return current_index >= 0 &&
        feed_response &&
        current_index < feed_response.length &&
        feed_response[current_index] !== null &&
        feed_response[current_index] !== undefined &&
        feed_response[current_index].product !== undefined;
}

function GetBaseImageFromFeedResponse(product) {
    if (product == undefined) {
        return '';
    }

    let imageSrc = '';
    if (product.product_shop != undefined) {
        imageSrc = product.product_shop.image_url;
    }
    else {
        imageSrc = product.media.base_image !== "" ? product.media.base_image : product.media.additional_images[0];
    }
    return imageSrc;
}

const ProductDetails = (props) => {

    const [mrp, setMrp] = useState(-1.0);
    const [discountPrice, setDiscountPrice] = useState(-1.0);
    const [imageSource, setImageSource] = useState('');

    useEffect(() => {
        // Change the mrp, discounted price and image source whenever index or feed response changes.
        const product = isProductDefined(props.feed_response, props.current_index) ? props.feed_response[props.current_index].product : undefined;
        var mrp = GetMrpFromFeedResponse(product);
        var discounted_price = GetDiscountedPriceFromFeedResponse(product);
        var image_source = GetBaseImageFromFeedResponse(product);
        setMrp(mrp);
        setDiscountPrice(discounted_price);
        setImageSource(image_source);
    }, [props.feed_response, props.current_index]);

    return (
        <>
            {
                isProductDefined(props.feed_response, props.current_index) &&
                <>
                    <img
                        data-src={imageSource}
                        src={imageSource}
                        style={{
                            width: "100%",
                            height: "250px",
                            objectFit: "cover",
                        }}
                    />
                    <Box
                        style={{
                            padding: "10px",
                        }}
                    >
                        <DisplayProductNameAndPrice
                            product_name={props.feed_response[props.current_index].product.name[0]}
                            brand_name={props.feed_response[props.current_index].product.brand}
                            product_features={props.feed_response[props.current_index].product}
                            mrp={mrp}
                            discountPrice={discountPrice}
                        />
                        <Box style={{ display: "flex", width: "100%" }}>
                            {props.feed_response[props.current_index].product.product_shop != undefined ?
                                <HandleVariants
                                    product_shop_info={props.feed_response[props.current_index].product.product_shop}
                                    addItemToCart={props.addItemToCart}
                                    setDrawerOpenStateFn={props.setDrawerOpenStateFn}
                                    access_token={props.access_token}
                                    setMrp={setMrp}
                                    setDiscountPrice={setDiscountPrice}
                                    setImageSource={setImageSource}
                                /> :
                                <>
                                </>
                            }
                        </Box>
                    </Box>
                    {/* <OpenProductLink
                        product_url={props.feed_response[props.current_index].product.url}
                        brand_name={props.feed_response[props.current_index].product.brand}
                        sendScoreFeedbackFn={props.postScoreFeedback}
                        productIndexInFeed={props.current_index}
                    /> */}
                    <OpenProductPage
                        productId={props.feed_response[props.current_index].id}
                        setDrawerOpenStateFn={props.setDrawerOpenStateFn}
                    />
                </>
            }
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    access_token: state.auth.access_token,
    feed_response: state.feature_lookup.feed_response,
    current_index: state.feature_lookup.current_index,
    cart: state.cart_actions.cart
});

const mapDispatchToProps = (dispatch) => ({
    postScoreFeedback: (index, score_key, score_value) => dispatch(postScoreFeedback(index, score_key, score_value)),
    addItemToCart: (productId, variant_id, quantity) => dispatch(addItemToCart(productId, variant_id, quantity)),
    setDrawerOpenStateFn: (open, drawerType) => dispatch(setDrawerOpenStateFn(open, drawerType)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function AboutBrand({ info }) {
    return (
        <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Typography variant="h6" style={{ marginTop: "0.2em", marginBottom: "0.2em" }}>
                About {info.brand_name}
            </Typography>
            <>
                {
                    info.story.map((storyline, key) => {
                        return (
                            <React.Fragment key={key}>
                                <Typography variant="body1" style={{ fontSize: "1.1em" }}>
                                    {storyline}
                                </Typography>
                                <br />
                            </React.Fragment>
                        )
                    })
                }
            </>
            {
                info.ig_num_followers &&
                <Typography variant="body1" style={{ fontSize: "1.1em" }}>
                    The brand has a following of {info.ig_num_followers} on Instagram.
                </Typography>
            }
            {
                info.ig_bio && info.ig_bio.length > 0 &&
                <Typography variant="body1" style={{ fontSize: "1.1em" }} >
                    <br />
                    Here's what they say on their Instagram bio
                    <br />
                </Typography>
            }
            {
                info.ig_bio && info.ig_bio.length > 0 &&
                info.ig_bio.map((bioline, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Typography variant="body1" style={{ fontSize: "1.1em" }}>
                                {bioline}
                            </Typography>
                        </React.Fragment>
                    )
                })
            }
        </Box >
    )
}
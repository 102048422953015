import React, { useRef } from "react";
import { getMobileOperatingSystem } from '../../../../Hooks/getOS';
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from '@mui/material/ImageListItemBar';
// const rowWeight = [1, 2, 3];
// const colWeight = [1, 2, 3];
// sort the given list into valid patterns of weights
// patterns being : {[1,3,3,3],[3,3,3,1],[2,3,3,3,3],[3,3,2,3,3],[3,3,3,3,2],[2,2],[3,3,3,3]}
// or find how to shuffle the imagelist to fill the gaps

const TileItem = ({
  image,
  product_id,
  product_url,
  product_brand,
  product_price,
  product_index,
  postFeedback,
  affiliate_link,
  available,
  colWidth = 50,
  colWeight = 2,
  rowWeight = 2,
}) => {

  const imageRef = useRef();
  const operation_system = getMobileOperatingSystem();

  const showFeed = () => {
    var url_to_open = product_url;
    if (affiliate_link && affiliate_link != '') {
      url_to_open = affiliate_link;
    }

    postFeedback(product_index, "video_drawer_item_clicked_" + product_url, 1.0);
    if (operation_system == 'iOS') {
      window.open(url_to_open, "_self");
    }
    else {
      window.open(url_to_open, "_blank");
    }
  };

  return (
    <ImageListItem key={product_id} cols={colWeight} rows={rowWeight}>
      <img
        ref={imageRef}
        src={image}
        loading="lazy"
        onClick={showFeed}
        style={{
          maxidth: colWidth * colWeight,
          height: colWidth * rowWeight,
          objectFit: "cover",
          filter: available == "NOT_AVAILABLE" ? "brightness(0.6)" : "brightness(1.0)"
        }}
      />
      <ImageListItemBar
        title={product_brand}
        subtitle={product_price}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      />
    </ImageListItem>
  );
};

export default TileItem;

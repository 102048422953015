import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const innerBoxStyles =
    window.innerHeight > window.innerWidth
        ? {
            top: "10vh",
        }
        : {
            top: "18vh",
        };


const LandingPageMesssage = ({ messageList, isMobile }) => {
    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 0
            }}
        >
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backdropFilter: "blur(1px)",
                    position: "absolute",
                    top: isMobile ? "10vh" : "20vh",
                    maxWidth: "90%",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "rgba(200, 200, 200, 0.60)",
                    borderRadius: "1em",
                    left: "auto",
                    ...innerBoxStyles,
                }}
            >
                <Typography
                    variant="h5"
                    textAlign="center"
                    fontSize={isMobile ? '1.5em' : '2.5em'}
                    style={{ "color": "#000000", fontFamily: "Nova Square" }}
                >
                    {messageList[0]}
                </Typography>
            </Box>
        </Box>
    );
};

export default LandingPageMesssage;
import Container from "@mui/material/Container";
import SelectableCard from "../../Quiz/SelectableCard";
import ImageGrid from "../../Quiz/ImageGrid";
import Typography from '@mui/material/Typography';


function onListChangedAux(index, multiple, max_selectable, selected) {
    if (multiple) {
        var local_selected = selected.slice();
        const sum = local_selected.reduce(
            (partialSum, a) => partialSum + parseInt(a),
            0
        );
        if ((sum < max_selectable || max_selectable == -1) && local_selected[index] == 0) {
            local_selected[index] = 1;
            return local_selected;
        } else if (local_selected[index] == 1) {
            local_selected[index] = 0;
            return local_selected;
        }
        else {
            return local_selected;
        }
    } else {
        var local_selected = new Array(selected.length).fill(0);
        local_selected[index] = 1;
        return local_selected;
    }
}

export function getSelectedQuizOptions(selectedQuizAnswers, quiz_options, quiz_key) {
    let response = {};
    response[quiz_key] = [];
    for (var index = 0; index < selectedQuizAnswers.length; index++) {
        if (selectedQuizAnswers[index] == 1) {
            response[quiz_key].push(quiz_options[index].label);
        }
    }
    return response;
}

export default function QuizOptions({ quiz_options, selected, setSelected, options_type = "IMAGE", multiselect = true, num_selectable = 2 }) {
    function onListChanged(index) {
        var local_selected = onListChangedAux(
            index,
            multiselect,
            num_selectable,
            selected
        );
        setSelected(local_selected);
    }

    return (
        <Container
            maxWidth="xs"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            <Typography variant="body1" component="div">
                {!multiselect ? "Please select one option" : num_selectable == -1 ? "Please select all that apply" : "Please select maximum " + num_selectable + " options"}
            </Typography>
            {options_type == "IMAGE" && <ImageGrid
                answers={quiz_options}
                onListChanged={onListChanged}
                selected={selected}
            />
            }
            {options_type == "TEXT" && <SelectableCard
                answers={quiz_options}
                onListChanged={onListChanged}
                selected={selected}
            />
            }
        </Container>
    );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react'
import Box from "@mui/material/Box";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { memo } from "react"


const swipe_up = keyframes`
  0% {
    transform: translate3d(0, 0px, 0);
  }

  100% {
    transform: translate3d(0,-20px,0);
  }
`

const swipe_down = keyframes`
  0% {
    transform: translate3d(0, 0px, 0);
  }

  100% {
    transform: translate3d(0,20px,0);
  }
`

const SwipeUpInstruction = () => {
    return (
        <div
            css={css`
                    animation: ${swipe_up} 1s ease-in-out infinite;
                `}
        >
            <KeyboardDoubleArrowUpIcon style={{ transform: "rotate(0deg)" }} />
        </div>
    )
}

const SwipeDownInstruction = () => {
    return (
        <div
            css={css`
                    animation: ${swipe_down} 1s ease-in-out infinite;
                `}
        >
            <KeyboardDoubleArrowUpIcon style={{ transform: "rotate(180deg)" }} />
        </div>
    )
}

const Instruction = () => {
    return (
        <div style={{ fontSize: 20, fontWeight: 600 }}>
            Swipe up or down to find more items.
        </div>
    )
}

function SwipeInstructionContainer() {
    return (
        <Box style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: "column",
            margin: "auto"
        }}>
            <SwipeUpInstruction />
            <Instruction />
            <SwipeDownInstruction />
        </Box >
    );
}

export default memo(SwipeInstructionContainer);
import React from "react";

const ImageItem = ({ image, componentWidth }) => {
  return (
    <div
      style={{
        height: 2 * componentWidth,
        width: "100%",
        marginTop: "0.9rem",
        marginBottom: "0.9rem",
        borderRadius: "5%",
        overflow: "hidden",
        borderWidth: "0px",
      }}
    >
      <img
        effect="blur"
        src={image}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        loading="lazy"
      />
    </div>
  );
};

export default ImageItem;

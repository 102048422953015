import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { ErrorMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "fetch_collection_list",
    initialState: {
        collection_list: [],
        // end_of_item_reached signifies if the end of items was reached and we should not make any more calls.
        end_of_item_reached: false,
        home_page_def: {},
        home_page_loaded: '',
    },
    reducers: {
        // action => action handler
        fetchCollectionOfCollectionStarted: (state, action) => {
        },
        fetchCollectionOfCollection: (state, action) => {
            state.collection_list = action.payload.server_response.coc;
            // [...state.collection_list, ...action.payload.server_response.coc];
            // TODO(siddhant): Currently we overwrite, so its alright, but we need to support concatenation logic.
            state.end_of_item_reached = action.payload.server_response.end_of_item_reached;
            state.home_page_def = action.payload.server_response.home_page_def;
            state.home_page_loaded = action.payload.data.homepage;
        },
        fetchCollectionOfCollectionFailed: (state, action) => {
            ErrorMessage("Error fetching collections.");
        },
    }
});

export const {
    fetchCollectionOfCollectionStarted,
    fetchCollectionOfCollection,
    fetchCollectionOfCollectionFailed
} = slice.actions;

export default slice.reducer;

export const fethcHomeScreenCollections = (num_to_fetch, local_page_name) => (dispatch, getState) => {
    var start_from = getState().fetch_collection_list.collection_list.length;
    var end_of_item_reached = getState().fetch_collection_list.end_of_item_reached;
    var home_page_already_loaded = getState().home_page_loaded;
    // If we are loading the same homepage and end of items was reached, do nothing.
    if (home_page_already_loaded == local_page_name && end_of_item_reached) {
        return;
    }
    // If we are trying to load a different homepage, then we should restart the call from 0. 
    if (home_page_already_loaded != local_page_name) {
        start_from = 0;
    }
    return dispatch(
        apiCallBegan({
            url: "/lookbook/fetch_home_screen_collections/",
            data_to_server: { start_from: start_from, num_to_fetch: num_to_fetch, homepage: local_page_name },
            data: { homepage: local_page_name },
            method: "post",
            onStart: fetchCollectionOfCollectionStarted.type,
            onSuccess: fetchCollectionOfCollection.type,
            onError: fetchCollectionOfCollectionFailed.type
        })
    );
};